import ruLocale from 'date-fns/locale/ru';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';

export default function LocalizedDatePicker(props) {
  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DatePicker disableOpenPicker mask="__.__.____" {...props} />
    </LocalizationProvider>
  );
}
