import { capitalize } from 'lodash';

export function capitalizeField(event) {
  return capitalize(event.target.value);
}

export function toUpperCaseField(event) {
  return event.target.value.toUpperCase();
}

export function minTwoDigits(n) {
  return (n < 10 ? '0' : '') + n;
}

export function dateToString(date) {
  if (typeof date === 'string' && date.search(/\d{4}-\d{2}-\d{2}/) !== -1)
    return date;
  return `${date.getFullYear()}-${minTwoDigits(
    date.getMonth() + 1
  )}-${minTwoDigits(date.getDate())}`;
}

export function stringToDate(strDate) {
  if (!strDate || strDate.search(/^\d{4}-\d{2}-\d{2}$/g) === -1) {
    return null;
  }
  const [year, month, day] = strDate.split('-');
  return new Date(year, month - 1, day);
}

export function getFIO(lastName, firstName, middleName) {
  const firstNameInitial = firstName ? `${firstName[0]}.` : '';
  const middleNameInitial = middleName ? `${middleName[0]}.` : '';

  return `${lastName} ${firstNameInitial} ${middleNameInitial}`.trim();
}

export function getClientLabel(client) {
  const firstNameShort =
    client.firstName !== null ? `${client.firstName[0]}.` : '';

  const middleNameShort =
    client.middleName !== null ? `${client.middleName[0]}.` : '';

  if (client.lastName === null) {
    if (client.middleName === null) {
      return `${client.firstName} ${client.phone}`;
    }
    return `${client.firstName} ${client.middleName} ${client.phone}`;
  }

  return `${client.lastName} ${firstNameShort} ${middleNameShort} ${client.phone}`;
}
