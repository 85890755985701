import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { forwardRef, useState, useEffect } from 'react';
import InputMask from 'react-input-mask';
// material
import { MTextField as TextField } from 'components/@material-extend';
//
import { toPhoneMask } from 'utils/maskFuncs';

// ----------------------------------------------------------------------

const PhoneInput = forwardRef(
  ({ name, onChange, onFocus, onBlur, ...props }, ref) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta, helpers] = useField(name);
    const [value, setValue] = useState(field.value);

    const handleChange = (event) => {
      let val = event.target.value;
      if (onChange) val = onChange(event);
      setValue(val);
    };

    const handleBlur = () => {
      if (value !== null) {
        setFieldValue(field.name, value);
        if (onBlur) onBlur(value);
      }
    };

    const handleFocus = () => {
      if (onFocus) onFocus(value);
      helpers.setTouched(true);
    };

    useEffect(() => {
      setValue(toPhoneMask(field.value));
    }, [field.value]);

    return (
      <InputMask
        mask="+7 (999) 999-99-99"
        value={value}
        onChange={handleChange}
        onBlur={handleBlur}
        onFocus={handleFocus}
        maskChar="_"
      >
        {() => (
          <TextField
            ref={ref}
            //   {...field}
            {...props}
            // value={value}

            error={Boolean(meta.touched && meta.error)}
            helperText={meta.touched && meta.error}
          />
        )}
      </InputMask>
    );
  }
);

PhoneInput.propTypes = {
  name: PropTypes.string,
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func
};

export default PhoneInput;
