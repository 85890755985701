import PropTypes from 'prop-types';
import { useFormikContext } from 'formik';
import { useEffect, useState } from 'react';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  useMediaQuery,
  Button,
  Switch,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  Divider,
  Tooltip,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
// utils
import { capitalizeField } from 'utils/textTransformation';
import { isDateValid } from 'utils/validations';
import { documentNumberRegex, documentSerialRegex } from 'utils/regexes';
// requests
import { findKBM } from 'requests/drivers-requests';
//
import {
  MSelect as Select,
  MInputLabel as InputLabel,
  MIconButton
} from 'components/@material-extend';
import {
  FormDatePicker,
  FormTextField
} from 'components/personal/form-components';

// ----------------------------------------------------------------------

Driver.propTypes = {
  driver: PropTypes.object,
  index: PropTypes.number,
  array: PropTypes.array,
  remove: PropTypes.func
};

export default function Driver({ driver, index, array, remove }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const {
    values: {
      insurerIsOwner,
      clientIsInsurer,
      //
      clientFirstName,
      insurerFirstName,
      ownerFirstName,
      clientMiddleName,
      insurerMiddleName,
      ownerMiddleName,
      clientLastName,
      insurerLastName,
      ownerLastName,
      insurerBirthdate,
      ownerBirthdate
    },
    getFieldProps
  } = useFormikContext();
  const [kbm, setKbm] = useState('');

  useEffect(() => {
    async function fetchData() {
      if (driver.isPreviousDriverLicense) return;

      let firstName = '';
      let lastName = '';
      let middleName = '';
      let licenseSerial = '';
      let licenseNumber = '';
      let birthdate = '';

      if (driver.driverSelect === 'Страхователь') {
        if (clientIsInsurer) {
          firstName = clientFirstName;
          lastName = clientLastName;
          middleName = clientMiddleName;
        } else {
          firstName = insurerFirstName;
          lastName = insurerLastName;
          middleName = insurerMiddleName;
        }
        birthdate = insurerBirthdate;
      }
      if (driver.driverSelect === 'Собственник') {
        firstName = ownerFirstName;
        lastName = ownerLastName;
        middleName = ownerMiddleName;
        birthdate = ownerBirthdate;
      }
      if (driver.driverSelect === 'Иное лицо') {
        firstName = driver.driverFirstName;
        lastName = driver.driverLastName;
        middleName = driver.driverMiddleName;
        birthdate = driver.driverBirthdate;
      }
      licenseSerial = driver.driverLicenseSerial;
      licenseNumber = driver.driverLicenseNumber;

      if (
        typeof birthdate === 'string' &&
        birthdate.search(/\d{4}-\d{2}-\d{2}/) === -1
      )
        return;
      if (typeof birthdate === 'object' && !isDateValid(birthdate)) return;

      if (
        firstName &&
        middleName &&
        lastName &&
        licenseNumber.search(documentNumberRegex) !== -1 &&
        licenseSerial.search(documentSerialRegex) !== -1
      ) {
        const result = await findKBM({
          firstName,
          middleName,
          lastName,
          birthdate,
          licenseSerial,
          licenseNumber
        });
        setKbm(result.found ? result.factor.toString() : '');

        enqueueSnackbar(
          `${lastName} ${firstName} ${middleName} КБМ=${result.factor}`,
          {
            variant: result.found ? 'success' : 'warning',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          }
        );
      }
    }
    fetchData();
  }, [
    driver,
    closeSnackbar,
    enqueueSnackbar,
    clientIsInsurer,
    clientFirstName,
    insurerFirstName,
    ownerFirstName,
    clientMiddleName,
    insurerMiddleName,
    ownerMiddleName,
    clientLastName,
    insurerLastName,
    ownerLastName,
    insurerBirthdate,
    ownerBirthdate
  ]);

  useEffect(() => {
    async function fetchData() {
      if (!driver.isPreviousDriverLicense) return;

      const firstName = driver.previousDriverFirstName;
      const lastName = driver.previousDriverLastName;
      const middleName = driver.previousDriverMiddleName;
      const licenseSerial = driver.previousDriverLicenseSerial;
      const licenseNumber = driver.previousDriverLicenseNumber;
      let birthdate = driver.driverBirthdate;
      if (driver.driverSelect === 'Страхователь') {
        birthdate = insurerBirthdate;
      }
      if (driver.driverSelect === 'Собственник') {
        birthdate = ownerBirthdate;
      }
      if (driver.driverSelect === 'Иное лицо') {
        birthdate = driver.driverBirthdate;
      }

      if (
        typeof birthdate === 'string' &&
        birthdate.search(/\d{4}-\d{2}-\d{2}/) === -1
      )
        return;
      if (typeof birthdate === 'object' && !isDateValid(birthdate)) return;

      if (
        firstName &&
        middleName &&
        lastName &&
        licenseNumber.search(documentNumberRegex) !== -1 &&
        licenseSerial.search(documentSerialRegex) !== -1
      ) {
        const result = await findKBM({
          firstName,
          middleName,
          lastName,
          birthdate,
          licenseSerial,
          licenseNumber
        });
        setKbm(result.found ? result.factor.toString() : '');
        enqueueSnackbar(
          `${lastName} ${firstName} ${middleName} КБМ=${result.factor}`,
          {
            variant: result.found ? 'success' : 'warning',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          }
        );
      }
    }
    fetchData();
  }, [
    driver,
    closeSnackbar,
    enqueueSnackbar,
    insurerBirthdate,
    ownerBirthdate
  ]);

  const getFullFieldName = (
    driverSelect,
    index,
    fieldName,
    noClientField = false
  ) => {
    if (driverSelect === 'Страхователь') {
      return clientIsInsurer && !noClientField
        ? `client${fieldName}`
        : `insurer${fieldName}`;
    }
    if (driverSelect === 'Собственник') return `owner${fieldName}`;
    return `drivers[${index}].driver${fieldName}`;
  };

  return (
    <>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormControl fullWidth variant="outlined">
          <InputLabel complete>{`Водитель ${index + 1}`}</InputLabel>
          <Select
            label="Водитель"
            defaultValue="Иное лицо"
            {...getFieldProps(`drivers[${index}].driverSelect`)}
          >
            <MenuItem value="Иное лицо">Иное лицо</MenuItem>
            <MenuItem value="Страхователь">Страхователь</MenuItem>
            {!insurerIsOwner ? (
              <MenuItem value="Собственник">Собственник</MenuItem>
            ) : undefined}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        {index > 0 ? (
          <Button
            size={isDesktop ? 'large' : 'small'}
            startIcon={<DeleteIcon />}
            onClick={() => remove(index)}
          >
            Удалить водителя
          </Button>
        ) : undefined}
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormControlLabel
          control={
            <Checkbox
              {...getFieldProps(`drivers[${index}].isForeignIdentity`)}
              color="primary"
            />
          }
          label="Иностранное удостоверение"
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormTextField
            name={getFullFieldName(driver.driverSelect, index, 'LastName')}
            fullWidth
            label="Фамилия"
            placeholder="Введите фамилию"
            required
            onChange={capitalizeField}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormTextField
            name={getFullFieldName(driver.driverSelect, index, 'FirstName')}
            fullWidth
            label="Имя"
            placeholder="Введите имя"
            required
            onChange={capitalizeField}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormTextField
            name={getFullFieldName(driver.driverSelect, index, 'MiddleName')}
            fullWidth
            label="Отчество"
            placeholder="Введите отчество"
            required
            onChange={capitalizeField}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 4 : 6}>
        <FormControl>
          <FormLabel complete>Пол</FormLabel>
          <RadioGroup
            row
            defaultValue="1"
            {...getFieldProps(`drivers[${index}].driverGender`)}
          >
            <FormControlLabel value="1" control={<Radio />} label="Мужской" />
            <FormControlLabel value="2" control={<Radio />} label="Женский" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {isDesktop ? <Grid item xs={4} /> : undefined}
      <Grid item xs={isDesktop ? 4 : 6}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormDatePicker
            label="Дата рождения"
            name={getFullFieldName(
              driver.driverSelect,
              index,
              'Birthdate',
              true
            )}
            required
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 3 : 6}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormTextField
            name={`drivers[${index}].driverLicenseSerial`}
            fullWidth
            label="Серия В/У"
            placeholder="Введите серию"
            required
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 3 : 6}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormTextField
            name={`drivers[${index}].driverLicenseNumber`}
            fullWidth
            label="Номер В/У"
            placeholder="Введите номер"
            required
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 3 : 6}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormDatePicker
            label="Дата начала стажа"
            required
            name={`drivers[${index}].experienceStartDate`}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 3 : 6}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormDatePicker
            label="Дата выдачи В/У"
            required
            name={`drivers[${index}].driverLicenseIssueDate`}
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 6 : 12}>
        <Tooltip
          title={kbm ? `КБМ = ${kbm}` : ''}
          arrow
          placement="top"
          disableFocusListener
        >
          <FormControlLabel
            control={
              <Switch
                {...getFieldProps(`drivers[${index}].isPreviousDriverLicense`)}
              />
            }
            label="Указать предыдущее В/У"
          />
        </Tooltip>
      </Grid>
      <Grid item xs={isDesktop ? 6 : 12} />
      {driver.isPreviousDriverLicense ? (
        <>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormTextField
                name={`drivers[${index}].previousDriverLastName`}
                fullWidth
                label="Фамилия в предыдущем В/У"
                placeholder="Введите фамилию"
                onChange={capitalizeField}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormTextField
                name={`drivers[${index}].previousDriverFirstName`}
                fullWidth
                label="Имя в предыдущем В/У"
                placeholder="Введите имя"
                onChange={capitalizeField}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormTextField
                name={`drivers[${index}].previousDriverMiddleName`}
                fullWidth
                label="Отчество в предыдущем В/У"
                placeholder="Введите отчество"
                onChange={capitalizeField}
              />
            </Tooltip>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormTextField
                name={`drivers[${index}].previousDriverLicenseSerial`}
                fullWidth
                label="Серия предыдущего В/У"
                placeholder="Введите серию"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 6}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormTextField
                name={`drivers[${index}].previousDriverLicenseNumber`}
                fullWidth
                label="Номер предыдущего В/У"
                placeholder="Введите номер"
              />
            </Tooltip>
          </Grid>
          <Grid item xs={isDesktop ? 4 : 12}>
            <Tooltip
              title={kbm ? `КБМ = ${kbm}` : ''}
              arrow
              placement="top"
              disableFocusListener
            >
              <FormDatePicker
                label="Дата выдачи предыдущего В/У"
                name={`drivers[${index}].previousDriverLicenseIssueDate`}
              />
            </Tooltip>
          </Grid>
        </>
      ) : undefined}
      {index < array.length - 1 ? (
        <Grid item xs={12}>
          <Divider />
        </Grid>
      ) : undefined}
    </>
  );
}
