import * as Yup from 'yup';
import { phoneRegex, polisSerialRegex, polisNumberRegex } from 'utils/regexes';

// ----------------------------------------------------------------------

const POLIS_START_MIN_VALIDATION_DATE = new Date(
  new Date(
    new Date(
      new Date(new Date().setDate(new Date().getDate() + 4)).setHours(0)
    ).setMinutes(0)
  ).setSeconds(-1)
);
const generalSchema = Yup.object().shape({
  // Общая информация
  clientPhone: Yup.string()
    .required('Телефон клиента обязателен')
    .matches(phoneRegex, 'Формат номера +7 (999) 999-99-99'),
  clientEmail: Yup.string()
    .email('Введите действующий e-mail')
    .required('E-mail обязателен'),
  target: Yup.string().required('Цель обязательна'),
  term: Yup.number().integer(),

  dateStart: Yup.date('Введите дату').min(
    POLIS_START_MIN_VALIDATION_DATE,
    'Дата некорректна'
  ),
  dateEnd: Yup.date('Введите дату').min(
    POLIS_START_MIN_VALIDATION_DATE,
    'Дата некоректна'
  ),
  prevPolicySerial: Yup.object({
    title: Yup.string(),
    value: Yup.string().matches(polisSerialRegex, 'Несоответствующий формат')
  }).nullable(),
  prevPolicyNumber: Yup.string(polisNumberRegex, 'Несоответствующий формат')
});

export default generalSchema;
