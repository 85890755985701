import * as Yup from 'yup';
import { documentNumberRegex, documentSerialRegex } from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const driverSchema = Yup.object().shape({
  licenseForeign: Yup.boolean(),

  firstName: Yup.string().required('Имя обязательно'),
  noMiddleName: Yup.boolean(),
  middleName: Yup.string().when('noMiddleName', (noMiddleName, schema) =>
    noMiddleName
      ? schema.notRequired()
      : schema.required('Отчество обязательно')
  ),
  lastName: Yup.string().required('Фамилия обязательна'),
  birthdate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .required('Дата рождения обязательна'),

  licenseSerial: Yup.string()
    .required('Серия обязательна')
    .when('licenseForeign', (licenseForeign, schema) =>
      !licenseForeign
        ? schema.matches(documentSerialRegex, 'Неверный формат')
        : undefined
    ),
  licenseNumber: Yup.string()
    .required('Номер обязателен')
    .when('isForeignIdentity', (isForeignIdentity, schema) =>
      !isForeignIdentity
        ? schema.matches(documentNumberRegex, 'Неверный формат')
        : undefined
    ),
  licenseDate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .required('Дата выдачи обязательна'),
  expDate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .required('Дата обязательна')

  // prevLicenseSerial: '',
  // prevLicenseNumber: '',
  // prevLicenseDate: '',
  // prevLicenseFirstname: '',
  // prevLicenseMiddlename: '',
  // prevLicenseLastname: ''
});

export default driverSchema;
