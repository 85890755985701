import * as Yup from 'yup';
import {
  bodyNumberRegex,
  documentSerialRegex,
  enginePowerRegex,
  phoneRegex,
  numberPlateRegex,
  VINNumberRegex,
  documentNumberRegex,
  polisSerialRegex,
  polisNumberRegex
} from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const POLIS_START_MIN_VALIDATION_DATE = new Date(
  new Date(
    new Date(
      new Date(new Date().setDate(new Date().getDate() + 4)).setHours(0)
    ).setMinutes(0)
  ).setSeconds(-1)
);
const newPolisSchema = Yup.object().shape({
  // Общая информация
  clientPhone: Yup.string()
    .required('Телефон клиента обязателен')
    .matches(phoneRegex, 'Формат номера +7 (999) 999-99-99'),
  clientEmail: Yup.string()
    .email('Введите действующий e-mail')
    .required('E-mail обязателен'),
  targetOfUse: Yup.string().required('Цель обязательна'),
  clientSelect: Yup.object({
    title: Yup.string(),
    value: Yup.number()
  }).required('Выбор клиента обязателен'),
  insuranceTerm: Yup.number().integer(),

  datePolisStart: Yup.date('Введите дату').min(
    POLIS_START_MIN_VALIDATION_DATE,
    'Дата некорректна'
  ),
  datePolisEnd: Yup.date('Введите дату').min(
    POLIS_START_MIN_VALIDATION_DATE,
    'Дата некоректна'
  ),
  withTrailer: Yup.boolean(),
  vehicleForeignRegistration: Yup.boolean(),
  vehicleGoesToRegistration: Yup.boolean(),
  previousPolisSerial: Yup.object({
    title: Yup.string(),
    value: Yup.string().matches(polisSerialRegex, 'Несоответствующий формат')
  }).nullable(),
  previousPolisNumber: Yup.string(polisNumberRegex, 'Несоответствующий формат'),

  // Блок Клиент
  clientIsInsurer: Yup.boolean(),
  clientFirstName: Yup.string().required('Имя обязательно'),
  clientMiddleName: Yup.string().required('Отчество обязательно'),
  clientLastName: Yup.string().required('Фамилия обязательна'),

  // Блок ТС
  withoutNumber: Yup.boolean(),
  numberPlate: Yup.string().when('withoutNumber', {
    is: true,
    then: Yup.string().matches(numberPlateRegex, 'Номер указан неверно'),
    otherwise: Yup.string()
      .required('Номер обязателен')
      .matches(numberPlateRegex, 'Номер указан неверно')
  }),
  vehicleBrand: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .required('Марка обязательна')
    .nullable(),
  vehicleModel: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .required('Модель обязательна')
    .nullable(),
  identifiersType: Yup.string(),
  identifiers: Yup.string()
    .required('Номер обязателен')
    .when('identifiersType', (identifiersType, schema) =>
      identifiersType === '1'
        ? schema.matches(VINNumberRegex, 'VIN введен не верно')
        : schema.matches(bodyNumberRegex, 'Номер введен неверно')
    ),
  vehicleDocumentType: Yup.string(),
  vehicleType: Yup.number(),
  yearOfManufacture: Yup.number()
    .required('Год обязателен')
    .min(1940, 'Неверный год')
    .max(new Date().getFullYear(), 'Год выше текущий даты'),
  enginePower: Yup.string()
    .required('Мощность обязательна')
    .matches(enginePowerRegex, 'Мощность введена неверно'),
  documentSerial: Yup.string()
    .when('vehicleDocumentType', (vehicleDocumentType, schema) =>
      vehicleDocumentType !== '3'
        ? schema.required('Серия обязательна')
        : undefined
    )
    .matches(documentSerialRegex, 'Неверная серия'),
  documentNumber: Yup.string()
    .required('Номер обязателен')
    .matches(documentNumberRegex, 'Неверный номер'),
  documentIssueDate: Yup.date('Дата некорректна')
    .required('Дата обязательна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна'),

  // Блок Страхователь
  insurerIsOwner: Yup.boolean(),
  insurerFirstName: Yup.string().when(
    'clientIsInsurer',
    (clientIsInsurer, schema) =>
      clientIsInsurer
        ? schema.notRequired()
        : schema.required('Имя обязательно')
  ),
  insurerNoMiddleName: Yup.boolean(),
  insurerMiddleName: Yup.string().when(
    ['clientIsInsurer', 'insurerNoMiddleName'],
    (clientIsInsurer, noMiddleName, schema) => {
      if (clientIsInsurer) {
        return schema.notRequired();
      }
      if (noMiddleName) {
        return schema.notRequired();
      }
      return schema.required('Отчество обязательно');
    }
  ),
  insurerLastName: Yup.string().when(
    'clientIsInsurer',
    (clientIsInsurer, schema) =>
      clientIsInsurer
        ? schema.notRequired()
        : schema.required('Фамилия обязательна')
  ),
  insurerGender: Yup.number(),
  insurerPassportType: Yup.string(),
  insurerBirthdate: Yup.date('Дата некорректна')
    .required('Дата рождения обязательна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна'),
  insurerPassportSerial: Yup.string()
    .required('Серия обязательна')
    .when('insurerPassportType', (insurerPassportType, schema) =>
      insurerPassportType === '0'
        ? schema.matches(documentSerialRegex, 'Неверный формат')
        : undefined
    ),
  insurerPassportNumber: Yup.string()
    .required('Номер обязателен')
    .when('insurerPassportType', (insurerPassportType, schema) =>
      insurerPassportType === '0'
        ? schema.matches(documentNumberRegex, 'Неверный формат')
        : undefined
    ),
  insurerPassportIssueDate: Yup.date('Дата некорректна')
    .required('Дата выдачи обязательна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна'),
  insurerPassportIssuedBy: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .required('Место выдачи обязательно')
    .nullable(),
  insurerRegistrationAddress: Yup.object({
    title: Yup.string(),
    value: Yup.object()
  })
    .required('Адрес регистрации обязателен')
    .nullable(),

  // Блок Собственник
  ownerFirstName: Yup.string().when(
    'insurerIsOwner',
    (insurerIsOwner, schema) =>
      insurerIsOwner ? schema.notRequired() : schema.required('Имя обязательно')
  ),
  ownerNoMiddleName: Yup.boolean(),
  ownerMiddleName: Yup.string().when(
    ['insurerIsOwner', 'ownerNoMiddleName'],
    (insurerIsOwner, ownerNoMiddleName, schema) => {
      if (insurerIsOwner) {
        return schema.notRequired();
      }
      if (ownerNoMiddleName) {
        return schema.notRequired();
      }
      return schema.required('Отчество обязательно');
    }
  ),
  ownerLastName: Yup.string().when('insurerIsOwner', (insurerIsOwner, schema) =>
    insurerIsOwner
      ? schema.notRequired()
      : schema.required('Фамилия обязательна')
  ),
  ownerGender: Yup.number(),
  ownerPassportType: Yup.string().when(
    'insurerIsOwner',
    (insurerIsOwner, schema) =>
      insurerIsOwner ? schema.notRequired() : schema.required('Тип обязателен')
  ),
  ownerBirthdate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Дата рождения обязательна')
    ),
  ownerPassportSerial: Yup.string()
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Серия обязательна')
    )
    .when('ownerPassportType', (ownerPassportType, schema) =>
      ownerPassportType === '0'
        ? schema.matches(documentSerialRegex, 'Неверный формат')
        : undefined
    ),
  ownerPassportNumber: Yup.string()
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Номер обязателен')
    )
    .when('ownerPassportType', (ownerPassportType, schema) =>
      ownerPassportType === '0'
        ? schema.matches(documentNumberRegex, 'Неверный формат')
        : undefined
    ),
  ownerPassportIssueDate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Дата выдачи обязательна')
    ),
  ownerPassportIssuedBy: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Место выдачи обязательно')
    )
    .nullable(),

  ownerRegistrationAddress: Yup.object({
    title: Yup.string(),
    value: Yup.object()
  })
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Адрес регистрации обязателен')
    )
    .nullable(),

  // Блок Водители
  drivers: Yup.array()
    .of(
      Yup.object({
        driversWithoutRestrictions: Yup.boolean().default(false),
        driverSelect: Yup.string(),
        isForeignIdentity: Yup.boolean(),
        driverFirstName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Имя обязательно')
              : schema.notRequired()
        ),
        driverMiddleName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Отчество обязательно')
              : schema.notRequired()
        ),
        driverLastName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Фамилия обязательна')
              : schema.notRequired()
        ),
        driverBirthdate: Yup.date('Дата некорректна')
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            ['driversWithoutRestrictions', 'driverSelect'],
            (driversWithoutRestrictions, driverSelect, schema) =>
              !driversWithoutRestrictions && driverSelect === 'Иное лицо'
                ? schema.required('Дата рождения обязательна')
                : schema.notRequired()
          ),
        driverLicenseSerial: Yup.string()
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Серия обязательна')
          )
          .when('isForeignIdentity', (isForeignIdentity, schema) =>
            !isForeignIdentity
              ? schema.matches(documentSerialRegex, 'Неверный формат')
              : undefined
          ),
        driverLicenseNumber: Yup.string()
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Номер обязателен')
          )
          .when('isForeignIdentity', (isForeignIdentity, schema) =>
            !isForeignIdentity
              ? schema.matches(documentNumberRegex, 'Неверный формат')
              : undefined
          ),
        experienceStartDate: Yup.date('Дата некорректна')
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Дата обязательна')
          ),
        driverLicenseIssueDate: Yup.date('Дата некорректна')
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Дата выдачи обязательна')
          ),
        isPreviousDriverLicense: Yup.boolean(),
        previousDriverLicenseSerial: Yup.string().matches(
          documentSerialRegex,
          'Неверный формат'
        ),
        previousDriverLicenseNumber: Yup.string().matches(
          documentNumberRegex,
          'Неверный формат'
        ),
        previousDriverLicenseIssueDate: Yup.date('Дата некорректна').min(
          MIN_VALIDATION_DATE,
          'Дата некорректна'
        ),
        previousDriverFirstName: Yup.string(),
        previousDriverMiddleName: Yup.string(),
        previousDriverLastName: Yup.string()
      })
    )
    .required()
  // Данные предыдущего полиса
});

export default newPolisSchema;
