import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'utils/axios';
// requests
import { getModelById } from 'requests/vehicles-requests';

// ----------------------------------------------------------------------

const initialState = {
  polisSerials: [],
  clients: [],
  targets: [],
  vehicleBrands: [],
  vehicleModels: [],
  docTypes: [],
  vehicleDocumentTypes: [],
  contractId: null,
  editContract: {
    id: null,
    eosago: null,
    client: null,
    vehicle: null,
    insurer: null,
    owner: null,
    drivers: []
  }
};

const slice = createSlice({
  name: 'osago',
  initialState,
  reducers: {
    setEditContract(state, action) {
      state.editContract.id = action.payload.contractId;
      state.editContract.eosago = action.payload.eosago;
      state.editContract.client = action.payload.client;
      state.editContract.vehicle = action.payload.vehicle;
      state.editContract.insurer = action.payload.insurer;
      state.editContract.owner = action.payload.owner;
      state.editContract.drivers = action.payload.drivers;
    },

    // Справочники
    getClientsSuccess(state, action) {
      state.clients = action.payload.clients;
    },
    getTargetsSuccess(state, action) {
      state.targets = action.payload.targets;
    },

    getVehicleBrandsSuccess(state, action) {
      state.vehicleBrands = action.payload.brands;
    },
    getVehicleModelsSuccess(state, action) {
      state.vehicleModels = action.payload.models;
    },
    getPolisSerialsSuccess(state, action) {
      state.polisSerials = action.payload.serials;
    },
    setContractId(state, action) {
      state.contractId = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { setContractId } = slice.actions;

// ----------------------------------------------------------------------

export function editContract(contract) {
  return async (dispatch) => {
    if (contract) {
      const {
        eosago = null,
        client = null,
        insurer = null,
        owner = null,
        drivers = []
      } = contract;
      let vehicle;
      ({ vehicle = null } = contract);

      if (vehicle) {
        const { modelId } = vehicle;
        const modelResponse = (await getModelById(modelId)) || {};

        vehicle = { ...vehicle, ...modelResponse };
      }

      dispatch(
        slice.actions.setEditContract({
          contractId: contract?.id ?? null,
          eosago,
          client,
          vehicle,
          insurer,
          owner,
          drivers
        })
      );
    }
  };
}

// ----------------------------------------------------------------------

// Клиенты агента
export function getClients() {
  return async (dispatch) => {
    // TODO : В запросе есть пагинация, это может всплыть дальше
    const response = await axios.get(`api/v1/users`);
    const { data } = response.data.Users;
    dispatch(slice.actions.getClientsSuccess({ clients: data }));
  };
}

// Цели использования
export function getTargets() {
  return async (dispatch) => {
    // TODO : В запросе есть пагинация, это может всплыть дальше
    const response = await axios.get(`api/v1/lists?type=targets`);
    const { results } = response.data;
    dispatch(slice.actions.getTargetsSuccess({ targets: results }));
  };
}

export function getVehicleBrands() {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/vehicles/brands`);
    const brands = response.data;
    dispatch(slice.actions.getVehicleBrandsSuccess({ brands }));
  };
}

export function getVehicleModels({ model }) {
  return async (dispatch) => {
    if (!model || model.length < 2) return;
    const response = await axios.get(`api/v1/vehicles?q=${model}`);
    const { models } = response.data;
    dispatch(slice.actions.getVehicleModelsSuccess({ models }));
  };
}

// export function getVehicleModels({ brand }) {
//   return async (dispatch) => {
//     if (!brand) return;
//     const response = await axios.get(`api/v1/vehicles/models?brandId=${brand}`);
//     const models = response.data;
//     dispatch(slice.actions.getVehicleModelsSuccess({ models }));
//   };
// }

export function getPolisSerials() {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/lists?type=policy_serials`);
    const { result = [] } = response.data;
    dispatch(slice.actions.getPolisSerialsSuccess({ serials: result }));
  };
}

// TODO: Неправильный метод
export function getVehicleDocTypes({ brand }) {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/vehicles/models?brand=${brand}`);
    const { models } = response.data;
    dispatch(slice.actions.getVehicleModelsSuccess({ models }));
  };
}
