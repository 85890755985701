import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'utils/axios';
// requests

// ----------------------------------------------------------------------

const initialState = {
  polisSerials: [],
  targets: []
};

const slice = createSlice({
  name: 'lists',
  initialState,
  reducers: {
    // Справочники
    getTargetsSuccess(state, action) {
      state.targets = action.payload;
    },
    getPolisSerialsSuccess(state, action) {
      state.polisSerials = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Цели использования
export function getTargets(targets) {
  return async (dispatch) => {
    // TODO : В запросе есть пагинация, это может всплыть дальше
    if (targets && targets.length === 0) {
      const response = await axios.get(`api/v1/lists?type=targets`);
      const { results } = response.data;
      dispatch(slice.actions.getTargetsSuccess(results));
    }
  };
}

export function getPolisSerials(serials) {
  return async (dispatch) => {
    if (serials && serials.length === 0) {
      const response = await axios.get(`api/v1/lists?type=policy_serials`);
      const { results = [] } = response.data;
      dispatch(slice.actions.getPolisSerialsSuccess(results));
    }
  };
}
