import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  InputLabel,
  FormControlLabel,
  Input,
  Grid,
  Checkbox,
  Typography,
  FormHelperText
} from '@material-ui/core';
//
import { useField, useFormikContext } from 'formik';

// ----------------------------------------------------------------------

RadioTextField.propTypes = {
  onInputChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  inputName: PropTypes.string,
  checkName: PropTypes.string,
  odd: PropTypes.bool,
  label: PropTypes.string,
  required: PropTypes.bool
};

export default function RadioTextField({
  label,
  onInputChange,
  onInputFocus,
  onInputBlur,
  inputName,
  checkName,
  odd,
  required
}) {
  const theme = useTheme();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const [field, meta, helpers] = useField(inputName);
  const [value, setValue] = useState(field.value);

  const [isActive, setActive] = useState(false);
  const [borderColor, setBorderColor] = useState(theme.palette.action.focus);

  const handleFocus = () => {
    setActive(true);
    helpers.setTouched(true);
    setBorderColor(theme.palette.primary.main);
    if (onInputFocus) onInputFocus(value);
  };

  const handleBlur = () => {
    setActive(false);
    setBorderColor(theme.palette.action.focus);
    if (value !== null) {
      setFieldValue(field.name, value);
      if (onInputBlur) onInputBlur(value);
    }
  };

  const handleMouseOver = () => {
    if (!isActive) {
      setBorderColor(theme.palette.text.primary);
    }
  };
  const handleMouseLeave = () => {
    if (!isActive) {
      setBorderColor(theme.palette.action.focus);
    }
  };

  const handleInputChange = (event) => {
    let val = event.target.value;
    if (onInputChange) val = onInputChange(event);
    setValue(val);
  };

  const getLabelColor = () => {
    if (meta.touched && meta.error) return theme.palette.error.main;
    if (value !== '') return theme.palette.text.primary;
    return theme.palette.text.secondary;
  };

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  return (
    <Box
      height={56}
      sx={{ backgroundColor: 'white' }}
      boxShadow={2}
      border={isActive ? 2 : 1}
      borderRadius={1}
      borderColor={
        meta.touched && meta.error ? theme.palette.error.main : borderColor
      }
    >
      <FormControl fullWidth size="small">
        <InputLabel
          shrink
          sx={{
            width: '100%',
            // top: '-33%',
            mt: -2,
            left: '3%',
            color: getLabelColor()
          }}
        >
          <Grid
            container
            alignContent="center"
            alignItems="center"
            direction="row"
          >
            <Grid item xs={3.5} container justifyContent="flex-start">
              <Typography
                sx={{
                  backgroundColor: odd
                    ? theme.palette.grey[200]
                    : theme.palette.background.default
                }}
              >{`${label} ${required ? ' * ' : ' '}`}</Typography>
            </Grid>
            <Grid item xs={8.5} container justifyContent="center">
              <FormControlLabel
                sx={{
                  backgroundColor: odd
                    ? theme.palette.grey[200]
                    : theme.palette.background.default
                }}
                value="1"
                control={<Checkbox {...getFieldProps(checkName)} />}
                label={<Typography>Отсутствует</Typography>}
              />
            </Grid>
          </Grid>
        </InputLabel>
        <Input
          disableUnderline
          sx={{ left: '3%', top: -2, borderWidth: 1 }}
          value={value}
          placeholder="Введите отчество"
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          onChange={handleInputChange}
        />
        <FormHelperText
          error
          id="component-helper-text"
          sx={{
            // backgroundColor: 'yellow',
            ml: 2,
            mt: 2
          }}
        >
          {meta.touched && meta.error}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
