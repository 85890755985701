import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { Form, FormikProvider, useFormik } from 'formik';
import { useSelector, useDispatch } from 'react-redux';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  Grid,
  Button,
  Switch,
  useMediaQuery,
  FormControlLabel,
  Typography
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
//
import {
  FormDatePicker,
  FormTextField
} from 'components/personal/form-components';
import { kbmSchema } from 'form_settings/validations';
import { findKBM } from 'requests/drivers-requests';
import { setKBMResult, setKBMSearchField } from 'redux/slices/osago/kbm';
//
import { ErrorAlert } from 'components/personal/mixed';

// ----------------------------------------------------------------------

CheckKBMForm.propTypes = {
  closeDialog: PropTypes.func
};

export default function CheckKBMForm({ closeDialog }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const { kbmSearch, kbmResult } = useSelector((state) => state.kbm);
  const [isError, setIsError] = useState(false);
  const [errorText, setErrorText] = useState('Ошибка');
  const [errorTitle, setErrorTitle] = useState('Ошибка');

  const formik = useFormik({
    // initialValues: kbmInitial,
    initialValues: {
      isForeignIdentity: kbmSearch.isForeignIdentity,
      firstName: kbmSearch.firstName,
      middleName: kbmSearch.middleName,
      lastName: kbmSearch.lastName,
      birthdate: kbmSearch.birthdate,
      licenseSerial: kbmSearch.licenseSerial,
      licenseNumber: kbmSearch.licenseNumber,
      isPreviousLicense: kbmSearch.isPreviousLicense,
      previousLicenseSerial: kbmSearch.previousLicenseSerial,
      previousLicenseNumber: kbmSearch.previousLicenseNumber,
      previousFirstName: kbmSearch.previousFirstName,
      previousMiddleName: kbmSearch.previousMiddleName,
      previousLastName: kbmSearch.previousLastName
    },
    validationSchema: kbmSchema,
    onSubmit: async (values) => {
      const { isPreviousLicense } = values;

      const kbmParams = {
        firstName: isPreviousLicense
          ? values.previousFirstName
          : values.firstName,
        middleName: isPreviousLicense
          ? values.previousMiddleName
          : values.middleName,
        lastName: isPreviousLicense ? values.previousLastName : values.lastName,
        birthdate: values.birthdate,
        licenseSerial: isPreviousLicense
          ? values.previousLicenseSerial
          : values.licenseSerial,
        licenseNumber: isPreviousLicense
          ? values.previousLicenseNumber
          : values.licenseNumber
      };

      try {
        const result = await findKBM(kbmParams);
        dispatch(setKBMResult(result?.detail[0]));
      } catch (exc) {
        console.log(exc);
        setErrorTitle(exc.message ?? 'Ошибка поиска КБМ');
        setErrorText(exc.description ?? exc.error ?? '');
        setIsError(true);
      }
    }
  });

  const {
    values: {
      isForeignIdentity,
      firstName,
      middleName,
      lastName,
      birthdate,
      licenseSerial,
      licenseNumber,
      isPreviousLicense,
      previousLicenseSerial,
      previousLicenseNumber,
      previousFirstName,
      previousMiddleName,
      previousLastName
    },
    isSubmitting,
    handleSubmit,
    getFieldProps
  } = formik;

  useEffect(() => {
    const kbmParams = {
      isForeignIdentity,
      firstName,
      middleName,
      lastName,
      birthdate,
      licenseSerial,
      licenseNumber,
      isPreviousLicense,
      previousLicenseSerial,
      previousLicenseNumber,
      previousFirstName,
      previousMiddleName,
      previousLastName
    };

    Object.entries(kbmParams)
      .filter(([_, value]) => Boolean(value))
      .forEach(([name, value]) => {
        dispatch(setKBMSearchField({ name, value }));
      });
  }, [
    dispatch,
    isForeignIdentity,
    firstName,
    middleName,
    lastName,
    birthdate,
    licenseSerial,
    licenseNumber,
    isPreviousLicense,
    previousLicenseSerial,
    previousLicenseNumber,
    previousFirstName,
    previousMiddleName,
    previousLastName
  ]);

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <Box
          sx={{ display: 'flex', justifyContent: 'center' }}
          flexDirection="column"
        >
          <Grid container spacing={4}>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(`isForeignIdentity`)}
                    defaultChecked={isForeignIdentity}
                  />
                }
                label="Иностранное удостоверение"
              />
            </Grid>
            {isDesktop ? <Grid item xs={8} /> : undefined}

            <Grid item xs={isDesktop ? 4 : 12}>
              <FormTextField
                fullWidth
                label="Фамилия"
                placeholder="Введите фамилию"
                name="lastName"
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormTextField
                fullWidth
                label="Имя"
                placeholder="Введите имя"
                name="firstName"
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormTextField
                fullWidth
                label="Отчество"
                placeholder="Введите отчество"
                name="middleName"
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormTextField
                fullWidth
                label="Серия В/У"
                placeholder="Введите серию"
                name="licenseSerial"
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormTextField
                fullWidth
                label="Номер В/У"
                placeholder="Введите номер"
                name="licenseNumber"
              />
            </Grid>
            <Grid item xs={isDesktop ? 4 : 12}>
              <FormDatePicker label="Дата рождения" name="birthdate" />
            </Grid>

            <Grid item xs={isDesktop ? 6 : 12}>
              <FormControlLabel
                control={
                  <Switch
                    {...getFieldProps(`isPreviousLicense`)}
                    defaultChecked={isPreviousLicense}
                  />
                }
                label="Указать предыдущее В/У"
              />
            </Grid>
            <Grid item xs={isDesktop ? 6 : 12} />
            {isPreviousLicense ? (
              <>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <FormTextField
                    fullWidth
                    label="Фамилия в предыдущем В/У"
                    placeholder="Введите фамилию"
                    name="previousLastName"
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <FormTextField
                    fullWidth
                    label="Имя в предыдущем В/У"
                    placeholder="Введите имя"
                    name="previousFirstName"
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 12}>
                  <FormTextField
                    fullWidth
                    label="Отчество в предыдущем В/У"
                    placeholder="Введите отчество"
                    name="previousMiddleName"
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 6}>
                  <FormTextField
                    fullWidth
                    label="Серия предыдущего В/У"
                    placeholder="Введите серию"
                    name="previousLicenseSerial"
                  />
                </Grid>
                <Grid item xs={isDesktop ? 4 : 6}>
                  <FormTextField
                    fullWidth
                    label="Номер предыдущего В/У"
                    placeholder="Введите номер"
                    name="previousLicenseNumber"
                  />
                </Grid>
              </>
            ) : undefined}
          </Grid>

          <Box sx={{ mt: 4, display: 'flex', justifyContent: 'flex-end' }}>
            <Grid container spacing={4}>
              {!isDesktop ? (
                <Grid item xs={12}>
                  {kbmResult ? (
                    <Typography variant="h5">
                      {kbmResult?.found
                        ? `Найдено. КБМ = ${kbmResult.factor}`
                        : 'Не найдено'}
                    </Typography>
                  ) : undefined}
                </Grid>
              ) : undefined}
              <Grid item xs={isDesktop ? 3 : 6}>
                <Button fullWidth variant="contained" onClick={closeDialog}>
                  Закрыть
                </Button>
              </Grid>
              <Grid item xs={isDesktop ? 3 : 6}>
                <LoadingButton
                  fullWidth
                  type="submit"
                  variant="contained"
                  pending={isSubmitting}
                >
                  Проверить КБМ
                </LoadingButton>
              </Grid>

              {isDesktop ? (
                <Grid item xs={6}>
                  {kbmResult ? (
                    <Typography variant="h5">
                      {kbmResult?.found
                        ? `Найдено. КБМ = ${kbmResult.factor}`
                        : 'Не найдено'}
                    </Typography>
                  ) : (
                    <ErrorAlert
                      open={isError}
                      title={errorTitle}
                      message={errorText}
                      // onClose={() => setIsError(false)}
                    />
                  )}
                </Grid>
              ) : undefined}
            </Grid>
          </Box>
        </Box>
      </Form>
    </FormikProvider>
  );
}
