const calculatorInitial = {
  // Блок Общая информация
  vehicleType: '1',
  enginePower: '',
  year: '',
  datePolisStart: new Date(new Date().setDate(new Date().getDate() + 3)),
  insuranceTerm: 12,
  withTrailer: false,

  // Блок Водители
  drivers: [
    {
      driversWithoutRestrictions: false,
      driverFirstName: '',
      driverMiddleName: '',
      driverLastName: '',
      driverGender: '1',
      driverSelect: 'Иное лицо',
      isForeignIdentity: false,
      driverBirthdate: '',
      driverLicenseSerial: '',
      driverLicenseNumber: '',
      experienceStartDate: '',
      driverLicenseIssueDate: '',

      // Подблок предыдущее В/У
      isPreviousDriverLicense: false,
      previousDriverLicenseSerial: '',
      previousDriverLicenseNumber: '',
      previousDriverLicenseIssueDate: '',
      previousDriverFirstName: '',
      previousDriverMiddleName: '',
      previousDriverLastName: ''
    }
  ],

  // Блок Собственник срабатывает в случае если Водители выбрано без ограничений
  ownerFirstName: '',
  ownerNoMiddleName: false,
  ownerMiddleName: '',
  ownerLastName: '',
  ownerGender: '1',
  ownerPassportType: '0',
  ownerBirthdate: '',
  ownerPassportSerial: '',
  ownerPassportNumber: '',
  ownerPassportIssueDate: '',
  ownerPassportIssuedBy: null,
  ownerRegistrationAddress: null
};
export default calculatorInitial;
