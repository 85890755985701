import { toPhoneMask } from 'utils/maskFuncs';
import { stringToDate, getClientLabel } from 'utils/textTransformation';

const POLIS_START_MIN_VALIDATION_DATE = new Date(
  new Date().setDate(new Date().getDate() + 4)
);

export default function editPolisInitial(editContract) {
  const { client, eosago, vehicle, insurer, owner, drivers } = editContract;
  const clientIsInsurer =
    client?.firstName === insurer?.firstName &&
    client?.middleName === insurer?.middleName &&
    client?.lastName === insurer?.lastName;

  const driversInit = drivers.map((driver) => {
    let driverSelect = 'Иное лицо';
    if (insurer?.insurerIsOwner) {
      driverSelect =
        driver?.firstName === insurer?.firstName &&
        driver?.middleName === insurer?.middleName &&
        driver?.lastName === insurer?.lastName
          ? 'Страхователь'
          : 'Иное лицо';
    } else {
      driverSelect =
        driver?.firstName === owner?.firstName &&
        driver?.middleName === owner?.middleName &&
        driver?.lastName === owner?.lastName
          ? 'Страхователь'
          : 'Иное лицо';
    }

    return {
      driversWithoutRestrictions: false,
      driverSelect,
      isForeignIdentity: driver?.licenseForeign ?? false,
      driverFirstName: driver?.firstName ?? '',
      driverMiddleName: driver?.middleName ?? '',
      driverLastName: driver?.lastName ?? '',
      driverGender: `${driver.genderId ?? 1}`,
      driverBirthdate: stringToDate(driver?.birthdate) ?? '',
      driverLicenseSerial: driver?.licenseSerial ?? '',
      driverLicenseNumber: driver?.licenseNumber ?? '',
      experienceStartDate: stringToDate(driver?.expDate) ?? '',
      driverLicenseIssueDate: stringToDate(driver?.licenseDate) ?? '',

      // Подблок предыдущее В/У
      isPreviousDriverLicense: false,
      previousDriverLicenseSerial: driver?.prevLicenseSerial ?? '',
      previousDriverLicenseNumber: driver?.prevLicenseNumber ?? '',
      previousDriverLicenseIssueDate:
        stringToDate(driver?.prevLicenseDate) ?? '',
      previousDriverFirstName: driver?.prevLicenseFirstname ?? '',
      previousDriverMiddleName: driver?.prevLicenseMiddlename ?? '',
      previousDriverLastName: driver?.prevLicenseLastname ?? ''
    };
  });

  const generalInit = {
    clientPhone: toPhoneMask(client?.phone) ?? '',
    clientEmail: client?.email ?? '',
    targetOfUse: `${eosago?.target ?? 1}`,
    clientSelect: {
      title: getClientLabel(client) ?? 'Создать нового',
      value: `${client?.id ?? -1}`
    },
    insuranceTerm: eosago?.term ?? 12,
    datePolisStart:
      stringToDate(eosago?.dateStart) ?? POLIS_START_MIN_VALIDATION_DATE,
    datePolisEnd: stringToDate(eosago?.dateEnd) ?? new Date(),
    withTrailer: Boolean(eosago?.useTrailer),
    previousPolisSerial: eosago?.prevPolicySerial
      ? {
          title: eosago?.prevPolicySerial,
          value: eosago?.prevPolicySerial
        }
      : null,
    previousPolisNumber: eosago?.prevPolicyNumber,
    // Следующие две опции на данный момент недоступны
    vehicleForeignRegistration: false,
    vehicleGoesToRegistration: false
  };

  const clientInit = {
    clientIsInsurer,
    clientFirstName: client?.firstName ?? '',
    noMiddleName: false,
    clientMiddleName: client?.middleName ?? '',
    clientLastName: client?.lastName ?? ''
  };

  const vehicleInit = {
    numberPlate: vehicle?.numberPlate ?? '',
    withoutNumber: false ?? '',
    vehicleBrand: vehicle?.brandId
      ? {
          title: vehicle.brandName,
          value: vehicle.brandId
        }
      : null,
    vehicleModel: vehicle?.modelName
      ? {
          title: vehicle.modelName,
          value: vehicle.modelId
        }
      : null,
    identifiersType: `${vehicle?.identifiersType ?? 1}`,
    identifiers: vehicle?.identifiers ?? '',
    vehicleDocumentType: `${vehicle?.docType ?? 1}`,
    vehicleType: `${vehicle?.type ?? 1}`,
    yearOfManufacture: vehicle?.year ?? '',
    enginePower: vehicle?.power ?? '',
    documentSerial: vehicle?.docSerial ?? '',
    documentNumber: vehicle?.docNumber ?? '',
    documentIssueDate: vehicle?.docDate ?? ''
  };

  const insurerInit = {
    insurerIsOwner: insurer?.insurerIsOwner,
    insurerFirstName: insurer?.firstName ?? '',
    insurerNoMiddleName: false,
    insurerMiddleName: insurer?.middleName ?? '',
    insurerLastName: insurer?.lastName ?? '',
    insurerGender: `${insurer?.genderId ?? 1}`,
    insurerPassportType: `${insurer?.passportForeign ?? 0}`,
    insurerBirthdate: stringToDate(insurer?.birthdate) ?? '',
    insurerPassportSerial: insurer?.passportSerial,
    insurerPassportNumber: insurer?.passportNumber,
    insurerPassportIssueDate: stringToDate(insurer?.passportDate) ?? '',
    insurerPassportIssuedBy: insurer?.passportPoint
      ? {
          title: insurer?.passportPoint,
          value: insurer?.passportPoint
        }
      : null,
    insurerRegistrationAddress: insurer?.dadata
      ? {
          title: JSON.parse(insurer?.dadata).value,
          value: JSON.parse(insurer?.dadata)
        }
      : null
  };

  const ownerInit = {
    ownerFirstName: owner?.firstName ?? '',
    ownerNoMiddleName: false,
    ownerMiddleName: owner?.middleName ?? '',
    ownerLastName: owner?.lastName ?? '',
    ownerGender: `${owner?.genderId ?? 1}`,
    ownerPassportType: `${owner?.passportForeign ?? 0}`,
    ownerBirthdate: stringToDate(owner?.birthdate) ?? '',
    ownerPassportSerial: owner?.passportSerial ?? '',
    ownerPassportNumber: owner?.passportNumber ?? '',
    ownerPassportIssueDate: stringToDate(owner?.passportDate) ?? '',
    ownerPassportIssuedBy: owner?.passportPoint
      ? {
          title: owner?.passportPoint,
          value: owner?.passportPoint
        }
      : null,
    ownerRegistrationAddress: owner?.dadata
      ? {
          title: JSON.parse(owner?.dadata).value,
          value: JSON.parse(owner?.dadata)
        }
      : null
  };

  return {
    ...generalInit,

    // // Блок Клиент
    ...clientInit,

    // // Блок ТС
    ...vehicleInit,

    // // Блок Страхователь
    ...insurerInit,

    // // Блок Собственник
    ...ownerInit,

    // Блок Водители

    drivers: driversInit
  };
}
