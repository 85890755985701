import { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  useMediaQuery,
  FormControl,
  FormLabel,
  Radio,
  RadioGroup,
  Checkbox,
  FormControlLabel
} from '@material-ui/core';
//
import {
  BlockTitle,
  FormBlock,
  FormDatePicker,
  FormTextField
} from 'components/personal/form-components';
import { MAutocomplete as Autocomplete } from 'components/@material-extend';
import {
  getPassportOffices,
  getRegistrationAddresses
} from 'redux/slices/dadata';

export default function OwnerBlock() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const { passportOffices, registrationAddresses } = useSelector(
    (state) => state.dadata
  );

  const { getFieldProps } = useFormikContext();

  const handlePassportIssuedByChange = useCallback(
    (value) => {
      dispatch(getPassportOffices({ query: value }));
    },
    [dispatch]
  );
  const handleRegistrationAddressChange = useCallback(
    (value) => {
      dispatch(getRegistrationAddresses({ query: value }));
    },
    [dispatch]
  );
  return (
    <FormBlock title={<BlockTitle title="Собственник" />}>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="ownerLastName"
          fullWidth
          label="Фамилия"
          placeholder="Введите фамилию"
        />
      </Grid>

      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="ownerFirstName"
          fullWidth
          label="Имя"
          placeholder="Введите имя"
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="ownerMiddleName"
          fullWidth
          label="Отчество"
          placeholder="Введите отчество"
        />
      </Grid>

      <Grid item xs={isDesktop ? 4 : 6}>
        <FormControl>
          <FormLabel complete>Пол</FormLabel>
          <RadioGroup row defaultValue="1" {...getFieldProps(`ownerGender`)}>
            <FormControlLabel value="1" control={<Radio />} label="Мужской" />
            <FormControlLabel value="2" control={<Radio />} label="Женский" />
          </RadioGroup>
        </FormControl>
      </Grid>
      {isDesktop ? <Grid item xs={4} /> : undefined}

      <Grid item xs={isDesktop ? 4 : 6}>
        <FormControlLabel
          control={
            <Checkbox {...getFieldProps('ownerNoMiddleName')} color="primary" />
          }
          label="Отчество отсутствует"
        />
      </Grid>

      <Grid item xs={isDesktop ? 4 : 6}>
        <FormControl>
          <FormLabel complete>Тип документа</FormLabel>
          <RadioGroup
            row
            defaultValue="0"
            {...getFieldProps(`ownerPassportType`)}
          >
            <FormControlLabel
              value="0"
              control={<Radio />}
              label="Паспорт РФ"
            />
            <FormControlLabel value="1" control={<Radio />} label="Другой" />
          </RadioGroup>
        </FormControl>
      </Grid>

      {isDesktop ? <Grid item xs={4} /> : undefined}

      <Grid item xs={isDesktop ? 4 : 6}>
        <FormDatePicker name="ownerBirthdate" label="Дата рождения" />
      </Grid>

      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="ownerPassportSerial"
          fullWidth
          label="Серия документа"
          placeholder="Введите серию"
          required
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="ownerPassportNumber"
          fullWidth
          label="Номер документа"
          placeholder="Введите номер"
          required
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormDatePicker name="ownerPassportIssueDate" label="Дата выдачи" />
      </Grid>

      <Grid item xs={isDesktop ? 6 : 12}>
        <Autocomplete
          id="ownerPassportIssuedBy"
          name="ownerPassportIssuedBy"
          options={passportOffices.map((office) => ({
            title: `${office.data.name} ${office.data.code}`,
            value: office.value
          }))}
          getOptionLabel={(option) => option.title}
          filterOptions={(options) => options}
          onChangeInputAction={handlePassportIssuedByChange}
          fullWidth
          renderInputParams={{
            required: true,
            name: 'ownerPassportIssuedBy',
            label: 'Кем выдан',
            placeholder: 'Для быстрого поиска можно ввести код подразделения'
          }}
        />
      </Grid>

      <Grid item xs={isDesktop ? 6 : 12}>
        <Autocomplete
          id="ownerRegistrationAddress"
          name="ownerRegistrationAddress"
          options={registrationAddresses.map((address) => ({
            title: address.value,
            value: address
          }))}
          filterOptions={(options) => options}
          onChangeInputAction={handleRegistrationAddressChange}
          getOptionLabel={(option) => option.title}
          // style={{}}
          fullWidth
          renderInputParams={{
            required: true,
            name: 'ownerRegistrationAddress',
            label: 'Адрес регистрации',
            placeholder: 'Начните ввод'
          }}
        />
      </Grid>
    </FormBlock>
  );
}
