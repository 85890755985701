import EndIcon from '@material-ui/icons/CloseRounded';
import { Box, useTheme, Button, IconButton } from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import DateRangePicker from '@material-ui/lab/DateRangePicker';
import { setDateFromToFilter } from 'redux/slices/osago/contracts';
import { fDateShort } from 'utils/formatTime';

const initialDateFrom = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  1
);
const initialDateTo = new Date(
  new Date().getFullYear(),
  new Date().getMonth(),
  new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
);

export default function DateFilter() {
  const theme = useTheme();
  const dispatch = useDispatch();
  const [value, setValue] = useState([initialDateFrom, initialDateTo]);
  const [open, setOpen] = useState(false);
  const { dateFrom, dateTo } = useSelector((state) => state.contracts.filters);

  const handleClick = () => {
    setOpen(true);
  };
  const handleClearClick = (e) => {
    e.stopPropagation();
    dispatch(
      setDateFromToFilter({ dateFrom: initialDateFrom, dateTo: initialDateTo })
    );
    setValue([initialDateFrom, initialDateTo]);
  };

  const handleChange = (newValue) => {
    if (newValue[0] && newValue[1]) {
      setValue(newValue);
      console.log({ newValue });
      dispatch(
        setDateFromToFilter({ dateFrom: newValue[0], dateTo: newValue[1] })
      );
      setOpen(false);
    }
  };

  useEffect(() => {
    if (dateFrom === value[0] && dateTo === value[1]) return;
    setValue([dateFrom, dateTo]);
  }, [dateFrom, dateTo, value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns}>
      <DateRangePicker
        value={value}
        onChange={handleChange}
        open={open}
        renderInput={
          (startProps, _) => (
            <Box
              sx={{
                px: 1,
                py: 1,
                display: 'flex'
              }}
              alignItems="center"
              justifyContent="space-between"
            >
              <Button
                variant="outlined"
                ref={startProps.ref}
                size="small"
                onClick={handleClick}
                disableRipple
                sx={{
                  backgroundColor: 'white',
                  borderRadius: 15,
                  color: theme.palette.grey[700],
                  borderColor: theme.palette.grey[700]
                }}
                endIcon={
                  <IconButton size="small" onClick={handleClearClick}>
                    <EndIcon sx={{ color: 'red' }} fontSize="small" />
                  </IconButton>
                }
              >
                {(value[0] && fDateShort(value[0])) ?? ''} -{' '}
                {(value[1] && fDateShort(value[1])) ?? ''}
              </Button>
            </Box>
          )

          //   (
          //   <>

          //     <Button
          //       ref={startProps.ref}
          //       onClick={() => {
          //       }}
          //     >
          //       d
          //     </Button>
          //     <TextField {...startProps} />
          //     <TextField {...endProps} />
          //   </>
          // )
        }
      />
    </LocalizationProvider>
  );

  // return (
  //   <Box
  //     sx={{
  //       px: 1,
  //       py: 1,
  //       display: 'flex'
  //     }}
  //     alignItems="center"
  //     justifyContent="space-between"
  //   >
  //     <Button
  //       variant="outlined"
  //       to
  //       size="small"
  //       onClick={handleClick}
  //       disableRipple
  //       sx={{
  //         backgroundColor: 'white',
  //         borderRadius: 15,
  //         color: theme.palette.grey[700],
  //         borderColor: theme.palette.grey[700]
  //       }}
  //       endIcon={
  //         <IconButton size="small" onClick={handleClearClick}>
  //           <EndIcon sx={{ color: 'red' }} fontSize="small" />
  //         </IconButton>
  //       }
  //     >
  //       01 окт. - 08 окт.
  //     </Button>
  //   </Box>
  // );
}
