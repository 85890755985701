import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  Card,
  useMediaQuery,
  Switch,
  MenuItem,
  FormControl,
  CardContent,
  CircularProgress
} from '@material-ui/core';
import {
  MInputLabel as InputLabel,
  MSelect as Select,
  MAutocomplete as Autocomplete,
  MIconButton
} from 'components/@material-extend';
//
import {
  SwitchField,
  FormDatePicker,
  FormTextField,
  FormPhoneInput
} from 'components/personal/form-components';
// utils
import { clearPhoneMask } from 'utils/maskFuncs';
import { phoneRegex } from 'utils/regexes';
import { toUpperCaseField } from 'utils/textTransformation';
// requests
import { createClient, searchClientsByPhone } from 'requests/users-requests';
import { getClientVehiclesList } from 'requests/vehicles-requests';
// from_settings
import { generalSchema } from 'form_settings/validations/new_polis_validations';
// redux
import { saveGeneral, setClient } from 'redux/slices/osago/edit_contract';
import ChooseVehicleDialog from './ChooseVehicleDialog';

// ----------------------------------------------------------------------

const polisStartDefaultDate = new Date(
  new Date().setDate(new Date().getDate() + 3)
);

const getPolisEndDate = (dateStart, term) => {
  const endDate = new Date(dateStart);
  try {
    endDate.setMonth(dateStart.getMonth() + term);
    endDate.setDate(endDate.getDate() - 1);
    return endDate;
  } catch (exc) {
    console.error(exc);
  }
  return new Date(dateStart);
};

export default function GeneralInformation() {
  const [openVehicleDialog, setOpenVehicleDialog] = useState(false);
  const [vehicles, setVehicles] = useState([]);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const [isLoading, setLoading] = useState(false);

  const dispatch = useDispatch();

  const { general, client, contract, updateGeneral, isEdit } = useSelector(
    (state) => state.editContract
  );
  const { targets, polisSerials } = useSelector((state) => state.lists);

  const polisSerialsList = polisSerials.map((serial) => ({
    title: serial.name,
    value: serial.name
  }));

  const formik = useFormik({
    initialValues: general,
    validationSchema: generalSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      const formGeneral = {
        clientId: client?.id,
        term: values.term,
        target: values.target,
        dateStart: values.dateStart,
        dateEnd: values.dateEnd,
        // TODO: Исправить multidriver
        // multiDriver: Number(values.drivers[0].driversWithoutRestrictions),
        ...(values.prevPolicySerial &&
          values.prevPolicyNumber && {
            prevPolicySerial: values.prevPolicySerial.value,
            prevPolicyNumber: values.prevPolicyNumber
          }),
        useTrailer: Number(values.useTrailer)
        // createContract: !contract
      };
      try {
        if (!client) {
          closeSnackbar();
          enqueueSnackbar(`Сохранение клиента...`, {
            variant: 'info',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

          const { contact } = await createClient({
            phone: clearPhoneMask(values.clientPhone),
            email: values.clientEmail
          });

          console.log({
            contact,
            ...{
              phone: clearPhoneMask(values.clientPhone),
              email: values.clientEmail
            }
          });

          dispatch(setClient(contact));
          formGeneral.clientId = contact.id;
          closeSnackbar();
        }

        dispatch(
          saveGeneral({
            general: { ...formGeneral, id: general.id },
            contractId: contract?.id
          })
        );
      } catch (exc) {
        console.log(exc);
        if (exc.phone) {
          enqueueSnackbar('Клиент с указанным телефоном уже существует', {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }

        if (exc.email) {
          enqueueSnackbar('Клиент с указанным Email уже существует', {
            variant: 'error',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
        }
        // enqueueSnackbar('Ошибка сохранения', {
        //   variant: 'error',
        //   action: (key) => (
        //     <MIconButton size="small" onClick={() => closeSnackbar(key)}>
        //       <Icon icon={closeFill} />
        //     </MIconButton>
        //   )
        // });
      }
    }
  });

  const {
    handleSubmit,
    values,
    isValid,
    submitForm,
    errors,
    touched,
    getFieldProps,
    setFieldValue,
    setValues
  } = formik;

  useEffect(() => {
    if (isValid) {
      submitForm();
    }
  }, [values, isValid, submitForm]);

  useEffect(() => {
    if (isEdit) return;
    async function fetchData() {
      if (values.clientPhone.search(phoneRegex) !== -1) {
        const phone = clearPhoneMask(values.clientPhone);
        enqueueSnackbar(`Поиск клиента...`, {
          variant: 'info',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });

        try {
          // TODO: Проверить поиск и возможно переработать
          const { Users } = (await searchClientsByPhone(phone)) || {};

          if (!Users) return;
          if (Users.data?.length === 0) return;

          enqueueSnackbar(`Найден клиент`, {
            variant: 'success',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });

          dispatch(setClient(Users.data[0]));
          setFieldValue('clientEmail', Users.data[0].email);

          // TODO: Поиск авто по номеру клиента
          const { vehicles } =
            (await getClientVehiclesList(Users.data[0].userId)) || {};

          if (vehicles && vehicles.length > 0) {
            setVehicles(vehicles);
            setOpenVehicleDialog(true);
          }
          // setVehicles([]);
          // setOpenVehicleDialog(true);
        } catch (e) {
          closeSnackbar();
        }
      }
    }

    fetchData();
  }, [
    values.clientPhone,
    setFieldValue,
    dispatch,
    closeSnackbar,
    enqueueSnackbar,
    isEdit
  ]);

  useEffect(() => {
    setFieldValue('dateEnd', getPolisEndDate(values.dateStart, values.term));
  }, [values.term, values.dateStart, setFieldValue, dispatch]);

  useEffect(() => {
    if (!updateGeneral) return;
    async function fetchData() {
      setLoading(true);
      console.log('UPDATE GENERAL');
      setValues({ ...updateGeneral, ...values });
      setLoading(false);
    }
    fetchData();
  }, [updateGeneral, setValues /* values */]);

  if (isLoading)
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  return (
    <FormikProvider value={formik}>
      <ChooseVehicleDialog
        open={openVehicleDialog}
        closeAction={() => {
          setOpenVehicleDialog(false);
        }}
        vehicles={vehicles}
      />
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <Grid item sm={12} xs={12}>
                    <FormControl
                      fullWidth
                      variant="outlined"
                      error={Boolean(touched.target && errors.target)}
                    >
                      <InputLabel complete>Цель использования</InputLabel>
                      <Select
                        label="Цель использования"
                        {...getFieldProps('target')}
                      >
                        {targets.map((target) => (
                          <MenuItem key={target.id} value={target.id}>
                            {target.description}
                          </MenuItem>
                        ))}
                      </Select>
                    </FormControl>
                  </Grid>
                  <SwitchField
                    label="Защита от спама"
                    switcher={
                      <Switch
                        checked={false}
                        onChange={() => {
                          alert('На данный момент опция недоступна');
                        }}
                      />
                    }
                  />
                  <SwitchField
                    label="ТС с прицепом"
                    switcher={<Switch {...getFieldProps('useTrailer')} />}
                  />
                  <SwitchField
                    label="ТС зарегистрировано в другой стране"
                    switcher={
                      <Switch
                        checked={false}
                        onChange={() => {
                          alert('На данный момент опция недоступна');
                        }}
                      />
                    }
                  />
                  <Grid item xs={12} />

                  <SwitchField
                    label="ТС следует к месту регистрации"
                    switcher={
                      <Switch
                        checked={false}
                        onChange={() => {
                          alert('На данный момент опция недоступна');
                        }}
                      />
                    }
                  />
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={isDesktop ? 6 : 12}>
                    <FormPhoneInput
                      name="clientPhone"
                      fullWidth
                      label="Телефон клиента"
                      placeholder="Введите телефон клиента"
                      required
                      // onChange={handlePhoneInputChange}
                      InputLabelProps={{ shrink: true }}
                    />
                  </Grid>
                  <Grid item xs={isDesktop ? 6 : 12}>
                    <FormTextField
                      name="clientEmail"
                      fullWidth
                      label="E-mail клиента"
                      placeholder="Введите Email клиента"
                    />
                  </Grid>
                  <Grid item xs={isDesktop ? 6 : 12}>
                    <FormDatePicker
                      label="Дата начала действия полиса"
                      defaultValue={polisStartDefaultDate}
                      name="dateStart"
                      // onBlur={(value) => {
                      //   setFieldValue(
                      //     'dateEnd',
                      //     getPolisEndDate(value, values.term)
                      //   );
                      // }}
                    />
                  </Grid>
                  <Grid item xs={isDesktop ? 6 : 12}>
                    <FormDatePicker
                      readOnly
                      label="Дата окончания действия полиса"
                      name="dateEnd"
                    />
                  </Grid>

                  <Grid item xs={12} />

                  <Grid item xs={isDesktop ? 6 : 12}>
                    <FormControl fullWidth variant="outlined">
                      <InputLabel complete>Срок страхования</InputLabel>
                      <Select
                        label="Срок страхования"
                        defaultValue={12}
                        {...getFieldProps('term')}
                      >
                        <MenuItem value={3}>3 месяца</MenuItem>
                        <MenuItem value={4}>4 месяца</MenuItem>
                        <MenuItem value={5}>5 месяцев</MenuItem>
                        <MenuItem value={6}>6 месяцев</MenuItem>
                        <MenuItem value={7}>7 месяцев</MenuItem>
                        <MenuItem value={8}>8 месяцев</MenuItem>
                        <MenuItem value={9}>9 месяцев</MenuItem>
                        <MenuItem value={12}>12 месяцев</MenuItem>
                      </Select>
                    </FormControl>
                  </Grid>

                  <Grid item xs={isDesktop ? 6 : 12} container spacing={1}>
                    <Grid item xs={4}>
                      <Autocomplete
                        id="prevPolicySerial"
                        name="prevPolicySerial"
                        options={polisSerialsList}
                        getOptionLabel={(option) => option.title}
                        onChangeInput={toUpperCaseField}
                        fullWidth
                        // filterOptions={(options, params) => {
                        //   const filtered = filter(options, params);
                        //   return filtered;
                        // }}
                        renderInputParams={{
                          name: 'prevPolicySerial',
                          label: 'Серия',
                          placeholder: 'Введите серию'
                        }}
                      />
                    </Grid>
                    <Grid item xs={8}>
                      <FormTextField
                        fullWidth
                        label="Номер предыдущего полиса"
                        name="prevPolicyNumber"
                        placeholder="Введите номер"
                      />
                    </Grid>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
