import * as Yup from 'yup';

// ----------------------------------------------------------------------

const accountRequisitsSchema = Yup.object().shape({
  bankName: Yup.string(),
  entityName: Yup.string(),
  inn: Yup.string().matches(/^[0-9]+$/g, 'ИНН состоит из цифр'),
  kpp: Yup.string().matches(/^[0-9]{9}$/g, 'КПП состоит из 9 цифр'),
  ogrn: Yup.string().matches(/^[0-9]{13}$/g, 'ОГРН состоит из 13 цифр'),
  entityAddress: Yup.object()
    .shape({
      title: Yup.string(),
      value: Yup.string()
    })
    .nullable(),
  address: Yup.object()
    .shape({
      title: Yup.string(),
      value: Yup.string()
    })
    .nullable(),
  settlementAccount: Yup.string(),
  bik: Yup.string().matches(/^[0-9]{9}$/g, 'БИК состоит из 9 цифр'),
  correspondentAccount: Yup.string()
});

export default accountRequisitsSchema;
