import { createSlice } from '@reduxjs/toolkit';
// utils
import {
  deleteEosagoContract,
  getContracts as getContractsReq
} from 'requests/eosago-contracts-requests';
import { getUserChildren } from 'requests/users-requests';

// ----------------------------------------------------------------------

const initialState = {
  contracts: [],
  isLoad: false,
  statuses: [
    {
      id: 0,
      name: 'Все'
    },
    {
      id: 1,
      name: 'Черновик'
    },
    {
      id: 2,
      name: 'Ожидает оплаты'
    },
    {
      id: 3,
      name: 'Оплачен'
    }
  ],

  usersFilters: [],

  filters: {
    agent: null,
    broker: null,
    client: null,

    nameSignPhone: null,
    status: 0,
    dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1),
    dateTo: new Date(
      new Date().getFullYear(),
      new Date().getMonth(),
      new Date(new Date().getFullYear(), new Date().getMonth() + 1, 0).getDate()
    )
  }
};

const slice = createSlice({
  name: 'contracts',
  initialState,
  reducers: {
    setLoad(state, action) {
      state.isLoad = action.payload;
    },

    getContractsSuccess(state, action) {
      state.contracts = action.payload;
    },

    deleteContract(state, action) {
      const index = state.contracts.findIndex(
        (contract) => contract.id === action.payload
      );
      state.contracts.splice(index, 1);
    },

    setFilterValue(state, action) {
      const [name, value] = Object.entries(action.payload)[0];
      state.filters[name] = value;
    },

    clearUserFilters(state, action) {
      delete state.usersFilters[action.payload];
    },
    addUserFilter(state, action) {
      const index = state.usersFilters.findIndex(
        (userFilter) => userFilter.roleId === action.payload.roleId
      );
      if (index === -1) {
        state.usersFilters.push(action.payload);
        return;
      }
      state.usersFilters[index] = action.payload;
    },
    clearUserFilterBelowRoleId(state, action) {
      const index = state.usersFilters.findIndex(
        (userFilter) => userFilter.roleId === action.payload
      );
      if (index !== -1) {
        state.usersFilters.splice(index + 1, state.usersFilters.length);
        state.usersFilters[index].selectedValue = null;
      }
    },
    setUserFilterSelectedValue(state, action) {
      const index = state.usersFilters.findIndex(
        (userFilter) => userFilter.roleId === action.payload.roleId
      );
      if (index !== -1) {
        state.usersFilters[index].selectedValue = action.payload.value;
      }
    }
  }
});

// Reducer
export default slice.reducer;

// export const { setContractId } = slice.actions;

// ----------------------------------------------------------------------

// Получить список заключенных контрактов
// TODO: Возможно стоит удалить этот метод и связанные с ним поля в редакс
export function getContracts({ dateFrom, dateTo, clientId, nameSignPhone }) {
  return async (dispatch) => {
    dispatch(slice.actions.setLoad(true));
    try {
      const { contracts = [] } =
        (await getContractsReq({
          dateFrom,
          dateTo,
          clientId,
          nameSignPhone
        })) || {};

      console.log(contracts);
      dispatch(slice.actions.getContractsSuccess(contracts));
    } catch (exc) {
      console.log(exc);
    } finally {
      dispatch(slice.actions.setLoad(false));
    }
  };
}

export function deleteContract(contractId) {
  return async (dispatch) => {
    const response = await deleteEosagoContract(contractId);
    if (response) {
      dispatch(slice.actions.deleteContract(contractId));
    }
  };
}

// Фильтры

export function addUsersFilter({ id, roleId }) {
  return async (dispatch) => {
    const { children = [] } = (await getUserChildren(id)) || {};
    dispatch(
      slice.actions.addUserFilter({
        id,
        roleId,
        children
      })
    );
  };
}
export function setSelectedValue({ roleId, value }) {
  return async (dispatch) => {
    dispatch(slice.actions.setUserFilterSelectedValue({ roleId, value }));
  };
}

export function clearUsersFiltersUnderRole(roleId) {
  return async (dispatch) => {
    dispatch(slice.actions.clearUserFilterBelowRoleId(roleId));
  };
}

export function setAgentFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ agent: value }));
  };
}

export function setBrokerFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ broker: value }));
  };
}

export function setClientFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ client: value }));
  };
}

export function setStatusFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ status: value }));
  };
}

export function setNameSignPhoneFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ nameSignPhone: value }));
  };
}

export function setDateFromFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ dateFrom: value }));
  };
}

export function setDateToFilter(value) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ dateTo: value }));
  };
}

export function setDateFromToFilter({ dateFrom, dateTo }) {
  return async (dispatch) => {
    dispatch(slice.actions.setFilterValue({ dateFrom }));
    dispatch(slice.actions.setFilterValue({ dateTo }));
  };
}

export function clearFilters() {
  return (dispatch) => {
    dispatch(
      slice.actions.setFilterValue({
        dateFrom: new Date(new Date().getFullYear(), new Date().getMonth(), 1)
      })
    );
    dispatch(
      slice.actions.setFilterValue({
        dateTo: new Date(
          new Date().getFullYear(),
          new Date().getMonth(),
          new Date(
            new Date().getFullYear(),
            new Date().getMonth() + 1,
            0
          ).getDate()
        )
      })
    );
    dispatch(slice.actions.setFilterValue({ nameSignPhone: null }));
  };
}
