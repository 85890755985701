import axios from 'utils/axios';
import { dateToString } from 'utils/textTransformation';

export const getEosagoGeneral = async (id) => {
  const response = await axios.get(`api/v1/eosago/general/${id}`);
  return response.data;
};

export const storeEosagoGeneral = async ({
  clientId,
  id,
  term,
  target,
  dateStart,
  dateEnd,
  multiDriver,
  prevPolicySerial,
  prevPolicyNumber,
  useTrailer,
  createContract
  // dc,
  // dcDate
}) => {
  let body = {
    clientId,
    term,
    target,
    dateStart:
      typeof dateStart === 'string' ? dateStart : dateToString(dateStart),
    dateEnd: typeof dateEnd === 'string' ? dateEnd : dateToString(dateEnd),
    multiDriver,
    useTrailer,
    ...(createContract && { createContract })
    // dc,
    // dcDate: typeof dcDate === 'string' ? dcDate : dateToString(dcDate)
  };
  if (prevPolicyNumber && prevPolicySerial) {
    body = { ...body, prevPolicyNumber, prevPolicySerial };
  }
  if (!id) {
    const response = await axios.post(`api/v1/eosago/general`, body);
    return response.data;
  }
  const response = await axios.put(`api/v1/eosago/general/${id}`, body);
  return response.data;
};
