import { useField, useFormikContext } from 'formik';
import { forwardRef, useState } from 'react';
// material
import { Switch } from '@material-ui/core';

// ----------------------------------------------------------------------

const FormSwitch = forwardRef(({ ...props }, _) => {
  const { setFieldValue } = useFormikContext();
  const [field] = useField(props);
  const [value, setValue] = useState(field.value);

  const handleChange = (event) => {
    setValue(event.target.checked);
    setFieldValue(field.name, event.target.checked);
  };

  return <Switch checked={value} onChange={handleChange} {...props} />;
});

export default FormSwitch;
