import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Grid, Box } from '@material-ui/core';

// ----------------------------------------------------------------------

ButtonsBlock.propTypes = {
  odd: PropTypes.bool,
  justifyContent: PropTypes.string,
  children: PropTypes.node
};

export default function ButtonsBlock({ odd, justifyContent, children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mb: { xs: 6 },
        backgroundColor: odd ? theme.palette.grey[200] : undefined
      }}
    >
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent={justifyContent ?? 'center'}
      >
        {children}
      </Grid>
    </Box>
  );
}
