// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

// Разделы Личного Кабинета

const ROOTS_PERSONAL = '/personal';
const ROOTS_USER = path(ROOTS_PERSONAL, '/user');
const ROOTS_OSAGO = path(ROOTS_PERSONAL, '/osago');
const ROOTS_CONTACTS = path(ROOTS_PERSONAL, '/contacts');
const ROOTS_USERS = path(ROOTS_PERSONAL, '/users');
const ROOTS_DIAGNOSTIC_CARDS = path(ROOTS_PERSONAL, '/diagnostic-cards');
const ROOTS_DOCUMENTS = path(ROOTS_PERSONAL, '/documents');
const ROOTS_CONTROL = path(ROOTS_PERSONAL, '/control');
const ROOTS_WALLET = path(ROOTS_PERSONAL, '/wallet');

// Авторизация
const ROOTS_AUTH = path(ROOTS_PERSONAL, '/auth');

// ----------------------------------------------------------------------

// Личный Кабинет

export const PATH_PERSONAL = {
  root: ROOTS_PERSONAL,
  osago: ROOTS_OSAGO,
  contacts: ROOTS_CONTACTS,
  profile: ROOTS_USER
};

// Авторизация

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  register: path(ROOTS_AUTH, '/register'),
  resetPassword: ROOTS_AUTH
};

// Разделы

export const PATH_USER = {
  root: ROOTS_USER,
  account: path(ROOTS_USER, '/account')
};

export const PATH_OSAGO = {
  root: ROOTS_OSAGO,
  editPolis: [
    path(ROOTS_OSAGO, '/polis/new_polis'),
    path(ROOTS_OSAGO, '/polis/:polis_id')
  ],
  // newPolis: path(ROOTS_OSAGO, '/:polis_id'),
  prolongation: path(ROOTS_OSAGO, '/prolongation'),
  prolongationJournal: path(ROOTS_OSAGO, '/prolongation-journal'),
  calculator: path(ROOTS_OSAGO, '/calculator'),
  calculations: path(ROOTS_OSAGO, '/calculations')
};

export const PATH_CONTACTS = {
  root: ROOTS_CONTACTS,
  contactDetails: path(ROOTS_CONTACTS, '/:contactId'),
  vehicleDetails: path(ROOTS_CONTACTS, '/:contactId/:contractId')
};

export const PATH_USERS = {
  root: ROOTS_USERS,
  userDetails: path(ROOTS_USERS, '/:userId')
};

export const PATH_DIAGNOSTIC_CARDS = {
  root: ROOTS_DIAGNOSTIC_CARDS
};

export const PATH_DOCUMENTS = {
  root: ROOTS_DOCUMENTS
};

export const PATH_CONTROL = {
  root: ROOTS_CONTROL
};

export const PATH_WALLET = {
  root: ROOTS_WALLET
};
