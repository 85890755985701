import { lazy } from 'react';
// pathes
import { PATH_USERS } from 'routes/paths';

// ----------------------------------------------------------------------

const UsersRoutes = {
  path: PATH_USERS.root,
  // guard: UsersGuard,
  routes: [
    // Contacts routes
    {
      exact: true,
      path: PATH_USERS.root,
      component: lazy(() => import('views/users_management/UsersPage'))
    },
    {
      exact: true,
      path: PATH_USERS.userDetails,
      component: lazy(() => import('views/users_management/EditUserPage'))
    }
  ]
};

export default UsersRoutes;
