import { useDispatch, useSelector } from 'react-redux';
// redux
import {
  login,
  register,
  logout,
  sendVerifyCode,
  updateProfile,
  checkPassword,
  testChangeEntity
} from 'redux/slices/authJwt';

// ----------------------------------------------------------------------

export default function useAuth() {
  const dispatch = useDispatch();
  const { user, isLoading, isAuthenticated } = useSelector(
    (state) => state.authJwt
  );

  return {
    method: 'jwt',
    user,
    isLoading,
    isAuthenticated,

    login: ({ email, password }) =>
      dispatch(
        login({
          email,
          password
        })
      ),

    register: ({
      email,
      phone,
      firstName,
      middleName,
      lastName,
      company,
      address,
      password
    }) =>
      dispatch(
        register({
          email,
          firstName,
          phone,
          middleName,
          lastName,
          company,
          address,
          password
        })
      ),

    logout: () => dispatch(logout()),

    sendVerifyCode: ({ phone }) => dispatch(sendVerifyCode({ phone })),

    updateProfileGeneral: ({
      firstName,
      middleName,
      lastName,
      birthdate,
      address,
      company,
      phone,
      email
    }) =>
      dispatch(
        updateProfile({
          userId: user.id,
          firstName,
          middleName,
          lastName,
          birthdate,
          address,
          company,
          phone,
          email
        })
      ),
    updateProfileRequisits: ({
      bankName,
      cardNumber,
      entityName,
      inn,
      kpp,
      ogrn,
      entityAddress,
      address,
      settlementAccount,
      bik,
      correspondentAccount
    }) =>
      dispatch(
        updateProfile({
          userId: user.id,
          bankName,
          cardNumber,
          entityName,
          inn,
          kpp,
          ogrn,
          entityAddress,
          address,
          settlementAccount,
          bik,
          correspondentAccount
        })
      ),

    checkPassword: ({ password }) => dispatch(checkPassword({ password })),

    changePassword: ({ password }) =>
      dispatch(updateProfile({ userId: user.id, password })),

    testChangeEntity: ({ entity }) => dispatch(testChangeEntity(entity))
  };
}
