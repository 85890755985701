import axios from 'utils/axios';
import { dateToString } from 'utils/textTransformation';

export const storePerson = async ({
  clientId,
  id,
  type,
  firstName,
  middleName,
  lastName,
  birthdate,
  genderId,
  dadata,
  passportSerial,
  passportNumber,
  passportDate,
  passportPoint,
  passportForeign,
  insurerIsOwner,
  createContract
}) => {
  const body = {
    clientId,
    type,
    firstName,
    middleName,
    lastName,
    genderId,
    birthdate:
      typeof birthdate === 'string' ? birthdate : dateToString(birthdate),
    dadata,
    passportSerial,
    passportNumber,
    passportDate:
      typeof passportDate === 'string'
        ? passportDate
        : dateToString(passportDate),
    passportPoint,
    passportForeign,
    insurerIsOwner,
    ...(createContract && { createContract })
  };
  if (!id) {
    const response = await axios.post(`api/v1/persons`, body);
    return response.data;
  }
  const response = await axios.put(`api/v1/persons/${id}`, body);
  return response.data;
};

export const getPerson = async (id) => {
  const response = await axios.get(`api/v1/persons/${id}`);
  return response.data;
};
