import { createSlice } from '@reduxjs/toolkit';
import axios from 'axios';
// utils
// import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  passportOffices: [],
  registrationAddresses: []
};

const slice = createSlice({
  name: 'dadata',
  initialState,
  reducers: {
    // Кем выдан пасспорт
    getPassportOfficesSuccess(state, action) {
      state.passportOffices = action.payload.results;
    },

    getRegistrationAddressSucces(state, action) {
      state.registrationAddresses = action.payload.results;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

export function getPassportOffices({ query }) {
  return async (dispatch) => {
    const response = await axios.post(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/fms_unit`,
      { query },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token 54e3a55d089eaece9500c4c9865948f71d311702'
        }
      }
    );
    const { suggestions } = response.data;

    dispatch(slice.actions.getPassportOfficesSuccess({ results: suggestions }));
  };
}

export function getRegistrationAddresses({ query }) {
  return async (dispatch) => {
    const response = await axios.post(
      `https://suggestions.dadata.ru/suggestions/api/4_1/rs/suggest/address`,
      { query },
      {
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          Authorization: 'Token 54e3a55d089eaece9500c4c9865948f71d311702'
        }
      }
    );
    const { suggestions } = response.data;

    dispatch(
      slice.actions.getRegistrationAddressSucces({ results: suggestions })
    );
  };
}
