import PropTypes from 'prop-types';
import { pdf } from '@react-pdf/renderer';
import { useState, useEffect } from 'react';
import { useSnackbar } from 'notistack';
import { useDispatch, useSelector } from 'react-redux';
// icons
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
import axios from 'utils/axios';
import carFill from '@iconify/icons-eva/car-fill';
import historyFill from '@iconify/icons-eva/calendar-fill';
import roundAccountBox from '@iconify/icons-ic/twotone-account-box';
import accountBox from '@iconify/icons-ic/outline-account-box';
import driversFill from '@iconify/icons-eva/people-fill';
// material
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { useTheme } from '@material-ui/core/styles';
import {
  useMediaQuery,
  Grid,
  Divider,
  Button,
  Collapse,
  Typography,
  Box,
  Switch
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import { MIconButton } from 'components/@material-extend';
// redux
import {
  clearContractFull,
  newEmptyDriver,
  setInsurerIsOwner
} from 'redux/slices/osago/edit_contract';
//
import CalculatePolisDialog from './new-polis/CalculatePolisDialog';
import {
  DriversBlock,
  GeneralInformation,
  InsurerBlock,
  NewPolisPDF,
  OwnerBlock,
  VehicleBlock
} from './new-polis';

// ----------------------------------------------------------------------

const download = (path, filename) => {
  // Create a new link
  const anchor = document.createElement('a');
  anchor.href = path;
  anchor.download = filename;

  // Append to the DOM
  document.body.appendChild(anchor);

  // Trigger `click` event
  anchor.click();

  // Remove element from DOM
  document.body.removeChild(anchor);
};

PolisBlock.propTypes = {
  title: PropTypes.string,
  body: PropTypes.func,
  prefix: PropTypes.func,
  suffix: PropTypes.func,
  defaultShow: PropTypes.bool
};

function PolisBlock({ title, body, prefix, suffix, defaultShow = true }) {
  const theme = useTheme();
  const [isShow, setShow] = useState(defaultShow);
  return (
    <>
      <Grid
        container
        item
        justifyItems="center"
        spacing={1}
        alignItems="center"
        alignContent="center"
      >
        <Grid item /* xs={0.5} */>{prefix}</Grid>
        <Grid item xs /* xs={10.8} */ container alignContainer="center">
          <Typography variant="h6">{title}</Typography>
        </Grid>
        <Grid item>{suffix}</Grid>

        <Grid container item xs /* ={0.7} */ justifyContent="flex-end">
          <Box border={2} borderRadius={40}>
            <MIconButton
              size="medium"
              onClick={() => {
                setShow(!isShow);
              }}
            >
              {isShow ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
            </MIconButton>
          </Box>
        </Grid>
        <Grid item xs={12}>
          <Divider
            sx={{
              bgcolor: theme.palette.primary.light,
              height: 3,
              alignSelf: 'center',
              alignItems: 'center',
              alignContent: 'center',
              top: 0
            }}
          />
        </Grid>
        <Grid item xs={12} />
        <Grid item xs={12}>
          <Collapse in={isShow}>{body}</Collapse>
        </Grid>
      </Grid>
    </>
  );
}

export default function NewPolisForm() {
  const theme = useTheme();
  const [openCalcDialog, setOpenCalcDialog] = useState(false);
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const dispatch = useDispatch();
  const {
    drivers,
    client,
    contract,
    insurerIsOwner,
    vehicle,
    insurer,
    owner,
    general
  } = useSelector((state) => state.editContract);
  const { targets } = useSelector((state) => state.lists);

  useEffect(() => {
    if (drivers.length === 0) {
      dispatch(newEmptyDriver());
    }
  }, [drivers, dispatch]);

  const showError = (message) => {
    closeSnackbar();
    enqueueSnackbar(message, {
      variant: 'error',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };

  const handleCalcButton = async () => {
    if (!contract) {
      showError('Заполните данные');
      return;
    }

    if (!contract.id || !contract.clientId) {
      showError('Заполните данные');
      return;
    }
    if (!contract.eosagoId) {
      showError('Заполните общую информацию');
      return;
    }
    if (!contract.vehicleId) {
      showError('Заполните транспорт');
      return;
    }
    if (!contract.insurerId) {
      showError('Заполните страхователя');
      return;
    }
    if (!contract.driversId || contract.driversId === '[]') {
      showError('Заполните водителей');
      return;
    }
    if (!contract.ownerId && !insurerIsOwner) {
      showError('Заполните собственника');
      return;
    }

    try {
      console.log('CALC CONTRACT');
      axios.get(`api/v1/eosago/contract/calc/${contract.id}`);
      setOpenCalcDialog(true);
    } catch (exc) {
      console.log(exc);
      showError('Ошибка расчета');
    }
  };

  const handleGeneratePDF = async () => {
    if (!contract) {
      showError('Заполните данные');
      return;
    }
    if (!contract.id || !contract.clientId) {
      showError('Заполните данные');
      return;
    }
    if (!contract.eosagoId) {
      showError('Заполните общую информацию');
      return;
    }
    if (!contract.vehicleId) {
      showError('Заполните транспорт');
      return;
    }
    if (!contract.insurerId) {
      showError('Заполните страхователя');
      return;
    }
    if (!contract.driversId || contract.driversId === '[]') {
      showError('Заполните водителей');
      return;
    }
    if (!contract.ownerId && !insurerIsOwner) {
      showError('Заполните собственника');
      return;
    }

    try {
      const doc = (
        <NewPolisPDF
          client={client}
          drivers={drivers}
          general={general}
          insurer={insurer}
          insurerIsOwner={insurerIsOwner}
          owner={owner}
          targets={targets}
          vehicle={vehicle}
        />
      );
      const asPdf = pdf(doc);
      const blob = await asPdf.toBlob();
      const url = URL.createObjectURL(blob);
      download(url, `${client.lastName}.pdf`);
    } catch (exc) {
      console.log(exc);
      showError('Ошибка расчета');
    }
  };

  const handleChangeInsurerIsOwner = (_, checked) => {
    dispatch(setInsurerIsOwner(checked));
  };

  return (
    <>
      <CalculatePolisDialog
        open={openCalcDialog}
        closeAction={() => {
          setOpenCalcDialog(false);
        }}
      />

      <Grid container flexDirection="column" spacing={5} sx={{ mt: 1 }}>
        <PolisBlock
          prefix={<Icon icon={historyFill} width={20} height={20} />}
          title="Общая информация"
          body={<GeneralInformation />}
        />
        {client ? (
          <>
            <PolisBlock
              prefix={<Icon icon={carFill} width={20} height={20} />}
              title="Данные о ТС"
              body={<VehicleBlock />}
            />
            <PolisBlock
              prefix={<Icon icon={roundAccountBox} width={20} height={20} />}
              title="Страхователь"
              body={<InsurerBlock />}
            />
            <PolisBlock
              prefix={<Icon icon={accountBox} width={20} height={20} />}
              title="Собственнник"
              suffix={
                <Grid
                  container
                  alignItems="center"
                  border={0}
                  sx={{ mr: isDesktop ? 12 : 0 }}
                >
                  <Grid item>Является страхователем</Grid>
                  <Grid item>
                    <Switch
                      defaultChecked
                      checked={insurerIsOwner}
                      onChange={handleChangeInsurerIsOwner}
                    />
                  </Grid>
                </Grid>
              }
              defaultShow={!insurerIsOwner}
              body={<OwnerBlock />}
            />
            <PolisBlock
              prefix={<Icon icon={driversFill} width={20} height={20} />}
              title="Водители"
              body={<DriversBlock />}
            />
          </>
        ) : undefined}

        <Grid
          container
          item
          justifyItems="center"
          spacing={1}
          justifyContent="center"
          alignItems="center"
          alignContent="center"
        >
          <Grid item xs={isDesktop ? 3 : 6}>
            <Button
              size={isDesktop ? 'large' : 'small'}
              variant="contained"
              fullWidth
              onClick={() => {
                dispatch(clearContractFull());
              }}
            >
              Очистить форму
            </Button>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <LoadingButton
              size={isDesktop ? 'large' : 'small'}
              variant="contained"
              fullWidth
              onClick={handleGeneratePDF}
            >
              Проверка данных
            </LoadingButton>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <LoadingButton
              size={isDesktop ? 'large' : 'small'}
              variant="contained"
              fullWidth
              disabled
              onClick={() => {}}
            >
              Копировать данные
            </LoadingButton>
          </Grid>
          <Grid item xs={isDesktop ? 3 : 6}>
            <LoadingButton
              size={isDesktop ? 'large' : 'small'}
              variant="contained"
              fullWidth
              onClick={handleCalcButton}
            >
              Сохранить и рассчитать
            </LoadingButton>
          </Grid>
        </Grid>
      </Grid>
    </>
  );
}
