import PropTypes from 'prop-types';
// material
import { Grid } from '@material-ui/core';
//
import Block from 'components/personal/mixed/Block';

// ----------------------------------------------------------------------

FormBlock.propTypes = {
  title: PropTypes.node,
  odd: PropTypes.bool,
  justifyContent: PropTypes.string,
  children: PropTypes.node
};

export default function FormBlock({ title, odd, justifyContent, children }) {
  return (
    <Block title={title} odd={odd}>
      <Grid
        container
        spacing={4}
        alignItems="center"
        justifyContent={justifyContent}
      >
        {children}
      </Grid>
    </Block>
  );
}
