import * as Yup from 'yup';

// ----------------------------------------------------------------------

const accountRequisitsSchema = Yup.object().shape({
  bankName: Yup.string(),
  cardNumber: Yup.number('Номер карты состоит из цифр')
});

export default accountRequisitsSchema;
