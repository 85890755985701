import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
//
import { getTargets } from 'redux/slices/osago/osago';

import Block from 'components/personal/mixed/Block';
import CalculationsHeader from 'components/personal/osago/calculations/CalculationsHeader';
import CalculationsTable from 'components/personal/osago/calculations/CalculationsTable';

// ----------------------------------------------------------------------

export default function Calculations() {
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getTargets());
  }, [dispatch]);
  return (
    <>
      <Block>
        <CalculationsHeader />
        <CalculationsTable />
      </Block>
    </>
  );
}
