import * as Yup from 'yup';
import { phoneRegex } from 'utils/regexes';

// ----------------------------------------------------------------------

const registerSchema = Yup.object().shape({
  firstName: Yup.string().required('Имя обязательно'),
  phone: Yup.string()
    .required('Телефон обязателен')
    .matches(phoneRegex, 'Формат номера +7 (999) 999-99-99'),
  email: Yup.string()
    .email('Введите действующий e-mail')
    .required('E-mail обязателен'),
  password: Yup.string().min(6, 'Минимальная длина пароля 6 символов')
});

export default registerSchema;
