import { useFormikContext } from 'formik';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  useMediaQuery,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
  FormLabel,
  RadioGroup,
  Radio
} from '@material-ui/core';
//
import { MSelect as Select } from 'components/@material-extend';
import {
  BlockTitle,
  FormBlock,
  FormDatePicker,
  FormTextField
} from 'components/personal/form-components';

export default function GeneralInformation() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const polisStartDefaultDate = new Date(
    new Date().setDate(new Date().getDate() + 3)
  );

  const { getFieldProps } = useFormikContext();
  return (
    <FormBlock title={<BlockTitle title="Общая информация" />}>
      <Grid item xs={isDesktop ? 3 : 6}>
        <FormControl>
          <FormLabel complete>Тип ТС</FormLabel>
          <RadioGroup row defaultValue="1" {...getFieldProps('vehicleType')}>
            <FormControlLabel value="1" control={<Radio />} label="Легковой" />
            <FormControlLabel
              // Пока учитывается только значение для грузовиков <=16 тонн
              value="5"
              control={<Radio />}
              label="Грузовой"
            />
          </RadioGroup>
        </FormControl>
      </Grid>

      <Grid item xs={isDesktop ? 3 : 6}>
        <FormControl fullWidth variant="outlined">
          <InputLabel complete>Срок страхования</InputLabel>
          <Select
            label="Срок страхования"
            defaultValue={12}
            {...getFieldProps('insuranceTerm')}
          >
            <MenuItem value={3}>3 месяца</MenuItem>
            <MenuItem value={4}>4 месяца</MenuItem>
            <MenuItem value={5}>5 месяцев</MenuItem>
            <MenuItem value={6}>6 месяцев</MenuItem>
            <MenuItem value={7}>7 месяцев</MenuItem>
            <MenuItem value={8}>8 месяцев</MenuItem>
            <MenuItem value={9}>9 месяцев</MenuItem>
            <MenuItem value={12}>12 месяцев</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={isDesktop ? 3 : 6}>
        <FormTextField
          fullWidth
          name="enginePower"
          label="Мощность Л.С."
          placeholder="Введите мощность"
        />
      </Grid>

      <Grid item xs={isDesktop ? 3 : 6}>
        <FormDatePicker
          label="Дата начала действия полиса"
          defaultValue={polisStartDefaultDate}
          name="datePolisStart"
        />
      </Grid>
      <Grid item xs={isDesktop ? 6 : 6}>
        <FormControlLabel
          control={<Switch {...getFieldProps('withTrailer')} />}
          label="Используется с прицепом"
        />
      </Grid>
    </FormBlock>
  );
}
