import PropTypes from 'prop-types';
import { Collapse, Alert, AlertTitle } from '@material-ui/core';

function ErrorAlert({ open, onClose, title, message }) {
  return (
    <Collapse in={open}>
      <Alert severity="error" onClose={onClose}>
        <AlertTitle>{title}</AlertTitle>
        {message}
      </Alert>
    </Collapse>
  );
}

ErrorAlert.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func,
  title: PropTypes.string,
  message: PropTypes.string
};

export default ErrorAlert;
