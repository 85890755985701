import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
// material
import {
  Grid,
  Button,
  Switch,
  MenuItem,
  FormControl,
  FormControlLabel,
  Checkbox,
  FormLabel,
  RadioGroup,
  Radio,
  Card,
  CardContent,
  Collapse,
  Box,
  Typography,
  CircularProgress
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import {
  MSelect as Select,
  MInputLabel as InputLabel
} from 'components/@material-extend';
// utils
import { capitalizeField } from 'utils/textTransformation';
// requests
import { findKBM } from 'requests/drivers-requests';
//
import {
  FormDatePicker,
  FormTextField,
  MiddleNameCheckTextField
} from 'components/personal/form-components';
import { useDispatch, useSelector } from 'react-redux';
import {
  newEmptyDriver,
  removeDriver,
  saveDriver
} from 'redux/slices/osago/edit_contract';
import { driverSchema } from 'form_settings/validations/new_polis_validations';
import useErrorSnackbar from 'hooks/useErrorSnackbar';

// ----------------------------------------------------------------------

Driver.propTypes = {
  driver: PropTypes.object,
  index: PropTypes.number
};

export default function Driver({ driver, index }) {
  const {
    drivers,
    insurerIsOwner,
    insurer,
    owner,
    contract,
    client,
    updateDrivers
  } = useSelector((state) => state.editContract);
  const updateDriver = updateDrivers[index];
  const [kbm, setKbm] = useState();
  const dispatch = useDispatch();
  const showError = useErrorSnackbar();

  const formik = useFormik({
    enableReinitialize: true,
    initialValues: {
      isPrev: false,

      genderId: `${driver.genderId ?? 1}`,
      licenseForeign: `${driver.licenseForeign ?? 0}`,

      firstName: driver.firstName ?? '',
      middleName: driver.middleName ?? '',
      noMiddleName: false,
      lastName: driver.lastName ?? '',
      birthdate: driver.birthdate ?? '',

      licenseSerial: driver.licenseSerial ?? '',
      licenseNumber: driver.licenseNumber ?? '',
      licenseDate: driver.licenseDate ?? '',
      expDate: driver.expDate ?? '',

      prevLicenseSerial: driver.prevLicenseSerial ?? '',
      prevLicenseNumber: driver.prevLicenseNumber ?? '',
      prevLicenseDate: driver.prevLicenseDate ?? '',
      prevLicenseFirstname: driver.prevLicenseFirstname ?? '',
      prevLicenseMiddlename: driver.prevLicenseMiddlename ?? '',
      prevLicenseLastname: driver.prevLicenseLastname ?? ''
    },
    isInitialValid: false,
    validationSchema: driverSchema,

    onSubmit: async (values) => {
      try {
        const driverForm = {
          id: driver.id,
          clientId: client.id,
          genderId: values.genderId,
          lastName: values.lastName,
          firstName: values.firstName,
          middleName: values.middleName,
          birthdate: values.birthdate,
          expDate: values.expDate,
          licenseDate: values.licenseDate,
          licenseSerial: values.licenseSerial,
          licenseNumber: values.licenseNumber,
          licenseForeign: Number(values.licenseForeign),
          ...(values.isPrev && {
            prevLicenseDate: values.prevLicenseDate,
            prevLicenseFirstname: values.prevLicenseFirstname,
            prevLicenseLastname: values.prevLicenseLastname,
            prevLicenseMiddlename: values.prevLicenseMiddlename,
            prevLicenseNumber: values.prevLicenseNumber,
            prevLicenseSerial: values.prevLicenseSerial
          })
        };
        dispatch(
          saveDriver({
            driver: driverForm,
            index,
            driversArray: drivers,
            contractId: contract?.id
          })
        );
      } catch (exc) {
        console.log(exc);
      }
    }
  });

  const handleChangeSelect = (event) => {
    const type = event.target.value;
    const persons = {
      insurer,
      owner,
      driver
    };

    const {
      firstName = '',
      middleName = '',
      noMiddleName = false,
      lastName = '',
      birthdate = '',
      genderId = '1',
      licenseForeign = '0'
    } = persons[type] || {};

    setValues({
      ...values,
      firstName,
      middleName,
      noMiddleName,
      lastName,
      birthdate,
      genderId: `${genderId ?? 1}`,
      licenseForeign: `${licenseForeign ?? 0}`
    });
  };

  const handleAdd = () => {
    dispatch(newEmptyDriver());
  };

  const handleRemove = () => {
    dispatch(removeDriver(index, driver?.id));
  };

  const {
    isValid,
    handleSubmit,
    getFieldProps,
    setValues,
    submitForm,
    values
  } = formik;

  // Поиск КБМ
  useEffect(() => {
    async function searchKbm() {
      const kbmParams = {
        firstName: values.isPreviousLicense
          ? values.previousFirstName
          : values.firstName,
        middleName: values.isPreviousLicense
          ? values.previousMiddleName
          : values.middleName,
        lastName: values.isPreviousLicense
          ? values.previousLastName
          : values.lastName,
        birthdate: values.birthdate,
        licenseSerial: values.isPreviousLicense
          ? values.previousLicenseSerial
          : values.licenseSerial,
        licenseNumber: values.isPreviousLicense
          ? values.previousLicenseNumber
          : values.licenseNumber
      };

      if (Object.values(kbmParams).filter((param) => !param).length > 0) return;
      const result = await findKBM(kbmParams);
      if (!result?.detail) return;
      setKbm(result?.detail[0]);
    }

    searchKbm();
  }, [
    values.isPreviousLicense,
    values.firstName,
    values.middleName,
    values.lastName,
    values.birthdate,
    values.licenseSerial,
    values.licenseNumber,
    values.previousFirstName,
    values.previousMiddleName,
    values.previousLastName,
    values.previousLicenseSerial,
    values.previousLicenseNumber
  ]);

  useEffect(() => {
    // Оповещение о пропущенных предыдущих блоках
    if (
      contract &&
      (!contract.vehicleId ||
        !contract.insurerId ||
        (!insurerIsOwner && !contract.ownerId))
    ) {
      [
        ...(!contract.vehicleId ? ['транспорт'] : []),
        ...(!contract.insurerId ? ['страхователя'] : []),
        ...(!insurerIsOwner && !contract.ownerId ? ['собственника'] : [])
      ].join(', ');
      showError(
        `Заполните ${[
          ...(!contract.vehicleId ? ['транспорт'] : []),
          ...(!contract.insurerId ? ['страхователя'] : []),
          ...(!insurerIsOwner && !contract.ownerId ? ['собственника'] : [])
        ].join(', ')}`
      );
    }

    if (isValid) {
      submitForm();
    }
  }, [values, isValid, submitForm]);

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!updateDriver) return;
    async function fetchData() {
      setLoading(true);
      setValues(updateDriver);
      setLoading(false);
    }
    fetchData();
  }, [updateDriver, setValues, setLoading]);

  if (isLoading)
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  return (
    <Grid item xs={12}>
      <FormikProvider value={formik}>
        <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
          <Grid container spacing={3}>
            <Grid item xs={12} md={4}>
              <Card>
                <CardContent>
                  <Grid container spacing={2}>
                    <Grid item sm={12} xs={12} container>
                      <FormControl fullWidth variant="outlined">
                        <InputLabel complete>{`Водитель ${
                          index + 1
                        }`}</InputLabel>
                        <Select
                          label="Водитель"
                          defaultValue="driver"
                          onChange={handleChangeSelect}
                        >
                          <MenuItem value="driver">Иное лицо</MenuItem>
                          <MenuItem value="insurer">Страхователь</MenuItem>
                          {!insurerIsOwner ? (
                            <MenuItem value="owner">Собственник</MenuItem>
                          ) : undefined}
                        </Select>
                      </FormControl>
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item sm={12} xs={12}>
                      <FormControl>
                        <FormLabel complete>Пол</FormLabel>
                        <RadioGroup
                          row
                          defaultValue="1"
                          {...getFieldProps(`genderId`)}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="Мужской"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Женский"
                          />
                        </RadioGroup>
                      </FormControl>
                    </Grid>
                    <Grid item sm={12} xs={12}>
                      <FormControlLabel
                        control={
                          <Checkbox
                            color="primary"
                            {...getFieldProps('licenseForeign')}
                          />
                        }
                        label="Иностранное удостоверение"
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item sm={12} xs={12}>
                      <FormControlLabel
                        control={<Switch {...getFieldProps(`isPrev`)} />}
                        label="Указать предыдущее В/У"
                      />
                    </Grid>
                    <Grid item xs={12} />
                    <Grid item xs={12}>
                      {index !== 0 ? (
                        <Button
                          startIcon={<DeleteIcon />}
                          onClick={handleRemove}
                        >
                          Удалить водителя
                        </Button>
                      ) : undefined}
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
            </Grid>

            <Grid item xs={12} md={8}>
              <Card>
                <CardContent>
                  <Grid container spacing={5}>
                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        required
                        name="lastName"
                        placeholder="Введите фамилию"
                        fullWidth
                        label="Фамилия"
                        onChange={capitalizeField}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormTextField
                        required
                        name="firstName"
                        placeholder="Введите фамилию"
                        fullWidth
                        label="Имя"
                        onChange={capitalizeField}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <MiddleNameCheckTextField
                        required
                        inputName="middleName"
                        checkName="noMiddleName"
                        fullWidth
                        label="Отчество"
                        onInputChange={capitalizeField}
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormDatePicker
                        required
                        fullWidth
                        name="birthdate"
                        label="Дата рождения"
                      />
                    </Grid>

                    <Grid item xs={12} />
                    <Grid item xs={12} />

                    <Grid item xs={6} sm={3}>
                      <FormTextField
                        required
                        name="licenseSerial"
                        fullWidth
                        label="Серия документа"
                        placeholder="Введите серию"
                      />
                    </Grid>
                    <Grid item xs={6} sm={3}>
                      <FormTextField
                        required
                        name="licenseNumber"
                        fullWidth
                        label="Номер документа"
                        placeholder="Введите номер"
                      />
                    </Grid>

                    <Grid item xs={12} sm={6}>
                      <FormDatePicker
                        required
                        name="expDate"
                        fullWidth
                        label="Дата начала стажа"
                      />
                    </Grid>
                    <Grid item container xs={12} sm={6} alignContent="flex-end">
                      <Typography variant="h6">
                        КМБ = {kbm?.factor ?? ''}
                      </Typography>
                    </Grid>
                    <Grid item xs={12} sm={6}>
                      <FormDatePicker
                        required
                        name="licenseDate"
                        fullWidth
                        label="Дата выдачи документа"
                      />
                    </Grid>

                    <Grid item xs={12}>
                      <Collapse in={values.isPrev}>
                        <Grid item container spacing={4}>
                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name="prevLicenseLastname"
                              fullWidth
                              label="Фамилия в предыдущем В/У"
                              placeholder="Введите фамилию"
                              onChange={capitalizeField}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name="prevLicenseFirstname"
                              fullWidth
                              label="Имя в предыдущем В/У"
                              placeholder="Введите имя"
                              onChange={capitalizeField}
                            />
                          </Grid>

                          <Grid item xs={12} sm={6}>
                            <FormTextField
                              name="prevLicenseMiddlename"
                              fullWidth
                              label="Отчество  в предыдущем В/У"
                              placeholder="Введите отчество"
                              onChange={capitalizeField}
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <FormTextField
                              name="prevLicenseSerial"
                              fullWidth
                              label="Пред. Серия документа"
                              placeholder="Введите серию"
                            />
                          </Grid>
                          <Grid item xs={6} sm={3}>
                            <FormTextField
                              fullWidth
                              name="prevLicenseNumber"
                              label="Пред. Номер документа"
                              placeholder="Введите номер"
                            />
                          </Grid>
                        </Grid>
                      </Collapse>
                    </Grid>
                  </Grid>

                  <Box
                    sx={{ mt: 3, display: 'flex', justifyContent: 'flex-end' }}
                  >
                    <Grid container spacing={1} justifyContent="flex-end">
                      <Grid item>
                        {index + 1 === drivers.length && index !== 4 && (
                          <Button
                            fullWidth
                            variant="contained"
                            startIcon={<AddIcon />}
                            onClick={handleAdd}
                          >
                            Добавить водителя
                          </Button>
                        )}
                      </Grid>
                    </Grid>
                  </Box>
                </CardContent>
              </Card>
            </Grid>
          </Grid>
        </Form>
      </FormikProvider>
    </Grid>
  );
}
