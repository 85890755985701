import { useState } from 'react';
import { useSelector } from 'react-redux';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
// material
import { Grid, Collapse, Button } from '@material-ui/core';
import Driver from './Driver';

// ----------------------------------------------------------------------

export default function DriversBlock() {
  const [isAll, setAll] = useState(false);
  const { drivers } = useSelector((state) => state.editContract);

  return (
    <Grid container spacing={5}>
      {drivers.length > 0 ? (
        <>
          <Grid item xs={12}>
            <Driver driver={drivers[0]} id={drivers[0].id} index={0} />
          </Grid>
          {drivers.length > 1 ? (
            <>
              <Grid item xs={12}>
                <Button
                  fullWidth
                  variant="contained"
                  startIcon={
                    isAll ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />
                  }
                  onClick={() => {
                    setAll(!isAll);
                  }}
                >{`${isAll ? 'Скрыть' : 'Показать'} других водителей (${
                  drivers.slice(1).length
                })`}</Button>
              </Grid>
              {drivers.slice(1).map((item, index) => (
                <Grid key={item.id} item xs={12}>
                  <Collapse in={isAll}>
                    <Driver driver={item} id={item.id} index={index + 1} />
                  </Collapse>
                </Grid>
              ))}
            </>
          ) : undefined}
        </>
      ) : undefined}
    </Grid>
  );
}
