import axios from 'axios';

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  // baseURL: 'https://test1.zk-digital.site/',
  // baseURL: 'http://127.0.0.1:8000/',
  baseURL: 'https://back.strahovkaorg.ru/',
  // baseURL: 'https://ts1.strahovkaorg.ru',
  // timeout: 10000,
  headers: {
    Accept: 'application/json'
  }
});

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
    axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axiosInstance.defaults.headers.common.Authorization;
  }
};

axiosInstance.interceptors.request.use(
  async (config) => {
    const token = localStorage.getItem('accessToken');
    config.headers.Authorization = `Bearer ${token}`;
    return config;
  },
  (error) => {
    Promise.reject(error);
  }
);

async function refreshTokens() {
  const oldRefreshToken = localStorage.getItem('refreshToken');
  if (!oldRefreshToken) return {};
  const tempAxiosInstance = axios.create({
    baseURL: axiosInstance.defaults.baseURL
  });

  const refreshResponse = await tempAxiosInstance.put('/api/v1/auth/refresh', {
    refreshToken: oldRefreshToken
  });

  const { accessToken, refreshToken } = refreshResponse.data;

  return { accessToken, refreshToken };
}

axiosInstance.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (
      error?.response?.status === 403 ||
      (error?.response?.status === 401 && !originalRequest._retry)
    ) {
      originalRequest._retry = true;
      try {
        const { accessToken, refreshToken } = (await refreshTokens()) || {};
        if (accessToken && refreshToken) {
          setSession(accessToken, refreshToken);
          axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          axiosInstance.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
          return axiosInstance(originalRequest);
        }
        return Promise.reject(error);
      } catch (e) {
        return Promise.reject(error);
      }
    }
    return Promise.reject(
      (error.response && error.response.data) || 'Something went wrong'
    );
  }
);

export default axiosInstance;
