import PropTypes from 'prop-types';
// material
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
//
import {
  BlockTitle,
  FormBlock,
  FormTextField
} from 'components/personal/form-components';

// ----------------------------------------------------------------------

DriversBlock.propTypes = {
  fieldType: PropTypes.string.isRequired,
  odd: PropTypes.bool
};

export default function DriversBlock({ fieldType, odd }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  // const { getFieldProps } = useFormikContext();
  return (
    <FormBlock odd={odd} title={<BlockTitle title="Клиент" />}>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name={`${fieldType}LastName`}
          fullWidth
          label="Фамилия"
          placeholder="Введите фамилию"
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name={`${fieldType}FirstName`}
          fullWidth
          label="Имя"
          placeholder="Введите имя"
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name={`${fieldType}MiddleName`}
          fullWidth
          label="Отчество"
          placeholder="Введите отчество"
        />
      </Grid>
    </FormBlock>
  );
}
