import { capitalize } from 'lodash';

// ----------------------------------------------------------------------

const PRIMARY_NAME = [
  ...['A', 'N', 'H', 'L', 'Q', '9', '8'],
  ...['А', 'Н', 'Х', 'Л', 'К']
];
const INFO_NAME = [
  ...['F', 'G', 'T', 'I', 'J', '1', '2', '3'],
  ...['Ф', 'Г', 'Т', 'И', 'Ж']
];
const SUCCESS_NAME = [
  ...['K', 'D', 'Y', 'B', 'O', '4', '5'],
  ...['Д', 'Б', 'О']
];
const WARNING_NAME = [
  ...['P', 'E', 'R', 'S', 'C', 'U', '6', '7'],
  ...['П', 'Е', 'Р', 'С', 'У']
];
const ERROR_NAME = [...['V', 'W', 'X', 'M', 'Z'], ...['В', 'М', 'З']];

function getFirstCharacter(name) {
  return capitalize(name && name.charAt(0));
}

function getAvatarColor(name) {
  if (PRIMARY_NAME.includes(getFirstCharacter(name))) return 'primary';
  if (INFO_NAME.includes(getFirstCharacter(name))) return 'info';
  if (SUCCESS_NAME.includes(getFirstCharacter(name))) return 'success';
  if (WARNING_NAME.includes(getFirstCharacter(name))) return 'warning';
  if (ERROR_NAME.includes(getFirstCharacter(name))) return 'warning';
  return 'default';
}

export default function createAvatar(name) {
  return {
    name: getFirstCharacter(name),
    color: getAvatarColor(name)
  };
}
