import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material
import { Box, Select } from '@material-ui/core';

// ----------------------------------------------------------------------

const MSelect = forwardRef((props, ref) => (
  <Box
    boxShadow={2}
    borderRadius={1}
    sx={{ width: '100%', backgroundColor: 'white' }}
  >
    <Select fullWidth ref={ref} {...props}>
      {props.children}
    </Select>
  </Box>
));

MSelect.propTypes = {
  children: PropTypes.node
};

export default MSelect;
