const prolongationInitial = {
  clientSelect: {
    title: 'Создать нового',
    value: '-1'
  },
  clientPhone: '',
  clientEmail: '',
  clientFirstName: '',
  clientMiddleName: '',
  clientLastName: '',
  prevPolisSerial: null,
  prevPolisNumber: '',
  ownerBirthdate: ''
};

export default prolongationInitial;
