import PropTypes from 'prop-types';
import { useHistory } from 'react-router-dom';
import { useState } from 'react';
import { useDispatch } from 'react-redux';
// material
import { Menu, MenuItem } from '@material-ui/core';
//
import { deleteContract } from 'redux/slices/osago/contracts';
import { clearContract, editContract } from 'redux/slices/osago/edit_contract';
import { PATH_OSAGO } from 'routes/paths';
// dialogs
import LoadDialog from 'components/LoadDialog';
import ConfirmDialog from 'components/ConfirmDialog';
import ContractEmailDialog from './ContractEmailDialog';

// ----------------------------------------------------------------------

/**
 * status:
 *  1 - Черновик
 *  2 - Ожидание оплаты
 *  3 - Оплачен
 */

DraftItems.propTypes = {
  setAnchorEl: PropTypes.func,
  contractId: PropTypes.number,
  contract: PropTypes.object
};

function DraftItems({ setAnchorEl, contractId, contract }) {
  const dispatch = useDispatch();
  const [isLoading, setLoading] = useState(false);
  const [openConfirm, setOpenConfirm] = useState(false);
  const [loadMessage, setLoadMessage] = useState('');
  const [loadErrors, setLoadErrors] = useState([]);
  const history = useHistory();

  //
  const handleEdit = async () => {
    try {
      setLoadMessage('Загрузка полиса');
      setLoading(true);
      dispatch(clearContract());
      await dispatch(editContract(contract));
      setLoading(false);
      history.push(`${PATH_OSAGO.root}/polis/${contractId}`);
    } catch (exc) {
      console.log(exc);
      const errors = [
        ...Object.values(exc.errors ?? {}).flat(),
        exc.message,
        exc.error
      ];
      console.log(errors);
      setLoadErrors(errors.length !== 0 ? errors : ['Ошибка загрузки']);
    }
  };

  const handleDelete = async () => {
    setOpenConfirm(true);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const confirmDelete = async () => {
    await dispatch(deleteContract(contractId));
    setOpenConfirm(false);
  };

  return (
    <>
      <LoadDialog open={isLoading} message={loadMessage} errors={loadErrors} />
      <ConfirmDialog
        open={openConfirm}
        onClose={() => {
          setOpenConfirm(false);
        }}
        refuseAction={() => {
          setOpenConfirm(false);
        }}
        confirmAction={confirmDelete}
        message="Вы действительно хотите удалить полис?"
      />
      <MenuItem onClick={handleEdit} onClose={handleClose}>
        Редактировать
      </MenuItem>
      <MenuItem onClick={handleDelete} onClose={handleClose}>
        Удалить
      </MenuItem>
    </>
  );
}

WaitItems.propTypes = {
  setAnchorEl: PropTypes.func
};

function WaitItems({ setAnchorEl }) {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const handleContractEmail = () => {
    setOpenEmailDialog(true);
  };
  const handleProceedToCheckout = () => {};

  const handleCheckPayment = () => {};

  const handlePlaceInDraft = () => {};

  return (
    <>
      <ContractEmailDialog
        open={openEmailDialog}
        onClose={() => {
          setOpenEmailDialog(false);
        }}
      />
      <MenuItem onClick={handleContractEmail} onClose={handleClose}>
        E-mail для полиса
      </MenuItem>
      <MenuItem onClick={handleProceedToCheckout} onClose={handleClose}>
        Перейти к оплате
      </MenuItem>
      <MenuItem onClick={handleCheckPayment} onClose={handleClose}>
        Проверить оплату
      </MenuItem>
      <MenuItem onClick={handlePlaceInDraft} onClose={handleClose}>
        Поместить в черновик
      </MenuItem>
    </>
  );
}

PaidItems.propTypes = {
  setAnchorEl: PropTypes.func
};
function PaidItems({ setAnchorEl }) {
  const handleClose = () => {
    setAnchorEl(null);
  };

  const [openEmailDialog, setOpenEmailDialog] = useState(false);

  const handleContractEmail = () => {
    setOpenEmailDialog(true);
  };
  const handleProlongation = () => {};

  const handleFiles = () => {};

  const handleSendToEmail = () => {};
  return (
    <>
      <ContractEmailDialog
        open={openEmailDialog}
        onClose={() => {
          setOpenEmailDialog(false);
        }}
      />
      <MenuItem onClick={handleContractEmail} onClose={handleClose}>
        E-mail для полиса
      </MenuItem>
      <MenuItem onClick={handleProlongation} onClose={handleClose}>
        Пролонгация
      </MenuItem>
      <MenuItem onClick={handleFiles} onClose={handleClose}>
        Файлы полиса
      </MenuItem>
      <MenuItem onClick={handleSendToEmail} onClose={handleClose}>
        Отправить на email
      </MenuItem>
    </>
  );
}

ActionMenu.propTypes = {
  setAnchorEl: PropTypes.func,
  contractId: PropTypes.number,
  contract: PropTypes.object,
  status: PropTypes.object,
  anchorEl: PropTypes.object
};
export default function ActionMenu({
  contractId,
  contract,
  status,
  anchorEl,
  setAnchorEl
}) {
  const handleClose = () => {
    setAnchorEl(null);
  };
  const actions = {
    1: (
      <DraftItems
        setAnchorEl={setAnchorEl}
        contractId={contractId}
        contract={contract}
      />
    ),
    2: <WaitItems setAnchorEl={setAnchorEl} />,
    3: <PaidItems setAnchorEl={setAnchorEl} />
  };
  return (
    <Menu
      id="long-menu"
      anchorEl={anchorEl}
      keepMounted
      open={Boolean(anchorEl)}
      onClose={handleClose}
    >
      {actions[status]}
    </Menu>
  );
}
