import PropTypes from 'prop-types';
// material
import { FormLabel } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) =>
  createStyles({
    complete: {
      color: theme.palette.text.primary
    },
    notComplete: {
      color: theme.palette.text.disabled
    }
  })
);
// ----------------------------------------------------------------------

const MFormLabel = (props) => {
  const classes = useStyles();

  return (
    <FormLabel
      classes={{
        root: props.complete === '' ? classes.notComplete : classes.complete
      }}
      {...props}
    >
      {props.children}
    </FormLabel>
  );
};

MFormLabel.propTypes = {
  children: PropTypes.node,
  complete: PropTypes.bool
};

export default MFormLabel;
