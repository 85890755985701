import PropTypes from 'prop-types';
import React from 'react';
import {
  Page,
  Text,
  View,
  Document,
  StyleSheet,
  Font
} from '@react-pdf/renderer';
import { dateToString } from 'utils/textTransformation';

Font.register({
  family: 'Roboto',
  fonts: [
    { src: '/fonts/Roboto-Regular.ttf' },
    { src: '/fonts/Roboto-Bold.ttf' }
  ]
});

const underlineBorder = {
  borderBottomWidth: 1,
  borderStyle: 'solid',
  borderColor: '#6A6B6B'
};

const styles = StyleSheet.create({
  col4: { width: '25%' },
  col8: { width: '75%' },
  col6: { width: '50%' },
  mb8: { marginBottom: 8 },
  mb40: { marginBottom: 40 },
  overline: {
    fontSize: 8,
    marginBottom: 8,
    fontWeight: 700,
    letterSpacing: 1.2,
    textTransform: 'uppercase'
  },
  h3: { fontSize: 16, fontWeight: 700 },
  title: {
    fontSize: 13,
    fontWeight: 700,
    textAlign: 'center',
    textTransform: 'uppercase'
  },
  h4: { fontSize: 13, fontWeight: 700 },
  upperText: {
    textTransform: 'uppercase'
  },
  lowerText: { textTransform: 'lowercase' },
  capitalizeText: { textTransform: 'capitalize' },
  body1: { fontSize: 9 },
  subtitle2: { fontSize: 9, fontWeight: 700 },
  alignRight: { textAlign: 'right' },
  page: {
    padding: '40px 24px 0 24px',
    fontSize: 9,
    lineHeight: 1.6,
    fontFamily: 'Roboto',
    backgroundColor: '#fff',
    textTransform: 'capitalize'
  },
  footer: {
    left: 0,
    right: 0,
    bottom: 0,
    padding: 24,
    margin: 'auto',
    borderTopWidth: 1,
    borderStyle: 'solid',
    position: 'absolute',
    borderColor: '#DFE3E8'
  },
  gridContainer: { flexDirection: 'row', justifyContent: 'space-between' },
  table: { display: 'flex', width: 'auto', marginBottom: '5%' },
  tableHeader: {},
  tableBody: {},
  tableRow: {
    padding: '8px 0',
    flexDirection: 'row'
  },
  noBorder: { paddingTop: 8, paddingBottom: 0, borderBottomWidth: 0 },
  cellUnderline: {
    borderBottomWidth: 1,
    borderStyle: 'solid',
    borderColor: '#DFE3E8'
  },
  tableCell_5: {
    width: '5%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_7: {
    width: '7%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_10: {
    width: '10%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_13: {
    width: '13%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_15: {
    width: '15%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_17: {
    width: '17%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_20: {
    width: '20%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_22: {
    width: '20%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_25: {
    width: '25%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_30: {
    width: '30%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_40: {
    width: '40%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_45: {
    width: '45%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_50: {
    width: '50%',
    marginHorizontal: '1%',
    ...underlineBorder
  },
  tableCell_95: {
    width: '95%',
    marginHorizontal: '1%',
    ...underlineBorder
  }
});

const getBoolStr = (state) => (state ? 'Да' : 'Нет');
const getVehicleType = (type) => (type === '1' ? 'Легковой' : 'Грузовой');
const getVehicleNumberType = (identifiersType) => {
  if (identifiersType === '1') return 'VIN';
  if (identifiersType === '2') return '№ кузова';
  return '№ шасси';
};
const getVehicleDocumentType = (documentType) => {
  const docs = { 1: 'ПТС', 2: 'СТС', 3: 'ЭПТС' };
  return docs[documentType];
};

NewPolisPDF.propTypes = {
  drivers: PropTypes.array,
  client: PropTypes.object,
  insurerIsOwner: PropTypes.bool,
  vehicle: PropTypes.object,
  insurer: PropTypes.object,
  owner: PropTypes.object,
  general: PropTypes.object,
  targets: PropTypes.array
};

// Create Document Component
export default function NewPolisPDF({
  drivers,
  client,
  insurerIsOwner,
  vehicle,
  insurer,
  owner,
  general,
  targets
}) {
  const getTarget = (targetId) =>
    targetId
      ? targets.find((target) => target.id.toString() === targetId.toString())
          .description
      : '';

  const getFIO = (person) =>
    `${person?.lastName ?? ''} ${person?.firstName ?? ''} ${
      person?.middleName ?? ''
    }`.trim();

  const getBirthdate = (person) =>
    typeof person.birthdate === 'string'
      ? person.birthdate
      : dateToString(person.birthdate);

  return (
    <Document language="ru">
      <Page size="A4" style={styles.page}>
        <View style={styles.table}>
          <Text style={styles.title}>Общая информация</Text>
          <View style={styles.tableBody}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>
                  Телефон <Text style={styles.lowerText}>клиента:</Text>
                </Text>
                <Text style={styles.body}>{client.phone}</Text>
              </View>
              <View style={styles.tableCell_25}>
                <Text style={styles.subtitle2}>
                  E-mail <Text style={styles.lowerText}>клиента:</Text>
                </Text>
                <Text style={styles.body}>{client.email}</Text>
              </View>

              <View style={styles.tableCell_50}>
                <Text style={styles.subtitle2}>
                  Цель <Text style={styles.lowerText}>использования:</Text>
                </Text>
                <Text style={styles.body}>{getTarget(general.target)}</Text>
              </View>
            </View>

            <View style={styles.tableRow}>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>
                  Срок <Text style={styles.lowerText}>страхования:</Text>
                </Text>
                <Text style={styles.body}>{`${general.term} месяцев`}</Text>
              </View>
              <View style={styles.tableCell_25}>
                <Text style={styles.subtitle2}>
                  Начало <Text style={styles.lowerText}>действия полиса:</Text>
                </Text>
                <Text style={styles.body}>
                  {typeof general.dateStart === 'string'
                    ? general.dateStart
                    : dateToString(general.dateStart)}
                </Text>
              </View>
              <View style={styles.tableCell_25}>
                <Text style={styles.subtitle2}>
                  Окончание{' '}
                  <Text style={styles.lowerText}>действия полиса:</Text>
                </Text>
                <Text style={styles.body}>
                  {typeof general.dateEnd === 'string'
                    ? general.dateEnd
                    : dateToString(general.dateEnd)}
                </Text>
              </View>
              <View style={styles.tableCell_25}>
                <Text style={styles.subtitle2}>
                  ФИО <Text style={styles.lowerText}>клиента:</Text>
                </Text>
                <Text style={styles.body}>{`${client.lastName} ${
                  client.firstName
                } ${client.middleName ?? ''}`}</Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <Text style={styles.title}>Транспортное средство</Text>
          <View style={styles.tableBody}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>Тип ТС:</Text>
                <Text style={styles.body}>{`${getVehicleType(
                  vehicle.type
                )}`}</Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  Гос. <Text style={styles.lowerText}>номер:</Text>
                </Text>
                <Text style={styles.body}>
                  {vehicle.withoutNumber
                    ? 'Без номера'
                    : `${vehicle.numberPlate}`}
                </Text>
              </View>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>Марка:</Text>
                <Text style={styles.body}>{`${
                  vehicle.brand?.title ?? ''
                }`}</Text>
              </View>
              <View style={styles.tableCell_50}>
                <Text style={styles.subtitle2}>Модель:</Text>
                <Text style={styles.body}>{`${
                  vehicle.model?.title ?? ''
                }`}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_20}>
                <Text style={styles.subtitle2}>{`${getVehicleNumberType(
                  vehicle.identifiersType
                )}:`}</Text>
                <Text style={styles.body}>{vehicle.identifiers}</Text>
              </View>
              <View style={styles.tableCell_13}>
                <Text style={styles.subtitle2}>Мощность ТС:</Text>
                <Text style={styles.body}>{`${vehicle.power} Л.С.`}</Text>
              </View>
              <View style={styles.tableCell_13}>
                <Text style={styles.subtitle2}>
                  Год <Text style={styles.lowerText}>выпуска:</Text>
                </Text>
                <Text style={styles.body}>{vehicle.year}</Text>
              </View>
              <View style={styles.tableCell_7}>
                <Text style={styles.subtitle2}>
                  Тип <Text style={styles.lowerText}>д-та:</Text>
                </Text>
                <Text style={styles.body}>
                  {getVehicleDocumentType(vehicle.docType)}
                </Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  Серия <Text style={styles.lowerText}>д-та:</Text>
                </Text>
                <Text style={styles.body}>{`${vehicle.docSerial}`}</Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  Номер <Text style={styles.lowerText}>д-та:</Text>
                </Text>
                <Text style={styles.body}>{vehicle.docNumber}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_13}>
                <Text style={styles.subtitle2}>
                  Дата <Text style={styles.lowerText}>выдачи:</Text>
                </Text>
                <Text style={styles.body}>
                  {typeof vehicle.docDate === 'string'
                    ? vehicle.docDate
                    : dateToString(vehicle.docDate)}
                </Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  С <Text style={styles.lowerText}>прицепом:</Text>
                </Text>
                <Text style={styles.body}>
                  {getBoolStr(general.useTrailer)}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_40}>
                <Text style={styles.subtitle2}>
                  Зарегистрировано
                  <Text style={styles.lowerText}> в другой стране:</Text>
                </Text>
                <Text style={styles.body}>{getBoolStr(general.isForeign)}</Text>
              </View>
              <View style={styles.tableCell_40}>
                <Text style={styles.subtitle2}>
                  С
                  <Text style={styles.lowerText}>
                    ледует к месту регистрации или к месту{' '}
                  </Text>
                  ТО:
                </Text>
                <Text style={styles.body}>
                  {getBoolStr(general.toRegistration)}
                </Text>
              </View>
            </View>
          </View>
        </View>

        <View style={styles.table}>
          <Text style={styles.title}>Страхователь</Text>
          <View style={styles.tableBody}>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_25}>
                <Text style={styles.subtitle2}>
                  ФИО <Text style={styles.lowerText}>страхователя</Text>:
                </Text>
                <Text style={styles.body}>
                  {`${insurer.lastName} ${insurer.firstName} ${
                    insurer.middleName ?? ''
                  }`}
                </Text>
              </View>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>
                  Тип <Text style={styles.lowerText}>документа</Text>:
                </Text>
                <Text style={styles.body}>
                  {insurer.passportForeign === '0' ? 'Паспорт РФ' : 'Другой'}
                </Text>
              </View>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>
                  Дата <Text style={styles.lowerText}>рождения</Text>:
                </Text>
                <Text style={styles.body}>
                  {typeof insurer.birthdate === 'string'
                    ? insurer.birthdate
                    : dateToString(insurer.birthdate)}
                </Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  Серия <Text style={styles.lowerText}>д-та</Text>:
                </Text>
                <Text style={styles.body}>{insurer.passportSerial}</Text>
              </View>
              <View style={styles.tableCell_10}>
                <Text style={styles.subtitle2}>
                  Номер <Text style={styles.lowerText}>д-та</Text>:
                </Text>
                <Text style={styles.body}>{insurer.passportNumber}</Text>
              </View>
              <View style={styles.tableCell_15}>
                <Text style={styles.subtitle2}>
                  Дата <Text style={styles.lowerText}>выдачи</Text>:
                </Text>
                <Text style={styles.body}>
                  {typeof insurer.passportDate === 'string'
                    ? insurer.passportDate
                    : dateToString(insurer.passportDate)}
                </Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_95}>
                <Text style={styles.subtitle2}>
                  Кем <Text style={styles.lowerText}>выдан</Text>:
                </Text>
                <Text style={styles.body}>{insurer.passportPoint ?? ''}</Text>
              </View>
            </View>
            <View style={styles.tableRow}>
              <View style={styles.tableCell_95}>
                <Text style={styles.subtitle2}>
                  Адрес <Text style={styles.lowerText}>регистрации</Text>:
                </Text>
                <Text style={styles.body}>
                  {insurer.dadata ? JSON.parse(insurer.dadata).value : ''}
                </Text>
              </View>
            </View>
          </View>
        </View>

        {insurerIsOwner ? undefined : (
          <View style={styles.table}>
            <Text style={styles.title}>Собственник</Text>
            <View style={styles.tableBody}>
              <View style={styles.tableRow}>
                <View style={styles.tableCell_25}>
                  <Text style={styles.subtitle2}>
                    ФИО <Text style={styles.lowerText}>собственника</Text>:
                  </Text>
                  <Text style={styles.body}>
                    {`${owner.lastName} ${owner.firstName} ${
                      owner.middleName ?? ''
                    }`}
                  </Text>
                </View>
                <View style={styles.tableCell_15}>
                  <Text style={styles.subtitle2}>
                    Тип <Text style={styles.lowerText}>документа</Text>:
                  </Text>
                  <Text style={styles.body}>
                    {owner.passportForeign === '0' ? 'Паспорт РФ' : 'Другой'}
                  </Text>
                </View>
                <View style={styles.tableCell_15}>
                  <Text style={styles.subtitle2}>
                    Дата <Text style={styles.lowerText}>рождения</Text>:
                  </Text>
                  <Text style={styles.body}>
                    {typeof owner.birthdate === 'string'
                      ? owner.birthdate
                      : dateToString(owner.birthdate)}
                  </Text>
                </View>
                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>
                    Серия <Text style={styles.lowerText}>д-та</Text>:
                  </Text>
                  <Text style={styles.body}>{owner.passportSerial}</Text>
                </View>
                <View style={styles.tableCell_10}>
                  <Text style={styles.subtitle2}>
                    Номер <Text style={styles.lowerText}>д-та</Text>:
                  </Text>
                  <Text style={styles.body}>{owner.passportNumber}</Text>
                </View>
                <View style={styles.tableCell_15}>
                  <Text style={styles.subtitle2}>
                    Дата <Text style={styles.lowerText}>выдачи</Text>:
                  </Text>
                  <Text style={styles.body}>
                    {owner.passportDate
                      ? owner.passportDate.toLocaleDateString()
                      : ''}
                  </Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell_95}>
                  <Text style={styles.subtitle2}>
                    Кем <Text style={styles.lowerText}>выдан</Text>:
                  </Text>
                  <Text style={styles.body}>{owner.passportPoint ?? ''}</Text>
                </View>
              </View>
              <View style={styles.tableRow}>
                <View style={styles.tableCell_95}>
                  <Text style={styles.subtitle2}>
                    Адрес <Text style={styles.lowerText}>регистрации</Text>:
                  </Text>
                  <Text style={styles.body}>
                    {owner.dadata ? JSON.parse(owner.dadata).value : ''}
                  </Text>
                </View>
              </View>
            </View>
          </View>
        )}
        {drivers[0].driversWithoutRestrictions ? undefined : (
          <View style={styles.table}>
            <Text style={styles.title}>Водители</Text>
            <View style={styles.tableBody}>
              {drivers.map((driver, _) => (
                <>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell_25}>
                      <Text style={styles.subtitle2}>
                        Иностранное{' '}
                        <Text style={styles.lowerText}>удостоверение</Text>:
                      </Text>
                      <Text style={styles.body}>
                        {getBoolStr(driver.licenseForeign)}
                      </Text>
                    </View>
                    <View style={styles.tableCell_25}>
                      <Text style={styles.subtitle2}>
                        ФИО <Text style={styles.lowerText}>водителя</Text>:
                      </Text>
                      <Text style={styles.body}>{getFIO(driver)}</Text>
                    </View>
                    <View style={styles.tableCell_15}>
                      <Text style={styles.subtitle2}>
                        Дата <Text style={styles.lowerText}>рождения</Text>:
                      </Text>
                      <Text style={styles.body}>{getBirthdate(driver)}</Text>
                    </View>
                  </View>
                  <View style={styles.tableRow}>
                    <View style={styles.tableCell_13}>
                      <Text style={styles.subtitle2}>Серия В/У:</Text>
                      <Text style={styles.body}>
                        {driver.driverLicenseSerial}
                      </Text>
                    </View>
                    <View style={styles.tableCell_13}>
                      <Text style={styles.subtitle2}>Номер В/У:</Text>
                      <Text style={styles.body}>
                        {driver.driverLicenseNumber}
                      </Text>
                    </View>
                    <View style={styles.tableCell_17}>
                      <Text style={styles.subtitle2}>
                        Дата <Text style={styles.lowerText}>начала стажа</Text>:
                      </Text>
                      <Text style={styles.body}>
                        {typeof driver.expDate === 'string'
                          ? driver.expDate
                          : dateToString(driver.expDate)}
                      </Text>
                    </View>
                    <View style={styles.tableCell_15}>
                      <Text style={styles.subtitle2}>
                        Дата <Text style={styles.lowerText}>выдачи </Text>В/У:
                      </Text>
                      <Text style={styles.body}>
                        {typeof driver.licenseDate === 'string'
                          ? driver.licenseDate
                          : dateToString(driver.licenseDate)}
                      </Text>
                    </View>
                  </View>
                  {driver.isPreviousDriverLicense ? (
                    <View style={styles.tableRow}>
                      <View style={styles.tableCell_15}>
                        <Text style={styles.subtitle2}>
                          Серия <Text style={styles.lowerText}>пред.</Text> В/У:
                        </Text>
                        <Text style={styles.body}>
                          {driver.previousDriverLicenseSerial}
                        </Text>
                      </View>
                      <View style={styles.tableCell_15}>
                        <Text style={styles.subtitle2}>
                          Номер <Text style={styles.lowerText}>пред.</Text> В/У:
                        </Text>
                        <Text style={styles.body}>
                          {driver.previousDriverLicenseNumber}
                        </Text>
                      </View>
                      <View style={styles.tableCell_20}>
                        <Text style={styles.subtitle2}>
                          Дата{' '}
                          <Text style={styles.lowerText}>выдачи пред. </Text>
                          В/У:
                        </Text>
                        <Text style={styles.body}>
                          {typeof driver.previousDriverLicenseIssueDate ===
                          'string'
                            ? driver.previousDriverLicenseIssueDate
                            : dateToString(
                                driver.previousDriverLicenseIssueDate
                              )}
                        </Text>
                      </View>
                      <View style={styles.tableCell_25}>
                        <Text style={styles.subtitle2}>
                          ФИО
                          <Text style={styles.lowerText}> в пред. </Text>
                          В/У:
                        </Text>
                        <Text style={styles.body}>
                          {`${driver.previousDriverLastName} ${
                            driver.previousDriverFirstName
                          } ${driver.previousDriverMiddleName ?? ''}`}
                        </Text>
                      </View>
                    </View>
                  ) : undefined}
                </>
              ))}
            </View>
          </View>
        )}
      </Page>
    </Document>
  );
}
