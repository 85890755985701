import { useSnackbar } from 'notistack';
import closeFill from '@iconify/icons-eva/close-fill';
import { MIconButton } from 'components/@material-extend';

import { Icon } from '@iconify/react';

export default function useErrorSnackbar() {
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  return (message) => {
    closeSnackbar();
    enqueueSnackbar(message, {
      variant: 'error',
      action: (key) => (
        <MIconButton size="small" onClick={() => closeSnackbar(key)}>
          <Icon icon={closeFill} />
        </MIconButton>
      )
    });
  };
}
