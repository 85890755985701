import PropTypes from 'prop-types';
import { forwardRef } from 'react';
// material
import { Box, TextField } from '@material-ui/core';
import { makeStyles, createStyles } from '@material-ui/core/styles';

// ----------------------------------------------------------------------
const useStyles = makeStyles((theme) =>
  createStyles({
    complete: {
      color: theme.palette.text.primary
    },
    notComplete: {
      color: theme.palette.text.disabled
    }
  })
);
// ----------------------------------------------------------------------

const MTextField = forwardRef(
  ({ InputLabelProps, sx, value, multiline = false, ...props }, ref) => {
    const classes = useStyles();

    function getClass(value) {
      if (props.complete) return classes.complete;
      if (value === '') return classes.notComplete;
      if (!value) return classes.notComplete;

      return classes.complete;
    }

    return (
      <Box
        boxShadow={2}
        borderRadius={1}
        sx={{ backgroundColor: 'white', ...sx }}
        height={multiline ? 125 : 55}
      >
        <TextField
          fullWidth
          ref={ref}
          InputLabelProps={{
            classes: {
              root: getClass(value)
            },
            ...InputLabelProps
          }}
          value={value}
          {...props}
        >
          {props.children}
        </TextField>
      </Box>
    );
  }
);

MTextField.propTypes = {
  children: PropTypes.node,
  InputLabelProps: PropTypes.object,
  sx: PropTypes.object,
  complete: PropTypes.bool,
  multiline: PropTypes.bool,
  value: PropTypes.string
};

export default MTextField;
