import { useDispatch, useSelector } from 'react-redux';
import FilterIcon from '@material-ui/icons/FilterListRounded';
import PersonAddIcon from '@material-ui/icons/PersonAddAlt1Rounded';
import {
  Box,
  Typography,
  Grid,
  IconButton,
  useMediaQuery,
  useTheme
} from '@material-ui/core';

import { newContact } from 'redux/slices/contacts/contacts';
import { PATH_CONTACTS } from 'routes/paths';
import { useHistory } from 'react-router';
//
import { ClearFiltersButton, DateFilter, NameSignPhoneFilter } from './filters';

const b = 0;

function getClientLabel(count) {
  const c = count % 10;
  if (c === 1) return 'клиент';
  if ([2, 3, 4].includes(c)) return 'клиента';
  return 'клиентов';
}

export default function CalculationsHeader() {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const history = useHistory();
  const { contracts } = useSelector((state) => state.contracts);

  const handleNewContact = async () => {
    //
    await dispatch(newContact());
    history.push(`${PATH_CONTACTS.root}/new_contact`);
  };

  return (
    <Box border={b} sx={{ mx: 3, mb: 5 }}>
      <Grid
        container
        columnGap={1}
        border={b}
        sx={{
          mx: 0,
          width: '100%',
          mb: 3
        }}
        rowGap={1}
        alignItems="center"
      >
        <Grid
          item
          border={b}
          sx={{
            px: 4
          }}
        >
          <IconButton onClick={() => {}}>
            <FilterIcon />
          </IconButton>
          <IconButton onClick={handleNewContact}>
            <PersonAddIcon color="secondary" />
          </IconButton>
        </Grid>
        <Grid item xs />
        <Grid item border={b}>
          <Typography>
            Найдено: <b>{contracts.length}</b> расчетов /{' '}
            <b>
              {
                [
                  ...new Set(
                    contracts
                      .filter((contract) => contract.client)
                      .map((contract) => contract.client.id)
                  )
                ].length
              }
            </b>{' '}
            {getClientLabel(
              [
                ...new Set(
                  contracts
                    .filter((contract) => contract.client)
                    .map((contract) => contract.client.id)
                )
              ].length
            )}
          </Typography>
        </Grid>
        <Grid item border={b}>
          <ClearFiltersButton />
        </Grid>
        <Grid item xs />
        <Grid item border={b} justifySelf="self-end">
          <Typography>
            <b>{contracts.filter((contract) => contract.offer).length}</b>{' '}
            продаж на{' '}
            <b>
              {contracts
                .filter((contract) => contract.offer)
                .map((contract) => contract.offer?.total ?? 0)
                .reduce((prev, current) => prev + current, 0)}{' '}
              руб.
            </b>{' '}
            с вознаграждением{' '}
            <b>
              {contracts
                .filter((contract) => contract.offer)
                .map((contract) => contract.offer?.comission ?? 0)
                .reduce((prev, current) => prev + current, 0)}{' '}
              руб.
            </b>
          </Typography>
        </Grid>
      </Grid>
      <Grid container columnGap={4}>
        <Grid
          item
          xs={isDesktop ? 8 : 12}
          justifyContent="center"
          justifyItems="center"
        >
          <NameSignPhoneFilter />
        </Grid>
        {!isDesktop && (
          <Grid item border={b}>
            <DateFilter />
          </Grid>
        )}
      </Grid>
    </Box>
  );
}
