import * as Yup from 'yup';

// ----------------------------------------------------------------------

const changePasswordSchema = Yup.object().shape({
  oldPassword: Yup.string().required('Старый пароль обязателен'),
  newPassword: Yup.string()
    .min(6, 'Пароль должен состоять минимум из 6 символов')
    .required('Новый пароль обязателен')
    .notOneOf([Yup.ref('oldPassword'), null], 'Совпадает со старым паролем'),
  confirmNewPassword: Yup.string().oneOf(
    [Yup.ref('newPassword'), null],
    'Пароли не совпадают'
  )
});

export default changePasswordSchema;
