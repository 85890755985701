import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Dialog,
  DialogContent,
  Grid,
  Typography,
  CircularProgress,
  Link,
  useMediaQuery,
  Box,
  AppBar,
  Toolbar,
  FormControlLabel,
  Checkbox,
  Card,
  CardContent,
  CardActions,
  CardMedia,
  Button
} from '@material-ui/core';
import {
  useTheme,
  experimentalStyled as styled
} from '@material-ui/core/styles';
import { MLinearProgress as LinearProgress } from 'components/@material-extend';
// requests
import { payOffer } from 'requests/eosago-contracts-requests';
import { updateCompany } from 'redux/slices/osago/edit_contract';

// ----------------------------------------------------------------------

const StyledFormControl = styled(FormControlLabel)({
  '& .MuiFormControlLabel-label': {
    fontSize: 20
  }
});
const StyledCheckbox = styled(Checkbox)({
  marginBottom: -4
});
const StyledCard = styled(Card)({
  minHeight: 300,
  height: 450
});
const CardImage = styled('img')({
  display: 'block',
  margin: '0 auto',
  height: 230,
  objectFit: 'fill'
});
const CardButton = styled(Button)({
  marginX: '3%'
});
const CardLink = styled(Link)({
  marginLeft: '3%',
  marginBottom: '2%'
});
const ChoseMessage = styled(Typography)({
  marginLeft: '3%',
  marginBottom: '2%'
});

// ----------------------------------------------------------------------

const Item = ({
  id,
  name,
  status,
  message,
  total,
  comission,
  offerId,
  showComission,
  showAccepted
}) => {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const [paylink, setPaylink] = useState(null);
  const [choseMessage, setChoseMessage] = useState('');
  const [isChose, setChose] = useState(false);

  const [timer, setTimer] = useState(null);

  useEffect(() => {
    if (status === 'wait') {
      const timer = setTimer(
        setTimeout(() => {
          dispatch(
            updateCompany(
              JSON.stringify({
                companyId: id,
                error: 'Предложений от компании не получено'
              })
            )
          );
        }, 60000)
      );
      setTimer(timer);
      return;
    }
    if (status !== 'wait') {
      clearTimeout(timer);
    }
  }, [status, dispatch, id, timer]);

  const handleChoose = async () => {
    if (!offerId) return;
    try {
      const payResult = await payOffer(offerId);
      const { url = null } = payResult;
      setPaylink(url);
    } catch (exc) {
      setChoseMessage(exc.error);
      console.log(exc);
    } finally {
      setChose(true);
    }
  };
  const getAction = () => {
    if (!paylink && isChose) return <ChoseMessage>{choseMessage}</ChoseMessage>;
    return !paylink ? (
      <CardButton fullWidth variant="contained" onClick={handleChoose}>
        Выбрать
      </CardButton>
    ) : (
      <CardLink
        // onClick={handleChoose}
        href={paylink}
        target="_blank"

        // onClick={(event) => event.preventDefault()}
      >
        Оплатить
      </CardLink>
    );
  };

  if (showAccepted && status === 'denied') {
    return null;
  }
  return (
    <Grid item xs={isDesktop ? 4 : 6}>
      <StyledCard>
        <CardMedia>
          <CardImage
            alt="cover"
            src="https://thumbs.dreamstime.com/z/%D0%B6%D0%B8%D0%BB%D0%B8%D1%89%D0%BD%D0%BE%D0%B5-%D1%81%D1%82%D1%80%D0%BE%D0%B8%D1%82%D0%B5%D0%BB%D1%8C%D1%81%D1%82%D0%B2%D0%BE-%D0%BD%D0%B5%D0%B4%D0%B2%D0%B8%D0%B6%D0%B8%D0%BC%D0%BE%D1%81%D1%82%D0%B8-%D0%B8-%D0%BA%D0%BE%D0%BD%D1%81%D1%82%D1%80%D1%83%D0%BA%D1%86%D0%B8%D0%B8-%D0%B2%D0%B5%D0%BA%D1%82%D0%BE%D1%80-%D0%BB%D0%BE%D0%B3%D0%BE%D1%82%D0%B8%D0%BF%D0%B0-104762620.jpg"
          />
        </CardMedia>
        <CardContent sx={{ height: 160 }}>
          <Typography variant="h5" textAlign="center">
            {name}
          </Typography>
          <Typography>{message}</Typography>
          {status === 'accepted' ? (
            <>
              <Typography>{`Сумма к оплате: ${total}`}</Typography>
              {showComission ? (
                <Typography>{`Коммиссия агента: ${comission}`}</Typography>
              ) : undefined}
            </>
          ) : undefined}
          {status === 'wait' ? (
            <LinearProgress sx={{ marginTop: { xs: 2 } }} />
          ) : undefined}
        </CardContent>
        {status === 'accepted' ? (
          <CardActions>{getAction()}</CardActions>
        ) : undefined}
      </StyledCard>
    </Grid>
  );
};

Item.propTypes = {
  id: PropTypes.number,
  name: PropTypes.string,
  status: PropTypes.object,
  message: PropTypes.string,
  total: PropTypes.number,
  comission: PropTypes.number,
  offerId: PropTypes.number,
  showComission: PropTypes.bool,
  showAccepted: PropTypes.bool
};

CalculatePolisDialog.propTypes = {
  open: PropTypes.bool,
  closeAction: PropTypes.func
};

export default function CalculatePolisDialog({ open, closeAction }) {
  const { companies } = useSelector((state) => state.editContract);

  const [showComission, setShowComission] = useState(false);
  const [showAccepted, setShowAccepted] = useState(false);

  return (
    <Dialog open={open} onClose={closeAction} fullWidth maxWidth="lg">
      <AppBar position="static" color="default">
        <Toolbar>
          <Typography variant="h4" sx={{ marginRight: { xs: 2 }, flexGrow: 1 }}>
            Расчет страхового полиса
          </Typography>
          <StyledFormControl
            control={
              <StyledCheckbox
                color="primary"
                checked={showComission}
                onChange={(event) => setShowComission(event.target.checked)}
              />
            }
            label="Информация для агента"
          />
          <StyledFormControl
            control={
              <StyledCheckbox
                color="primary"
                checked={showAccepted}
                onChange={(event) => setShowAccepted(event.target.checked)}
              />
            }
            label="Показать только положительные ответы"
          />
        </Toolbar>
      </AppBar>
      <DialogContent>
        <Box>
          <Grid
            container
            justifyContent={companies.length === 0 ? 'center' : 'flex-start'}
            spacing={2}
          >
            {companies.length === 0 ? (
              <CircularProgress disableShrink size={100} thickness={5} />
            ) : (
              companies.map((company, index) => (
                <Item
                  id={index}
                  key={company.id}
                  name={company.name}
                  status={company.status}
                  message={company.message}
                  comission={company.comission}
                  total={company.total}
                  showAccepted={showAccepted}
                  showComission={showComission}
                />
              ))
            )}
          </Grid>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
