import PropTypes from 'prop-types';
// material
import { Dialog, DialogContent, Box, Typography } from '@material-ui/core';

// ----------------------------------------------------------------------

ContractEmailDialog.propTypes = {
  open: PropTypes.bool,
  onClose: PropTypes.func
};
export default function ContractEmailDialog({ open, onClose }) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box height={150} width={400} display="flex" justifyContent="center">
          <Typography variant="h5">Сообщение</Typography>
        </Box>
      </DialogContent>
    </Dialog>
  );
}
