import {
  InputAdornment,
  TextField,
  useMediaQuery,
  useTheme
} from '@material-ui/core';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
//
import { setNameSignPhoneFilter } from 'redux/slices/osago/contracts';
import { DateFilter } from '.';

export default function NameSignPhoneFilter() {
  const [value, setValue] = useState('');
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const dispatch = useDispatch();
  const { nameSignPhone } = useSelector((state) => state.contracts.filters);

  useEffect(() => {
    if (!nameSignPhone) {
      setValue('');
    }
  }, [nameSignPhone]);

  const handleChange = (event) => {
    const val = event.target.value;
    if (val === '') {
      setValue(val);
      return;
    }
    if (val.search(/^[А-Яа-яёЁ0-9]+$/g) !== -1) {
      setValue(val);
    }
  };

  const handleBlur = (event) => {
    dispatch(setNameSignPhoneFilter(event.target.value));
  };

  return (
    <TextField
      fullWidth
      value={value}
      onChange={handleChange}
      onBlur={handleBlur}
      // placeholder=""
      label="Поиск по телефону, Фамилии, госномеру"
      InputProps={{
        ...(isDesktop && {
          endAdornment: (
            <InputAdornment position="end">
              <DateFilter />
            </InputAdornment>
          )
        })
      }}
    />
  );
}
