import * as Yup from 'yup';
import { documentSerialRegex, documentNumberRegex } from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const kbmSchema = Yup.object().shape({
  isPreviousLicense: false,

  firstName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.notRequired()
        : schema.required('Имя обязательно')
  ),
  middleName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.notRequired()
        : schema.required('Отчество обязательно')
  ),
  lastName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.notRequired()
        : schema.required('Фамилия обязательна')
  ),
  birthdate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .required('Дата рождения обязательна'),
  licenseSerial: Yup.string()
    .when('isPreviousLicense', (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.notRequired()
        : schema.required('Серия обязательна')
    )
    .when('isForeignIdentity', (isForeignIdentity, schema) =>
      !isForeignIdentity
        ? schema.matches(documentSerialRegex, 'Неверная серия')
        : undefined
    ),
  licenseNumber: Yup.string()
    .when('isPreviousLicense', (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.notRequired()
        : schema.required('Номер обязателен')
    )
    .when('isForeignIdentity', (isForeignIdentity, schema) =>
      !isForeignIdentity
        ? schema.matches(documentNumberRegex, 'Неверный номер')
        : undefined
    ),
  //   experienceStartDate: Yup.date()
  //     .min(MIN_VALIDATION_DATE, 'Дата некорректна')
  //     .required('Дата обязательна'),
  //   licenseIssueDate: Yup.date()
  //     .min(MIN_VALIDATION_DATE, 'Дата некорректна')
  //     .required('Дата обязательна'),

  // Подблок предыдущее В/У
  previousLicenseSerial: Yup.string()
    .when('isPreviousLicense', (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Серия обязательна')
        : schema.notRequired()
    )
    .when('isForeignIdentity', (isForeignIdentity, schema) =>
      !isForeignIdentity
        ? schema.matches(documentSerialRegex, 'Неверная серия')
        : undefined
    ),
  previousLicenseNumber: Yup.string()
    .when('isPreviousLicense', (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Номер обязателен')
        : schema.notRequired()
    )
    .when('isForeignIdentity', (isForeignIdentity, schema) =>
      !isForeignIdentity
        ? schema.matches(documentNumberRegex, 'Неверный номер')
        : undefined
    ),
  previousLicenseIssueDate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('isPreviousLicense', (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Дата выдачи обязательна')
        : schema.notRequired()
    ),
  previousFirstName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Имя обязательно')
        : schema.notRequired()
  ),
  previousMiddleName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Отчество обязательно')
        : schema.notRequired()
  ),
  previousLastName: Yup.string().when(
    'isPreviousLicense',
    (isPreviousLicense, schema) =>
      isPreviousLicense
        ? schema.required('Фамилия обязательна')
        : schema.notRequired()
  )
});

export default kbmSchema;
