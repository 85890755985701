import axios from 'utils/axios';

export const getUser = async (id) => {
  const response = await axios.get(`api/v1/users/${id}`);
  return response.data;
};

export const searchClientsByPhone = async (phone) => {
  const response = await axios.get('api/v1/users', {
    params: {
      search: phone,
      page: 1
    }
  });
  return response.data;
};

export const getUserChildren = async (userId) => {
  const response = await axios.get(`api/v1/users/${userId}/children`);
  return response.data;
};

export async function updateUser(id, data) {
  const {
    // general
    firstName,
    middleName,
    lastName,
    birthdate,
    address,
    company,
    phone,
    email,
    // requisits
    bankName,
    cardNumber,
    entityName,
    inn,
    kpp,
    ogrn,
    entityAddress,
    settlementAccount,
    bik,
    correspondentAccount,
    // password
    password
  } = data;
  const body = {
    // general
    ...(firstName && { firstName }),
    ...(middleName && { middleName }),
    ...(lastName && { lastName }),
    ...(birthdate && { birthdate }),
    ...(address && { address }),
    ...(company && { company }),
    ...(phone && { phone }),
    ...(email && { email }),

    // requisits
    ...(bankName && { bankName }),
    ...(cardNumber && { cardNumber }),
    ...(entityName && { entityName }),
    ...(inn && { inn }),
    ...(kpp && { kpp }),
    ...(ogrn && { ogrn }),
    ...(entityAddress && { entityAddress }),
    ...(settlementAccount && { settlementAccount }),
    ...(bik && { bik }),
    ...(correspondentAccount && { correspondentAccount }),

    // password
    ...(password && { password })
  };
  const response = await axios.put(`api/v1/users/${id}`, body);
  return response.data;
}

export async function createClient(data) {
  const response = await axios.post('api/v1/contacts', data);
  return response.data;
}
