import PropTypes from 'prop-types';
import { useEffect } from 'react';
import { Link as RouterLink, useLocation, matchPath } from 'react-router-dom';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
import {
  Box,
  Link,
  List,
  Avatar,
  Drawer,
  Hidden,
  Typography,
  ListSubheader
} from '@material-ui/core';
// components
import Logo from 'components/Logo';
import Scrollbar from 'components/Scrollbar';
//
import { PATH_PERSONAL } from 'routes/paths';
import useAuth from 'hooks/useAuth';
//
import MenuLinks from './SidebarConfig';
import SidebarItem from './SidebarItem';

// ----------------------------------------------------------------------

const DRAWER_WIDTH = 280;

const RootStyle = styled('div')(({ theme }) => ({
  [theme.breakpoints.up('lg')]: {
    flexShrink: 0,
    width: DRAWER_WIDTH
  }
}));

const AccountStyle = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  padding: theme.spacing(2, 2.5),
  margin: theme.spacing(1, 2.5, 5),
  borderRadius: theme.shape.borderRadiusSm,
  backgroundColor: theme.palette.grey[500_12]
}));

// ----------------------------------------------------------------------

function reduceChild({ array, item, pathname, level }) {
  const key = item.href + level;
  if (item.items) {
    const match = matchPath(pathname, {
      path: item.href,
      exact: false
    });

    array = [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        icon={item.icon}
        info={item.info}
        href={item.href}
        title={item.title}
        isButton={item.isButton}
        buttonAction={item.buttonAction}
        open={Boolean(match)}
      >
        {RenderSidebarItems({
          pathname,
          level: level + 1,
          items: item.items
        })}
      </SidebarItem>
    ];
  } else {
    array = [
      ...array,
      <SidebarItem
        key={key}
        level={level}
        href={item.href}
        icon={item.icon}
        info={item.info}
        title={item.title}
        isButton={item.isButton}
        buttonAction={item.buttonAction}
      />
    ];
  }
  return array;
}

RenderSidebarItems.propTypes = {
  items: PropTypes.array,
  pathname: PropTypes.string,
  level: PropTypes.number
};
function RenderSidebarItems({ items, pathname, level = 0 }) {
  const { user } = useAuth();

  return (
    <List disablePadding>
      {items
        .filter((item) => {
          // TODO: Переделать этот фильтр. Возможно предоставить возможность добавления новых фильтров
          if (
            item.title === 'Пользователи' &&
            ![1, 2, 3, 4].includes(user.roleId)
          )
            return false;
          return true;
        })
        .reduce(
          (array, item) => reduceChild({ array, item, pathname, level }),
          []
        )}
    </List>
  );
}

MenuSidebar.propTypes = {
  isOpenSidebar: PropTypes.bool,
  onCloseSidebar: PropTypes.func
};

export default function MenuSidebar({ isOpenSidebar, onCloseSidebar }) {
  const { pathname } = useLocation();
  const { user } = useAuth();

  useEffect(() => {
    if (isOpenSidebar && onCloseSidebar) {
      onCloseSidebar();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pathname]);

  const renderContent = (
    <Scrollbar>
      <Box sx={{ px: 2.5, py: 3 }}>
        <RouterLink to={PATH_PERSONAL.root}>
          <Logo />
        </RouterLink>
      </Box>

      <Link underline="none" component={RouterLink} to="#">
        <AccountStyle>
          <Avatar
            alt="My Avatar"
            src="/static/mock-images/avatars/avatar_default.jpg"
          />
          <Box sx={{ ml: 2 }}>
            <Typography variant="subtitle2" sx={{ color: 'text.primary' }}>
              {user.firstName}
            </Typography>
            <Typography variant="body2" sx={{ color: 'text.secondary' }}>
              {user.roleName}
            </Typography>
          </Box>
        </AccountStyle>
      </Link>

      {MenuLinks.map((list, index) => (
        <List
          disablePadding
          key={`${list.subheader}_${index}`}
          subheader={
            <ListSubheader
              disableSticky
              disableGutters
              sx={{
                mt: 3,
                mb: 2,
                pl: 5,
                color: 'text.primary',
                typography: 'overline'
              }}
            >
              {list.subheader}
            </ListSubheader>
          }
        >
          {RenderSidebarItems({
            items: list.items,
            pathname,
            user
          })}
        </List>
      ))}
    </Scrollbar>
  );

  return (
    <RootStyle>
      <Hidden lgUp>
        <Drawer
          open={isOpenSidebar}
          onClose={onCloseSidebar}
          PaperProps={{
            sx: { width: DRAWER_WIDTH }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
      <Hidden lgDown>
        <Drawer
          open
          variant="persistent"
          PaperProps={{
            sx: { width: DRAWER_WIDTH, bgcolor: 'background.default' }
          }}
        >
          {renderContent}
        </Drawer>
      </Hidden>
    </RootStyle>
  );
}
