// hooks
import useAuth from 'hooks/useAuth';
//
import createAvatar from 'utils/createAvatar';
import { MAvatar } from './@material-extend';

// ----------------------------------------------------------------------

export default function MyAvatar({ ...other }) {
  const { user } = useAuth();

  return (
    <MAvatar
      // TODO: заменить на аватар пользователя
      src="/static/mock-images/avatars/avatar_default.jpg"
      alt={user.firstName}
      color={user.photoURL ? 'default' : createAvatar(user.firstName).color}
      {...other}
    >
      {createAvatar(user.firstName).name}
    </MAvatar>
  );
}
