import { useState } from 'react';
import PropTypes from 'prop-types';
// material
import { experimentalStyled as styled } from '@material-ui/core/styles';
//
import PersonalNavbar from './PersonalNavbar';
import MenuSidebar from './MenuSidebar';
import VerificationDialog from './VerificationDialog';
import CheckKBMDialog from './CheckKBMDialog';

// ----------------------------------------------------------------------

const APP_BAR_MOBILE = 64;
const APP_BAR_DESKTOP = 92;

const RootStyle = styled('div')({
  display: 'flex',
  minHeight: '100%',
  overflow: 'hidden'
});
const MainStyle = styled('div')(({ theme }) => ({
  flexGrow: 1,
  minHeight: '100%',
  paddingTop: APP_BAR_MOBILE + 24,
  paddingBottom: theme.spacing(10),
  [theme.breakpoints.up('lg')]: {
    paddingTop: APP_BAR_DESKTOP + 24
  }
}));

// ----------------------------------------------------------------------

PersonalLayout.propTypes = {
  children: PropTypes.node
};

export default function PersonalLayout({ children }) {
  const [open, setOpen] = useState(false);

  return (
    <RootStyle>
      <VerificationDialog />
      <CheckKBMDialog />
      <PersonalNavbar onOpenSidebar={() => setOpen(true)} />
      <MenuSidebar isOpenSidebar={open} onCloseSidebar={() => setOpen(false)} />

      <MainStyle>{children}</MainStyle>
    </RootStyle>
  );
}
