import { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useFormikContext } from 'formik';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
// utils
import { clearPhoneMask, toPhoneMask } from 'utils/maskFuncs';
import { phoneRegex } from 'utils/regexes';
//
import {
  MAutocomplete as Autocomplete,
  MIconButton
} from 'components/@material-extend';
import { searchClientsByPhone } from 'requests/users-requests';
import {
  FormBlock,
  BlockTitle,
  FormTextField,
  FormPhoneInput
} from 'components/personal/form-components';

// ----------------------------------------------------------------------

export default function GeneralInformation() {
  const { clients } = useSelector((state) => state.osago);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();

  const {
    setFieldValue,
    values: { clientSelect, clientPhone }
  } = useFormikContext();

  const clientsItems = [
    { title: 'Создать нового', value: '-1' },
    ...(clients
      ? clients.map((client) => ({
          title: getClientLabel(client),
          value: `${client.userId}`
        }))
      : [])
  ];

  const changeClient = (item) => {
    if (item.value === -1) {
      setFieldValue('clientEmail', '');
      setFieldValue('clientPhone', '');
      setFieldValue('clientFirstName', '');
      setFieldValue('clientMiddleName', '');
      setFieldValue('clientLastName', '');
      return;
    }
    const client = clients.find((element) => element.userId === item.value);
    setFieldValue('clientEmail', client.email);
    setFieldValue('clientPhone', toPhoneMask(client.phone));
    setFieldValue('clientFirstName', client.firstName);
    setFieldValue('clientMiddleName', client.middleName);
    setFieldValue('clientLastName', client.lastName);
  };
  const getClientLabel = (client) => {
    const firstNameShort =
      client.firstName !== null ? `${client.firstName[0]}.` : '';

    const middleNameShort =
      client.middleName !== null ? `${client.middleName[0]}.` : '';

    if (client.lastName === null) {
      if (client.middleName === null) {
        return `${client.firstName} ${client.phone}`;
      }
      return `${client.firstName} ${client.middleName} ${client.phone}`;
    }

    return `${client.lastName} ${firstNameShort} ${middleNameShort} ${client.phone}`;
  };

  useEffect(() => {
    async function fetchData() {
      if (clientSelect.value === '-1') {
        const phone = clearPhoneMask(clientPhone);
        if (phone.search(/7\d{10}/g) !== -1) {
          enqueueSnackbar(`Поиск клиента...`, {
            variant: 'info',
            action: (key) => (
              <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                <Icon icon={closeFill} />
              </MIconButton>
            )
          });
          // TODO: Проверить поиск и возможно переработать
          const { Users = undefined } =
            (await searchClientsByPhone(phone)) || {};
          if (Users?.data && Users.data.length > 0) {
            enqueueSnackbar(`Найден клиент`, {
              variant: 'success',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });

            setFieldValue('clientEmail', Users.data[0].email);
            setFieldValue('clientFirstName', Users.data[0].firstName);
            setFieldValue('clientMiddleName', Users.data[0].middleName);
            setFieldValue('clientLastName', Users.data[0].lastName);
            setFieldValue('clientSelect', {
              title: getClientLabel(Users.data[0]),
              value: Users.data[0].userId
            });
          } else {
            enqueueSnackbar(`Клиент не найден`, {
              variant: 'warning',
              action: (key) => (
                <MIconButton size="small" onClick={() => closeSnackbar(key)}>
                  <Icon icon={closeFill} />
                </MIconButton>
              )
            });
          }
        }
      }
    }
    fetchData();
  }, [
    clientPhone,
    clientSelect,
    closeSnackbar,
    enqueueSnackbar,

    setFieldValue
  ]);

  const handlePhoneInputChange = (event) => {
    const phone = event.target.value;
    if (phone.search(phoneRegex) !== -1) {
      setFieldValue('clientPhone', phone);
    }
  };

  return (
    <FormBlock title={<BlockTitle title="Общая информация" />}>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormPhoneInput
          name="clientPhone"
          fullWidth
          label="Телефон клиента"
          placeholder="Введите телефон клиента"
          required
          onChange={handlePhoneInputChange}
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <Autocomplete
          name="clientSelect"
          options={clientsItems}
          onChangeAction={changeClient}
          getOptionLabel={(option) => option.title}
          fullWidth
          defaultValue={{ title: 'Создать нового', value: '-1' }}
          renderInputParams={{
            name: 'clientSelect',
            label: 'Клиент',
            placeholder: 'Начните ввод'
          }}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="clientEmail"
          fullWidth
          label="E-mail клиента"
          placeholder="Введите e-mail"
        />
      </Grid>
    </FormBlock>
  );
}
