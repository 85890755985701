import { useDispatch, useSelector } from 'react-redux';
// material
import { Dialog, DialogContent, Typography, Box } from '@material-ui/core';
//
import { CheckKBMForm } from 'components/personal/osago';
import { closeKBMDialog } from 'redux/slices/osago/kbm';

// ----------------------------------------------------------------------

export default function CheckKBMDialog() {
  const dispatch = useDispatch();
  const { showDialog } = useSelector((state) => state.kbm);

  const handleClose = () => {
    dispatch(closeKBMDialog());
  };

  return (
    <Dialog open={showDialog} fullWidth maxWidth="lg">
      <DialogContent>
        <Typography variant="h3" gutterBottom>
          Проверка КБМ
        </Typography>

        <Box sx={{ mt: 5, mb: 3, mx: 1 }}>
          <CheckKBMForm closeDialog={handleClose} />
        </Box>
      </DialogContent>
    </Dialog>
  );
}
