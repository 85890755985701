import { createSlice } from '@reduxjs/toolkit';
import {
  deleteDriver,
  getDriver,
  storeDriver
} from 'requests/drivers-requests';
import {
  storeEosagoGeneral,
  getEosagoGeneral
} from 'requests/eosago-general-requests';
import { getPerson, storePerson } from 'requests/persons-requests';
import { createClient, updateUser } from 'requests/users-requests';
import {
  getModelById,
  getVehicle,
  storeVehicle
} from 'requests/vehicles-requests';
import { storeEosagoContract } from 'requests/eosago-contracts-requests';
import { getCompaniesList } from 'requests/companies-requests';
import { toPhoneMask } from 'utils/maskFuncs';

const POLIS_START_MIN_VALIDATION_DATE = new Date(
  new Date().setDate(new Date().getDate() + 4)
);

const initialState = {
  isEdit: false,
  id: null,
  general: {
    id: null,
    clientPhone: '',
    clientEmail: '',
    target: '1',
    term: 12,
    dateStart: POLIS_START_MIN_VALIDATION_DATE,
    dateEnd: new Date(),
    useTrailer: false,
    prevPolicySerial: null,
    prevPolicyNumber: '',
    // Следующие две опции на данный момент недоступны
    vehicleForeignRegistration: false,
    vehicleGoesToRegistration: false
  },
  updateGeneral: null,
  client: null,
  vehicle: {
    id: null,
    numberPlate: '',
    type: '1',
    power: '',
    year: '',
    identifiersType: '1',
    identifiers: '',
    docType: '1',
    docSerial: '',
    docNumber: '',
    docDate: '',
    brand: null,
    model: null
  },
  updateVehicle: null,
  insurerIsOwner: true,
  insurerIsClient: true,
  ownerIsClient: false,
  insurer: {
    id: null,
    type: 'INSURER',
    lastName: '',
    firstName: '',
    middleName: '',
    birthdate: '',
    address: null,
    passportPoint: null,
    genderId: '1',
    passportSerial: '',
    passportNumber: '',
    passportDate: '',
    passportForeign: '0',
    insurerIsOwner: true
  },
  updateInsurer: null,
  owner: {
    id: null,
    type: 'OWNER',
    lastName: '',
    firstName: '',
    middleName: '',
    birthdate: '',
    address: null,
    passportPoint: null,
    genderId: '1',
    passportSerial: '',
    passportNumber: '',
    passportDate: '',
    passportForeign: '0',
    insurerIsOwner: true
  },
  updateOwner: null,
  ownerCopy: {
    id: null,
    type: 'OWNER',
    lastName: '',
    firstName: '',
    middleName: '',
    birthdate: '',
    address: null,
    passportPoint: null,
    genderId: '1',
    passportSerial: '',
    passportNumber: '',
    passportDate: '',
    passportForeign: '0',
    insurerIsOwner: true
  },
  drivers: [],
  updateDrivers: [],
  contract: null,
  companies: []
};

const slice = createSlice({
  name: 'edit_contract',
  initialState,
  reducers: {
    toInitial(state, _) {
      Object.entries(initialState).forEach(([key, value]) => {
        state[key] = value;
      });
    },
    setIsEdit(state, action) {
      state.isEdit = action.payload;
    },
    setClient(state, action) {
      state.client = action.payload;
    },
    setGeneral(state, action) {
      state.general = action.payload;
    },
    addToGeneral(state, action) {
      state.general = { ...state.general, ...action.payload };
    },
    setUpdateGeneral(state, action) {
      state.updateGeneral = action.payload;
    },
    setContract(state, action) {
      state.contract = action.payload;
    },
    setVehicle(state, action) {
      state.vehicle = action.payload;
    },
    addToVehicle(state, action) {
      state.vehicle = { ...state.vehicle, ...action.payload };
    },
    setUpdateVehicle(state, action) {
      state.updateVehicle = action.payload;
    },
    setInsurer(state, action) {
      state.insurer = action.payload;
    },
    addToInsurer(state, action) {
      state.insurer = { ...state.insurer, ...action.payload };
    },
    setUpdateInsurer(state, action) {
      state.updateInsurer = action.payload;
    },
    setOwner(state, action) {
      state.owner = action.payload;
    },
    addToOwner(state, action) {
      state.owner = { ...state.owner, ...action.payload };
    },
    setUpdateOwner(state, action) {
      state.updateOwner = action.payload;
    },
    setOwnerCopy(state, action) {
      state.ownerCopy = action.payload;
    },
    //
    setDrivers(state, action) {
      state.drivers = action.payload;
    },
    setUpdateDrivers(state, action) {
      state.updateDrivers = action.payload;
    },
    replaceDriver(state, action) {
      state.drivers[action.payload.index] = action.payload.driver;
    },
    addDriver(state, action) {
      state.drivers.push(action.payload);
    },
    removeDriver(state, action) {
      state.drivers.splice(action.payload, 1);
    },
    clearDrivers(state, _) {
      state.drivers.splice(0, state.drivers.length);
    },
    setInsurerIsOwner(state, action) {
      state.insurerIsOwner = action.payload;
    },
    setInsurerIsClient(state, action) {
      state.insurerIsClient = action.payload;
    },
    setOwnerIsClient(state, action) {
      state.ownerIsClient = action.payload;
    },
    /// Действия со страховыми компаниями
    setCompanies(state, action) {
      state.companies = action.payload;
    },
    setCompany(state, action) {
      state.companies[action.payload.id] = {
        ...state.companies[action.payload.id],
        ...action.payload.company
      };
    }
  }
});

export default slice.reducer;

export function setClient(client) {
  return async (dispatch) => {
    dispatch(
      slice.actions.setClient({
        ...client,
        ...(client?.userId && { id: client.userId })
      })
    );
  };
}

export function saveGeneral({ general, contractId }) {
  return async (dispatch) => {
    const { eosago, contract } = await storeEosagoGeneral({
      ...general,
      ...(!contractId && { createContract: true })
    });
    console.log({ eosago, contract });
    dispatch(slice.actions.setGeneral(eosago));
    if (contractId) {
      const { contract: updatedContract } = await storeEosagoContract({
        contractId,
        eosagoId: eosago.id
      });

      dispatch(slice.actions.setContract(updatedContract));
      return;
    }
    dispatch(slice.actions.setContract(contract));
  };
}

export function saveClient(client) {
  return async (dispatch) => {
    if (!client.id) {
      const { contact } = await createClient({
        phone: client.phone,
        email: client.email
      });
      console.log(contact);
      dispatch(slice.actions.setClient(contact));
      return;
    }
    const { user } = await updateUser(client.id, client);
    dispatch(slice.actions.setClient(user));
  };
}

export function saveVehicle({ vehicle, contractId, modelInfo = {} }) {
  return async (dispatch) => {
    const { vehicle: vehicleResult, contract } = await storeVehicle({
      ...vehicle,
      ...(!contractId && { createContract: true })
    });
    console.log({ vehicleResult, contract });
    dispatch(
      slice.actions.setVehicle({
        ...vehicleResult,
        ...modelInfo
      })
    );

    if (contractId) {
      const { contract: updatedContract } = await storeEosagoContract({
        contractId,
        vehicleId: vehicleResult.id
      });
      dispatch(slice.actions.setContract(updatedContract));
      return;
    }
    dispatch(slice.actions.setContract(contract));
  };
}

export function setInsurerIsOwner(value = true) {
  return (dispatch) => {
    dispatch(slice.actions.setInsurerIsOwner(value));
  };
}

export function setInsurerIsClient(value = true) {
  return (dispatch) => {
    dispatch(slice.actions.setInsurerIsClient(value));
    dispatch(slice.actions.setOwnerIsClient(!value));
  };
}

export function savePerson({ person, contractId }) {
  return async (dispatch) => {
    const { person: personResult, contract } = await storePerson({
      ...person,
      ...(!contractId && { createContract: true })
    });
    console.log({ personResult, contract });
    if (person.type === 'INSURER') {
      dispatch(slice.actions.setInsurer(personResult));
    } else {
      dispatch(slice.actions.setOwner(personResult));
    }
    if (contractId) {
      const { contract: updatedContract } = await storeEosagoContract({
        contractId,
        ...(person.type === 'INSURER' && { insurerId: personResult.id }),
        ...(person.type === 'OWNER' && { ownerId: personResult.id })
      });
      dispatch(slice.actions.setContract(updatedContract));
      return;
    }
    dispatch(slice.actions.setContract(contract));
  };
}

export function newEmptyDriver() {
  return async (dispatch) => {
    const driver = {
      genderId: '1',
      firstName: '',
      middleName: '',
      lastName: '',
      birthdate: '',
      expDate: '',
      licenseDate: '',
      licenseSerial: '',
      licenseNumber: '',
      licenseForeign: '0',

      prevLicenseSerial: '',
      prevLicenseNumber: '',
      prevLicenseDate: '',
      prevLicenseFirstname: '',
      prevLicenseMiddlename: '',
      prevLicenseLastname: ''
    };
    dispatch(slice.actions.addDriver(driver));
  };
}

export function removeDriver(index, id) {
  return async (dispatch) => {
    if (id) await deleteDriver(id);
    dispatch(slice.actions.removeDriver(index));
  };
}

export function saveDriver({ driver, index, contractId, driversArray = [] }) {
  return async (dispatch) => {
    const { driver: driverResult, contract } = await storeDriver({
      ...driver,
      ...(!contractId && { createContract: true })
    });

    console.log({ driverResult, contract });

    dispatch(
      slice.actions.replaceDriver({
        index,
        driver: driverResult
      })
    );

    if (contractId) {
      const { contract: updatedContract } = await storeEosagoContract({
        contractId,
        driversId: [
          ...driversArray.filter((d) => d.id).map((d) => d.id),
          driverResult.id
        ]
      });
      dispatch(slice.actions.setContract(updatedContract));
      return;
    }
    const { contract: updatedContract } = await storeEosagoContract({
      contractId: contract.id,
      driversId: [driverResult.id]
    });
    dispatch(slice.actions.setContract(updatedContract));
  };
}

export function searchContract({
  eosagoId,
  vehicleId,
  insurerId,
  ownerId,
  clientId,
  ...contractIdentifiers
}) {
  return async (dispatch) => {
    console.log('IN DISPATCH');
    if (clientId) {
      // TODO: Стоит ли менять клиента на найденного?
      // const { user: client = null } = (await getUser(clientId)) || {};
      // dispatch(slice.actions.setClient(client));
    }
    console.log({
      eosagoId,
      vehicleId,
      insurerId,
      ownerId,
      clientId,
      ...contractIdentifiers
    });
    if (eosagoId) {
      const { eosago } = await getEosagoGeneral(eosagoId);
      console.log('EOSAGO');
      eosago.prevPolicySerial = {
        title: eosago.prevPolicySerial,
        value: eosago.prevPolicySerial
      };
      console.log(eosago);
      dispatch(slice.actions.setUpdateGeneral(eosago));
    }

    if (vehicleId) {
      console.log('SEARCH VEHICLE');
      const { vehicle } = await getVehicle(vehicleId);
      console.log('VEHICLE');
      const { modelName = null, brandId = null, brandName = null } =
        (await getModelById(vehicle.modelId)) || {};
      console.log('MODEL');
      vehicle.brand = { title: brandName, value: brandId };
      vehicle.model = { title: modelName, value: vehicle.modelId };
      vehicle.identifiersType = `${vehicle.identifiersType}`;
      vehicle.docType = `${vehicle.docType}`;
      vehicle.type = `${vehicle.type}`;
      dispatch(slice.actions.setUpdateVehicle(vehicle));
    }

    if (insurerId) {
      const { person: insurer = undefined } = await getPerson(insurerId);
      console.log('INSURER');
      insurer.genderId = `${insurer.genderId}`;
      insurer.passportForeign = `${insurer.passportForeign}`;
      insurer.passportPoint = {
        title: insurer.passportPoint,
        value: insurer.passportPoint
      };
      if (insurer.dadata && insurer.dadata !== '') {
        insurer.address = {
          title: JSON.parse(insurer.dadata).value,
          value: JSON.parse(insurer.dadata)
        };
      }
      dispatch(slice.actions.setUpdateInsurer(insurer));
    }

    if (ownerId) {
      const { person: owner } = await getPerson(ownerId);
      console.log('OWNER');
      owner.genderId = `${owner.genderId}`;
      owner.passportForeign = `${owner.passportForeign}`;
      owner.passportPoint = {
        title: owner.passportPoint,
        value: owner.passportPoint
      };
      if (owner.dadata && owner.dadata !== '') {
        owner.address = {
          title: JSON.parse(owner.dadata).value,
          value: JSON.parse(owner.dadata)
        };
      }

      dispatch(slice.actions.setUpdateOwner(owner));
    }

    if (!contractIdentifiers.driversId) return;
    const driversId = Array.isArray(contractIdentifiers.driversId)
      ? contractIdentifiers.driversId
      : JSON.parse(contractIdentifiers.driversId);
    if (driversId.length > 0) {
      const driversResult = await Promise.all(
        driversId.map(async (id) => {
          const { driver } = await getDriver(id);
          console.log(`DRIVER ${id}`);
          return driver;
        })
      );

      dispatch(slice.actions.clearDrivers());
      for (let i = 0; i < driversResult.length - 2; i += 1) {
        driversResult.forEach((_) => dispatch(newEmptyDriver()));
      }
      // driversResult.forEach((_) => dispatch(newEmptyDriver()));
      dispatch(slice.actions.setUpdateDrivers(driversResult));
    }
  };
}

export function selectVehicle(vehicle) {
  return async (dispatch) => {
    if (vehicle.id) {
      const { modelName = null, brandName = null } =
        (await getModelById(vehicle.modelId)) || {};
      vehicle.model = {
        title: `${brandName} ${modelName}`,
        value: vehicle.modelId
      };
      vehicle.identifiersType = `${vehicle.identifiersType}`;
      vehicle.docType = `${vehicle.docType}`;
      vehicle.type = `${vehicle.type}`;
      dispatch(slice.actions.setUpdateVehicle(vehicle));
    }
  };
}

export function getInsuranceCompanies() {
  return async (dispatch) => {
    const { results = [] } = await getCompaniesList();
    dispatch(
      slice.actions.setCompanies(
        results.map((result) => ({
          id: result.id,
          name: result.name,
          status: 'wait',
          message: 'Ожидает ответа'
        }))
      )
    );
  };
}

export function updateCompany(result = {}) {
  return async (dispatch) => {
    const { companyId, error, results } = JSON.parse(result);
    if (results) {
      const { id, eId, total, comission } = results;
      dispatch(
        slice.actions.setCompany({
          id: companyId,
          company: {
            status: 'accepted',
            message: 'Получено предложение',
            offerId: id,
            eId,
            total,
            comission
          }
        })
      );
      return;
    }
    if (error) {
      dispatch(
        slice.actions.setCompany({
          id: companyId,
          company: {
            status: 'denied',
            message: error ?? 'Предложений от компании не получено'
          }
        })
      );
      // return;
    }
  };
}

export function copyOwner(values) {
  return (dispatch) => {
    dispatch(slice.actions.setOwnerCopy(values));
  };
}
export function updateOwner(values) {
  return (dispatch) => {
    dispatch(slice.actions.setUpdateOwner(values));
  };
}
export function updateInsurer(values) {
  return (dispatch) => {
    dispatch(slice.actions.setUpdateInsurer(values));
  };
}

export function editContract(contract = {}) {
  return async (dispatch) => {
    const {
      eosago = null,
      client = null,
      vehicle = null,
      insurer = null,
      owner = null,
      drivers = []
    } = contract;

    console.log({ contract, client });

    dispatch(slice.actions.setIsEdit(true));

    dispatch(
      slice.actions.setContract({
        id: contract.id,
        eosagoId: eosago?.id ?? null,
        clientId: client?.id ?? null,
        vehicleId: vehicle?.id ?? null,
        insurerId: insurer?.id ?? null,
        ownerId: owner?.id ?? null,
        driversId: `[${drivers.map((d) => d.id)}]`
      })
    );
    if (client) {
      dispatch(slice.actions.setClient(client));
    }
    // if (eosago) {
    dispatch(
      slice.actions.addToGeneral({
        ...eosago,
        clientPhone: toPhoneMask(client?.phone ?? ''),
        clientEmail: client?.email ?? '',
        ...(eosago?.prevPolicySerial
          ? {
              prevPolicySerial: {
                title: eosago.prevPolicySerial,
                value: eosago.prevPolicySerial
              }
            }
          : { prevPolicySerial: null })
      })
    );
    // }
    // if (vehicle) {
    dispatch(
      slice.actions.addToVehicle({
        ...vehicle,
        ...(vehicle && {
          brand: { title: vehicle?.brand, value: vehicle?.brand },
          model: { title: vehicle?.model, value: vehicle?.modelId },
          identifiersType: `${vehicle?.identifiersType}`,
          docType: `${vehicle?.docType}`,
          type: `${vehicle?.type}`
        })
      })
    );
    // }
    // if (insurer) {
    dispatch(
      slice.actions.addToInsurer({
        ...insurer,
        ...(insurer && {
          genderId: `${insurer?.genderId}`,
          passportForeign: `${insurer?.passportForeign}`,
          passportPoint: {
            title: insurer?.passportPoint,
            value: insurer?.passportPoint
          },
          address: {
            title: JSON.parse(insurer?.dadata).value,
            value: JSON.parse(insurer?.dadata)
          }
        })
      })
    );
    // }

    if (owner && owner.id !== insurer.id) {
      dispatch(slice.actions.setInsurerIsOwner(false));
    }
    dispatch(
      slice.actions.addToOwner({
        ...owner,
        ...(owner && {
          genderId: `${owner?.genderId}`,
          passportForeign: `${owner?.passportForeign}`,
          passportPoint: {
            title: owner?.passportPoint,
            value: owner?.passportPoint
          },
          address: {
            title: JSON.parse(owner?.dadata).value,
            value: JSON.parse(owner?.dadata)
          }
        })
      })
    );
    if (drivers.length > 0) {
      dispatch(slice.actions.setDrivers(drivers));
    }
  };
}

export function clearContract() {
  return (dispatch) => {
    dispatch(slice.actions.toInitial());
    // dispatch(slice.actions.set)
  };
}

export function clearContractFull() {
  return (dispatch) => {
    dispatch(clearContract());
    dispatch(
      slice.actions.setUpdateGeneral({ ...initialState.general, dateEnd: '' })
    );
    dispatch(slice.actions.setUpdateVehicle(initialState.vehicle));
    dispatch(slice.actions.setUpdateInsurer(initialState.insurer));
    dispatch(slice.actions.setUpdateOwner(initialState.owner));
    dispatch(slice.actions.setUpdateDrivers(initialState.drivers));
  };
}
