import PropTypes from 'prop-types';
import {
  CircularProgress,
  IconButton,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  Typography,
  useTheme
} from '@material-ui/core';
import { useState, useEffect } from 'react';
import { useSelector } from 'react-redux';
import Label from 'components/Label';
import MoreVertIcon from '@material-ui/icons/MoreVert';
import { fDate } from 'utils/formatTime';
import Scrollbar from 'components/Scrollbar';

import ActionMenu from './actions/ActionMenu';

const getStatusColor = (status) => {
  const colors = {
    1: 'info',
    2: 'warning',
    3: 'success'
  };
  return colors[status];
};

Row.propTypes = {
  contract: PropTypes.object
};

function Row({ contract }) {
  const theme = useTheme();
  const [anchorEl, setAnchorEl] = useState(null);

  const { id, createdAt, client, vehicle, offer, status } = contract;

  return (
    <TableRow
      onClick={() => {
        // TODO: Добавить действие для щелчка на строку?
      }}
    >
      <TableCell>{fDate(createdAt)}</TableCell>
      <TableCell>
        <Typography variant="subtitle2">
          {`${client?.lastName ?? ''} ${client?.firstName ?? ''} ${
            client?.middleName ?? ''
          }`.trim()}
        </Typography>
      </TableCell>
      <TableCell>
        {vehicle ? (
          <>
            <Typography variant="body2">{vehicle.brand ?? ''}</Typography>
            <Typography variant="body2">{vehicle.model ?? ''}</Typography>
          </>
        ) : (
          'Не заполнено'
        )}
      </TableCell>
      <TableCell>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color="warning"
        >
          {vehicle ? vehicle.numberPlate : 'Не заполнено'}
        </Label>
      </TableCell>
      <TableCell align="center">
        {offer ? 'OFFER' : <Typography variant="subtitle1">-</Typography>}
      </TableCell>
      <TableCell>
        <Label
          variant={theme.palette.mode === 'light' ? 'ghost' : 'filled'}
          color={getStatusColor(status?.id)}
        >
          {status?.name}
        </Label>
      </TableCell>
      <TableCell>{offer && 'Коммисия'}</TableCell>
      <TableCell>
        <IconButton
          aria-label="more"
          aria-controls="long-menu"
          aria-haspopup="true"
          onClick={(event) => {
            setAnchorEl(event.currentTarget);
          }}
        >
          <MoreVertIcon />
        </IconButton>
        <ActionMenu
          anchorEl={anchorEl}
          setAnchorEl={setAnchorEl}
          status={status?.id}
          contractId={id}
          contract={contract}
        />
      </TableCell>
    </TableRow>
  );
}

export default function CalculationsTable() {
  const { isLoad, contracts } = useSelector((state) => state.contracts);
  const [page, setPage] = useState(0);
  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  useEffect(() => {
    setPage(0);
  }, [contracts]);

  return (
    <>
      <Scrollbar>
        <TableContainer sx={{ minWidth: 800 }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell>Дата</TableCell>
                <TableCell>Контакт</TableCell>
                <TableCell>Авто</TableCell>
                <TableCell>Гос. Номер.</TableCell>
                <TableCell>Компания</TableCell>
                <TableCell>Статус</TableCell>
                <TableCell>Вознаграждение</TableCell>
                <TableCell />
              </TableRow>
            </TableHead>
            {isLoad ? (
              <TableBody>
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    <CircularProgress />
                  </TableCell>
                </TableRow>
              </TableBody>
            ) : (
              <TableBody>
                {contracts.length > 0 ? (
                  contracts
                    .slice(page * 20, page * 20 + 20)
                    .map((contract) => (
                      <Row key={contract.id} contract={contract} />
                    ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={7} align="center">
                      Контракты отсутствуют
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            )}
          </Table>
        </TableContainer>
      </Scrollbar>

      <TablePagination
        rowsPerPageOptions={[20]}
        component="div"
        labelRowsPerPage="Строк на странице"
        labelDisplayedRows={({ from, to, count }) =>
          `${from}-${to} из ${count !== -1 ? count : `больше чем ${to}`}`
        }
        count={contracts.length}
        rowsPerPage={20}
        page={page}
        onPageChange={handleChangePage}
        // page={page}
        // onPageChange={handleChangePage}
        // onRowsPerPageChange={handleChangeRowsPerPage}
      />
    </>
  );
}
