import { createSlice } from '@reduxjs/toolkit';
import { updateUser } from 'requests/users-requests';
import axios from 'utils/axios';

const initialState = {
  users: [],
  page: 1,
  allCount: 0,
  offset: 20,
  editUser: null
};

const slice = createSlice({
  name: 'users',
  initialState,
  reducers: {
    getUsersSuccess(state, action) {
      state.users = action.payload;
    },
    setAllCount(state, action) {
      state.allCount = action.payload;
    },
    setUsersPage(state, action) {
      state.page = action.payload;
    },
    setUsersOffset(state, action) {
      state.page = 1;
      state.offset = action.payload;
    },
    setEditUser(state, action) {
      state.editUser = action.payload;
    },
    setEntity(state, action) {
      state.editUser.entity = action.payload;
    }
  }
});

export default slice.reducer;

export const { setUsersPage, setUsersOffset, setEntity } = slice.actions;
// ----------------------------------------------------------------------

export function getUsers({ offset, page, search }) {
  return async (dispatch) => {
    const response = await axios.get('api/v1/users', {
      params: {
        except: 'client',
        offset,
        page,
        search
      }
    });

    console.log(response.data);

    const { data = [], total = 0 } = response.data?.Users;

    dispatch(slice.actions.getUsersSuccess(data));
    dispatch(slice.actions.setAllCount(total));
  };
}

export function setEditUser(id) {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/users/${id}`);
    const { user } = response.data;
    dispatch(slice.actions.setEditUser(user));
  };
}

export function updateEditUser({ userId, data }) {
  return async (dispatch) => {
    if (Object.values(data).length === 0) return;

    const { user } = await updateUser(userId, data);
    dispatch(slice.actions.setEditUser(user));
    // dispatch(setEditUser(userId));
  };
}
