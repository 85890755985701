import PropTypes from 'prop-types';
// material
import {
  Dialog,
  DialogContent,
  DialogActions,
  Box,
  Typography
} from '@material-ui/core';
import { MButton as Button } from './@material-extend';

// ----------------------------------------------------------------------

ConfirmDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  confirmAction: PropTypes.func,
  confirmButtonText: PropTypes.string,
  refuseAction: PropTypes.func,
  onClose: PropTypes.func,
  refuseButtonText: PropTypes.string
};
export default function ConfirmDialog({
  open,
  onClose,
  message,
  confirmAction,
  confirmButtonText,
  refuseAction,
  refuseButtonText
}) {
  return (
    <Dialog open={open} onClose={onClose}>
      <DialogContent>
        <Box height={150} width={400} display="flex" justifyContent="center">
          <Typography variant="h5">{message}</Typography>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={refuseAction}
          disableElevation
          variant="contained"
          color="primary"
          size="large"
        >
          {confirmButtonText ?? 'Отмена'}
        </Button>
        <Button
          onClick={confirmAction}
          variant="outlined"
          color="primary"
          size="large"
        >
          {refuseButtonText ?? 'Подтвердить'}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
