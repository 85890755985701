import { useState } from 'react';
import PropTypes from 'prop-types';
import { useFormikContext, FieldArray } from 'formik';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Grid,
  useMediaQuery,
  FormControlLabel,
  Typography,
  Checkbox,
  Button
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
//
import { FormBlock } from 'components/personal/form-components';
import Driver from './Driver';

// ----------------------------------------------------------------------

DriversBlock.propTypes = {
  odd: PropTypes.bool
};

export default function DriversBlock({ odd }) {
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));
  const [isNotRestricted, setNotRestricted] = useState(false);

  const {
    values: { drivers },
    setFieldValue
  } = useFormikContext();

  const handleRestrictionChange = (event) => {
    setNotRestricted(event.target.checked);
    for (let i = 0; i < drivers.length; i += 1)
      setFieldValue(
        `drivers[${i}].driversWithoutRestrictions`,
        event.target.checked
      );
  };

  return (
    <FormBlock
      odd={odd}
      title={
        <Grid container spacing={4} justifyContent="center" alignItems="center">
          <Grid item>
            <Typography variant="h4" align="center">
              Водители
            </Typography>
          </Grid>
          <Grid item>
            <FormControlLabel
              control={
                <Checkbox
                  // {...getFieldProps('driversWithoutRestrictions')}
                  value={isNotRestricted}
                  onChange={handleRestrictionChange}
                  color="primary"
                />
              }
              label="Без ограничений"
            />
          </Grid>
        </Grid>
      }
    >
      {!isNotRestricted ? (
        <FieldArray name="drivers">
          {({ remove, push }) => (
            <>
              {drivers.length > 0 &&
                drivers.map((driver, index, array) => (
                  // <Box
                  //   key={`driver_${index}`}
                  //   sx={{ mt: { xs: index > 0 ? 3 : 0 } }}
                  // >
                  <Driver
                    key={`driver_${index}`}
                    driver={driver}
                    index={index}
                    array={array}
                    remove={remove}
                  />
                  // </Box>
                ))}
              <Grid item xs={isDesktop ? 9 : 6} />
              <Grid item xs={isDesktop ? 3 : 6}>
                {drivers.length < 5 ? (
                  <Button
                    fullWidth
                    // size="large"
                    size={isDesktop ? 'large' : 'small'}
                    variant="contained"
                    startIcon={<AddIcon />}
                    onClick={() => push({ isPreviousDriverLicense: false })}
                  >
                    Добавить водителя
                  </Button>
                ) : undefined}
              </Grid>
            </>
          )}
        </FieldArray>
      ) : undefined}
    </FormBlock>
  );
}
