import { useState, useCallback } from 'react';

export default function useTimer({ interval, timeout }) {
  const [time, setTime] = useState(0);

  const startTimer = useCallback(() => {
    setTime(timeout);
    const timer = setInterval(() => {
      setTime((time) => time - interval);
    }, interval);
    setTimeout(() => {
      clearInterval(timer);
    }, timeout);
  }, [setTime, interval, timeout]);

  return [time / interval, startTimer];
}
