import jwtDecode from 'jwt-decode';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  isAuthenticated: false,
  user: {}
};

const slice = createSlice({
  name: 'authJwt',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // INITIALISE
    getInitialize(state, action) {
      state.isLoading = false;
      state.isAuthenticated = action.payload.isAuthenticated;
      state.user = action.payload.user;
    },

    // LOGIN
    loginSuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // REGISTER
    // registerSuccess(state, action) {
    //   state.isAuthenticated = true;
    //   state.user = action.payload.user;
    // },

    // VERIFY
    verifySuccess(state, action) {
      state.isAuthenticated = true;
      state.user = action.payload.user;
    },

    // LOGOUT
    logoutSuccess(state) {
      state.isAuthenticated = false;
      state.user = null;
    },

    testChangeEntity(state, action) {
      state.user.entity = action.payload;
    }
  }
});

// Reducer
export default slice.reducer;

export const { testChangeEntity } = slice.actions;

// ----------------------------------------------------------------------

const isValidToken = (accessToken) => {
  if (!accessToken) {
    return false;
  }
  const decoded = jwtDecode(accessToken);
  const currentTime = Date.now() / 1000;

  return decoded.exp > currentTime;
};

const setSession = (accessToken, refreshToken) => {
  if (accessToken) {
    localStorage.setItem('accessToken', accessToken);
    localStorage.setItem('refreshToken', refreshToken);
    axios.defaults.headers.common.Authorization = `Bearer ${accessToken}`;
  } else {
    localStorage.removeItem('accessToken');
    localStorage.removeItem('refreshToken');
    delete axios.defaults.headers.common.Authorization;
  }
};

// ----------------------------------------------------------------------

export function login({ email, password }) {
  return async (dispatch) => {
    setSession(null, null);
    const response = await axios.post(`api/v1/auth/login`, {
      email,
      password
    });
    const { accessToken, refreshToken } = response.data;
    setSession(accessToken, refreshToken);
    const userResponse = await axios.get(`api/v1/auth/who_i_am`);
    const { user } = userResponse.data;
    dispatch(slice.actions.loginSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function register({ email, phone, firstName, password }) {
  return async (_) => {
    await axios.post(`api/v1/auth/register`, {
      agent: true,
      email,
      phone,
      firstName,
      ...(password && { password })
    });
  };
}

// ----------------------------------------------------------------------

export function sendVerifyCode({ phone }) {
  return async () => {
    await axios.post(`api/v1/auth/login`, {
      phone
    });
  };
}

export function verifyPhone({ phone, smsCode }) {
  return async (dispatch) => {
    try {
      const response = await axios.post(`api/v1/auth/login`, {
        phone,
        smsCode
      });
      const { accessToken = null, refreshToken } = response;
      if (accessToken) {
        setSession(accessToken, refreshToken);

        const userResponse = await axios.get(`api/v1/auth/who_i_am`);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: userResponse.data
          })
        );
      }
    } catch (exc) {
      console.log(exc);
    }

    // dispatch(slice.actions.loginSuccess({ user }));
  };
}

// ----------------------------------------------------------------------

export function logout() {
  return async (dispatch) => {
    await axios.post(`api/v1/auth/logout`);
    setSession(null, null);
    dispatch(slice.actions.logoutSuccess());
  };
}

// ----------------------------------------------------------------------

export function updateProfile({
  userId,
  // general
  firstName,
  middleName,
  lastName,
  birthdate,
  address,
  company,
  phone,
  email,
  // requisits
  bankName,
  cardNumber,
  entityName,
  inn,
  kpp,
  ogrn,
  entityAddress,
  settlementAccount,
  bik,
  correspondentAccount,
  // password
  password
}) {
  return async (dispatch) => {
    // dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');

      if (accessToken && isValidToken(accessToken)) {
        const body = {
          // general
          ...(firstName && { firstName }),
          ...(middleName && { middleName }),
          ...(lastName && { lastName }),
          ...(birthdate && { birthdate }),
          ...(address && { address }),
          ...(company && { company }),
          ...(phone && { phone }),
          ...(email && { email }),

          // requisits
          ...(bankName && { bankName }),
          ...(cardNumber && { cardNumber }),
          ...(entityName && { entityName }),
          ...(inn && { inn }),
          ...(kpp && { kpp }),
          ...(ogrn && { ogrn }),
          ...(entityAddress && { entityAddress }),
          ...(settlementAccount && { settlementAccount }),
          ...(bik && { bik }),
          ...(correspondentAccount && { correspondentAccount }),

          // password
          ...(password && { password })
        };
        await axios.put(`api/v1/users/${userId}`, body);
        getInitialize();
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (exc) {
      console.log(exc);
    }
  };
}

export function checkPassword({ password }) {
  return async (_) => {
    await axios.post('api/v1/auth/check_password', {
      password
    });
  };
}

export function getInitialize() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const accessToken = window.localStorage.getItem('accessToken');
      const refreshToken = window.localStorage.getItem('refreshToken');

      // TODO: isValidToken может мешать. пока его убрал
      if (accessToken && refreshToken /* && isValidToken(accessToken) */) {
        setSession(accessToken, refreshToken);
        const response = await axios.get(`api/v1/auth/who_i_am`);
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: true,
            user: response.data.user
          })
        );
      } else {
        dispatch(
          slice.actions.getInitialize({
            isAuthenticated: false,
            user: null
          })
        );
      }
    } catch (error) {
      console.error(error);
      dispatch(
        slice.actions.getInitialize({
          isAuthenticated: false,
          user: null
        })
      );
    }
  };
}
