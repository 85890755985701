// routes
import {
  PATH_CONTROL,
  PATH_DIAGNOSTIC_CARDS,
  PATH_DOCUMENTS,
  PATH_OSAGO,
  PATH_CONTACTS,
  PATH_USERS
} from 'routes/paths';
// components
import SvgIconStyle from 'components/SvgIconStyle';
import { showKBMDialog } from 'redux/slices/osago/kbm';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle
    src={`/static/icons/${name}.svg`}
    sx={{ width: 22, height: 22 }}
  />
);

const ICONS = {
  control: getIcon('/navbar/ic_dashboard'),
  clients: getIcon('/navbar/ic_user'),
  osago: getIcon('shield'),
  inspection: getIcon('love-camera'),
  documents: getIcon('love-camera'),
  checkKBM: getIcon('shield')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    // subheader: 'general',
    items: [
      {
        title: 'Управление',
        icon: ICONS.control,
        href: PATH_USERS.root,
        items: [
          {
            title: 'Пользователи',
            href: PATH_USERS.root
          }
        ]
      },
      {
        title: 'Контакты',
        icon: ICONS.clients,
        href: PATH_CONTACTS.root
      },
      {
        title: 'Осаго',
        href: PATH_OSAGO.root,
        icon: ICONS.osago,
        items: [
          {
            title: 'Создать полис',
            href: `${PATH_OSAGO.root}/polis/new_polis`
          },
          {
            title: 'Проверить КБМ',
            icon: ICONS.checkKBM,
            href: PATH_CONTROL.root,
            isButton: true,
            buttonAction: (dispatch) => {
              dispatch(showKBMDialog());
            }
          },
          {
            title: 'Пролонгация',
            href: PATH_OSAGO.prolongation
          },
          {
            title: 'Журнал пролонгации',
            href: PATH_OSAGO.prolongationJournal
          },
          {
            title: 'Калькулятор',
            href: PATH_OSAGO.calculator
          },
          {
            title: 'Расчеты',
            href: PATH_OSAGO.calculations
          }
        ]
      },
      {
        title: 'Диагностические карты',
        icon: ICONS.inspection,
        href: PATH_DIAGNOSTIC_CARDS.root
        // items: []
      },
      {
        title: 'Документы',
        icon: ICONS.documents,
        href: PATH_DOCUMENTS.root,
        items: []
      }
      // {
      //   title: 'Управление',
      //   icon: ICONS.control,
      //   href: PATH_CONTROL.root
      //   // items: []
      // }
    ]
  }
];

export default sidebarConfig;
