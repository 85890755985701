import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { useState, useEffect } from 'react';
import ruLocale from 'date-fns/locale/ru';
import { Icon } from '@iconify/react';
import calendarFill from '@iconify/icons-eva/calendar-fill';
// material
import AdapterDateFns from '@material-ui/lab/AdapterDateFns';
import DatePicker from '@material-ui/lab/DatePicker';
import LocalizationProvider from '@material-ui/lab/LocalizationProvider';
import { InputAdornment } from '@material-ui/core';
//
import { MTextField as TextField } from 'components/@material-extend';
import { stringToDate } from 'utils/textTransformation';

// ----------------------------------------------------------------------

LocalizedDatePicker.propTypes = {
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  onChange: PropTypes.func,
  required: PropTypes.bool
};

export default function LocalizedDatePicker({
  onFocus,
  onBlur,
  onChange,
  required,
  ...props
}) {
  const { setFieldValue } = useFormikContext();
  const [field, meta, helpers] = useField(props);
  const [value, setValue] = useState(field.value);

  const handleChange = (val) => {
    setValue(val);
    if (onChange) onChange(val);
  };
  const handleBlur = () => {
    setFieldValue(field.name, value);
    if (onBlur) onBlur(value);
  };
  const handleFocus = () => {
    helpers.setTouched(true);
    if (onFocus) onFocus(value);
  };

  useEffect(() => {
    if (field.value) {
      if (typeof field.value === 'string') {
        setValue(stringToDate(field.value) ?? '');
        return;
      }
      setValue(field.value);
    }
  }, [field.value]);

  return (
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={ruLocale}>
      <DatePicker
        disableOpenPicker
        // {...field}
        {...props}
        mask="__.__.____"
        value={value}
        onChange={handleChange}
        renderInput={(params) => (
          <TextField
            {...params}
            onBlur={handleBlur}
            onFocus={handleFocus}
            complete={Boolean(value)}
            error={Boolean(meta.touched && meta.error)}
            required={required}
            helperText={meta.touched && meta.error}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Icon icon={calendarFill} width={24} height={24} />
                </InputAdornment>
              )
            }}
          />
        )}
      />
    </LocalizationProvider>
  );
}
