export function isDateValid(date) {
  if (Object.prototype.toString.call(date) === '[object Date]') {
    // it is a date
    if (Number.isNaN(date.getTime())) {
      // d.valueOf() could also work
      // date is not valid
      return false;
    }
    return true;
    // date is valid
  }
  return false;
  // not a date
}
