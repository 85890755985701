import { useEffect, useState } from 'react';
// material
import {
  Dialog,
  DialogContent,
  Typography,
  Box,
  Link
} from '@material-ui/core';
//
import { VerifyCodeForm } from 'components/authentication/verify-code';
import useAuth from 'hooks/useAuth';
import useTimer from 'hooks/useTimer';

export default function VerificationDialog() {
  const { user, sendVerifyCode } = useAuth();
  const [open, setOpen] = useState(false);

  const [timer, startTimer] = useTimer({ interval: 1000, timeout: 60 * 1000 });

  useEffect(() => {
    if (open) startTimer();
    return () => {};
  }, [startTimer, open]);

  const resendAction = async () => {
    if (timer > 0) return;
    try {
      await sendVerifyCode({
        phone: user.phone
      });
      startTimer();
    } catch (error) {
      console.error(error);
    }
  };

  const handleClose = () => {
    setOpen(false);
  };

  const closeDialog = () => {
    setOpen(false);
  };

  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogContent>
        <Typography variant="h3" gutterBottom>
          Пожалуйста проверьте свои SMS!
        </Typography>
        <Typography sx={{ color: 'text.secondary' }}>
          На указанный вами номер был выслан 6-значный код подтверждения.
          Пожалуйста введите этот код ниже, для подтверждения своего номера
          телефона.
        </Typography>

        <Box sx={{ mt: 5, mb: 3 }}>
          <VerifyCodeForm closeDialog={closeDialog} user={user} />
        </Box>

        <Typography variant="body2" align="center">
          Код не пришел? &nbsp;
          {timer > 0 ? (
            <>{`Повторная отправка возможна через ${timer}`}</>
          ) : (
            <Link variant="subtitle2" underline="none" onClick={resendAction}>
              Отправить код повторно
            </Link>
          )}
        </Typography>
      </DialogContent>
    </Dialog>
  );
}
