import { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
// material
import { MDatePicker as DatePicker } from 'components/@material-extend';
//
import { isDateValid } from 'utils/validations';
import { setDateToFilter } from 'redux/slices/osago/contracts';
import { TextField } from '@material-ui/core';

// ----------------------------------------------------------------------

const MAX_DATE = new Date(2100, 0, 1);

export default function DateToFilter() {
  const { dateFrom, dateTo } = useSelector((state) => state.contracts.filters);
  const dispatch = useDispatch();
  const [date, setDate] = useState(dateTo);

  const handleChange = (value) => {
    setDate(value);
  };

  const handleBlur = () => {
    if (
      isDateValid(date) /* && date - dateFrom >= 0 */ &&
      MAX_DATE - date >= 0
    ) {
      dispatch(setDateToFilter(date));
    }
  };
  return (
    <DatePicker
      value={date}
      onChange={handleChange}
      minDate={dateFrom}
      maxDate={MAX_DATE}
      renderInput={(params) => (
        <TextField {...params} helperText="" label="До" onBlur={handleBlur} />
      )}
    />
  );
}
