import * as Yup from 'yup';
import { phoneRegex } from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const accountGeneralSchema = Yup.object().shape({
  firstName: Yup.string(),
  middleName: Yup.string(),
  lastName: Yup.string(),
  birthdate: Yup.date('Дата некорректна').min(
    MIN_VALIDATION_DATE,
    'Дата некорректна'
  ),
  phone: Yup.string().matches(phoneRegex, 'Формат номера +7 (999) 999-99-99'),
  company: Yup.string(),
  address: Yup.object().shape({
    title: Yup.string(),
    value: Yup.string()
  }),
  email: Yup.string().email('Введите действующий e-mail')
});

export default accountGeneralSchema;
