const POLIS_START_MIN_VALIDATION_DATE = new Date(
  new Date().setDate(new Date().getDate() + 4)
);
const newPolisInitial = {
  // Общая информация
  clientPhone: '',
  clientEmail: '',
  targetOfUse: '1',
  clientSelect: {
    title: 'Создать нового',
    value: '-1'
  },
  insuranceTerm: 12,
  datePolisStart: POLIS_START_MIN_VALIDATION_DATE,
  datePolisEnd: new Date(),
  withTrailer: false,
  previousPolisSerial: null,
  previousPolisNumber: '',
  // Следующие две опции на данный момент недоступны
  vehicleForeignRegistration: false,
  vehicleGoesToRegistration: false,

  // Блок Клиент
  clientIsInsurer: true,
  clientFirstName: '',
  noMiddleName: false,
  clientMiddleName: '',
  clientLastName: '',

  // Блок ТС
  numberPlate: '',
  withoutNumber: false,
  vehicleBrand: null,
  vehicleModel: null,
  identifiersType: '1',
  identifiers: '',
  vehicleDocumentType: '1',
  vehicleType: '1',
  yearOfManufacture: '',
  enginePower: '',
  documentSerial: '',
  documentNumber: '',
  documentIssueDate: '',

  // Блок Страхователь
  insurerIsOwner: true,
  insurerFirstName: '',
  insurerNoMiddleName: false,
  insurerMiddleName: '',
  insurerLastName: '',
  insurerGender: '1',
  insurerPassportType: '0',
  insurerBirthdate: '',
  insurerPassportSerial: '',
  insurerPassportNumber: '',
  insurerPassportIssueDate: '',
  insurerPassportIssuedBy: null,
  insurerRegistrationAddress: null,

  // Блок Собственник
  ownerFirstName: '',
  ownerNoMiddleName: false,
  ownerMiddleName: '',
  ownerLastName: '',
  ownerGender: '1',
  ownerPassportType: '0',
  ownerBirthdate: '',
  ownerPassportSerial: '',
  ownerPassportNumber: '',
  ownerPassportIssueDate: '',
  ownerPassportIssuedBy: null,
  ownerRegistrationAddress: null,

  // Блок Водители

  drivers: [
    {
      driversWithoutRestrictions: false,
      driverSelect: 'Иное лицо',
      isForeignIdentity: false,
      driverFirstName: '',
      driverMiddleName: '',
      driverLastName: '',
      driverGender: '1',
      driverBirthdate: '',
      driverLicenseSerial: '',
      driverLicenseNumber: '',
      experienceStartDate: '',
      driverLicenseIssueDate: '',

      // Подблок предыдущее В/У
      isPreviousDriverLicense: false,
      previousDriverLicenseSerial: '',
      previousDriverLicenseNumber: '',
      previousDriverLicenseIssueDate: '',
      previousDriverFirstName: '',
      previousDriverMiddleName: '',
      previousDriverLastName: ''
    }
  ]
};

export default newPolisInitial;
