import PropTypes from 'prop-types';
import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// material
import {
  Grid,
  Card,
  Switch,
  FormControl,
  FormControlLabel,
  CardContent,
  RadioGroup,
  FormLabel,
  Radio,
  CircularProgress
} from '@material-ui/core';
import { MAutocomplete as Autocomplete } from 'components/@material-extend';
// utils
import { capitalizeField } from 'utils/textTransformation';
import {
  FormDatePicker,
  FormTextField,
  MiddleNameCheckTextField
} from 'components/personal/form-components';
import { personSchema } from 'form_settings/validations/new_polis_validations';
import {
  getPassportOffices,
  getRegistrationAddresses
} from 'redux/slices/dadata';
import {
  copyOwner,
  saveClient,
  savePerson,
  setInsurerIsClient,
  updateInsurer
} from 'redux/slices/osago/edit_contract';
import useErrorSnackbar from 'hooks/useErrorSnackbar';

// ----------------------------------------------------------------------

SwitchField.propTypes = {
  label: PropTypes.func,
  switcher: PropTypes.func
};

function SwitchField({ label, switcher }) {
  return (
    <Grid item sm={12} xs={12} container spacing={1}>
      <Grid item sm={8} xs={8} container justifyContent="flex-start">
        {label}
      </Grid>
      <Grid item sm={4} xs={4} container justifyContent="flex-end">
        {switcher}
      </Grid>
    </Grid>
  );
}

export default function InsurerBlock() {
  const dispatch = useDispatch();
  const {
    owner,
    client,
    contract,
    ownerIsClient,
    insurerIsOwner,
    updateOwner,
    ownerCopy
  } = useSelector((state) => state.editContract);
  const { passportOffices, registrationAddresses } = useSelector(
    (state) => state.dadata
  );
  const showError = useErrorSnackbar();

  const handlePassportIssuedByChange = useCallback(
    (value) => {
      dispatch(getPassportOffices({ query: value }));
    },
    [dispatch]
  );

  const handleRegistrationAddressChange = useCallback(
    (value) => {
      dispatch(getRegistrationAddresses({ query: value }));
    },
    [dispatch]
  );

  const formik = useFormik({
    initialValues: owner,
    validationSchema: personSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      const ownerForm = {
        id: owner.id,
        clientId: client.id,
        type: values.type,
        lastName: values.lastName,
        firstName: values.firstName,
        middleName: values.middleName,
        genderId: values.genderId,
        birthdate: values.birthdate,
        dadata: values.address.value,
        passportSerial: values.passportSerial,
        passportNumber: values.passportNumber,
        passportDate: values.passportDate,
        passportPoint: values.passportPoint.value,
        passportForeign: values.passportForeign,
        insurerIsOwner: Number(insurerIsOwner)
      };

      if (!insurerIsOwner) {
        dispatch(savePerson({ person: ownerForm, contractId: contract?.id }));
      }

      if (ownerIsClient) {
        console.log('owner save Users');
        dispatch(
          saveClient({
            ...client,
            lastName: values.lastName,
            firstName: values.firstName,
            middleName: values.middleName,
            genderId: values.genderId,
            birthdate: values.birthdate,
            address: values.address.value.value
          })
        );
      }
    }
  });

  const {
    handleSubmit,
    values,
    isValid,
    submitForm,
    setValues,
    getFieldProps
  } = formik;

  useEffect(() => {
    // Оповещение о пропущенных предыдущих блоках
    if (contract && !contract.vehicleId) {
      showError('Заполните транспорт');
    }

    if (insurerIsOwner) {
      if (!updateOwner) return;
      setValues(updateOwner);

      return;
    }
    setValues(ownerCopy);
  }, [insurerIsOwner, updateOwner, setValues, ownerCopy]);

  const handleChangeOwnerIsClient = (_, checked) => {
    dispatch(setInsurerIsClient(!checked));
  };

  const [isLoading, setLoading] = useState(false);

  useEffect(() => {
    if (!updateOwner) return;
    async function fetchData() {
      setLoading(true);
      setValues(updateOwner);
      setLoading(false);
    }
    fetchData();
  }, [updateOwner, setValues]);

  useEffect(() => {
    if (!insurerIsOwner) {
      dispatch(copyOwner(values));
    }
    if (insurerIsOwner && values.type === 'INSURER') {
      dispatch(updateInsurer(values));
    }
    if (isValid) {
      submitForm();
    }
  }, [values, isValid, dispatch, insurerIsOwner, submitForm]);

  if (isLoading)
    return (
      <Grid container justifyContent="center">
        <Grid item>
          <CircularProgress />
        </Grid>
      </Grid>
    );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container spacing={2}>
                  <SwitchField
                    label="Контакт"
                    switcher={
                      <Switch
                        checked={ownerIsClient}
                        onChange={handleChangeOwnerIsClient}
                      />
                    }
                  />

                  <Grid item sm={12} xs={12}>
                    <FormControl>
                      <FormLabel complete>Пол</FormLabel>
                      <RadioGroup
                        row
                        defaultValue="1"
                        {...getFieldProps(`genderId`)}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Мужской"
                        />
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="Женский"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12} />
                  <Grid item sm={12} xs={12}>
                    <FormControl>
                      <FormLabel complete>Тип документа</FormLabel>
                      <RadioGroup
                        row
                        defaultValue="0"
                        {...getFieldProps('passportForeign')}
                      >
                        <FormControlLabel
                          value="0"
                          control={<Radio />}
                          label="Паспорт РФ"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Другой"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="lastName"
                      placeholder="Введите фамилию"
                      required
                      fullWidth
                      label="Фамилия"
                      onChange={capitalizeField}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="firstName"
                      placeholder="Введите имя"
                      required
                      fullWidth
                      label="Имя"
                      onChange={capitalizeField}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <MiddleNameCheckTextField
                      required={!values.noMiddleName}
                      inputName="middleName"
                      checkName="noMiddleName"
                      fullWidth
                      label="Отчество"
                      onInputChange={capitalizeField}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormDatePicker
                      name="birthdate"
                      required
                      fullWidth
                      label="Дата рождения"
                    />
                  </Grid>

                  <Grid item xs={12} />

                  <Grid item xs={6} sm={3}>
                    <FormTextField
                      name="passportSerial"
                      required
                      fullWidth
                      label="Серия документа"
                      placeholder="Введите серию"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormTextField
                      name="passportNumber"
                      required
                      fullWidth
                      label="Номер документа"
                      placeholder="Введите номер"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormDatePicker
                      name="passportDate"
                      required
                      fullWidth
                      label="Дата выдачи документа"
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      name="passportPoint"
                      options={passportOffices.map((office) => ({
                        title: `${office.data.name} ${office.data.code}`,
                        value: office.value
                      }))}
                      getOptionLabel={(option) => option.title}
                      filterOptions={(options) => options}
                      onChangeInputAction={handlePassportIssuedByChange}
                      fullWidth
                      renderInputParams={{
                        required: true,
                        name: `passportPoint`,
                        label: 'Кем выдан',
                        placeholder:
                          'Для быстрого поиска можно ввести код подразделения'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      name="address"
                      options={registrationAddresses.map((address) => ({
                        title: address.value,
                        value: address
                      }))}
                      filterOptions={(options) => options}
                      onChangeInputAction={handleRegistrationAddressChange}
                      getOptionLabel={(option) => option.title}
                      fullWidth
                      renderInputParams={{
                        required: true,
                        name: 'address',
                        label: 'Адрес регистрации',
                        placeholder: 'Начните ввод'
                      }}
                    />
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
