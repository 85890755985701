import axios from 'utils/axios';
import { dateToString } from 'utils/textTransformation';

export const storeDriver = async ({
  clientId,
  id,
  firstName,
  middleName,
  lastName,
  birthdate,
  genderId,
  expDate,
  licenseDate,
  licenseSerial,
  licenseNumber,
  licenseForeign,
  isPrevious,
  prevLicenseSerial,
  prevLicenseNumber,
  prevLicenseDate,
  prevLicenseFirstname,
  prevLicenseMiddlename,
  prevLicenseLastname,
  createContract
}) => {
  const body = {
    clientId,
    firstName,
    middleName,
    lastName,
    genderId,
    birthdate:
      typeof birthdate === 'string' ? birthdate : dateToString(birthdate),
    expDate: typeof expDate === 'string' ? expDate : dateToString(expDate),
    licenseDate:
      typeof licenseDate === 'string' ? licenseDate : dateToString(licenseDate),
    licenseSerial,
    licenseNumber,
    licenseForeign,
    ...(isPrevious && {
      prevLicenseSerial,
      prevLicenseNumber,
      prevLicenseDate:
        typeof prevLicenseDate === 'string'
          ? prevLicenseDate
          : dateToString(prevLicenseDate),
      prevLicenseFirstname,
      prevLicenseMiddlename,
      prevLicenseLastname
    }),
    ...(createContract && { createContract })
  };

  if (!id) {
    const response = await axios.post(`api/v1/drivers`, body);
    return response.data;
  }
  const response = await axios.put(`api/v1/drivers/${id}`, body);
  return response.data;
};

export const getDriver = async (id) => {
  const response = await axios.get(`api/v1/drivers/${id}`);
  return response.data;
};

export const deleteDriver = async (id) => {
  const response = await axios.delete(`api/v1/drivers/${id}`);
  return response;
};

export const findKBM = async ({
  firstName,
  middleName,
  lastName,
  birthdate,
  licenseSerial,
  licenseNumber
}) => {
  const response = await axios.post(`api/v1/drivers/kbm`, {
    firstName,
    middleName,
    lastName,
    birthdate:
      typeof birthdate === 'string' ? birthdate : dateToString(birthdate),
    licenseSerial,
    licenseNumber
  });
  console.log(response.data);
  const { results } = response.data;
  return results;
};
