import axios from 'utils/axios';
import { dateToString } from 'utils/textTransformation';

export const searchEosago = async ({ numberPlate }) => {
  const response = await axios.get(`api/v1/eosago/contracts/search`, {
    params: { numberPlate }
  });
  console.log(response.data);
  return response.data;
};
export const searchEosagoByIdentifier = async ({ vin, date }) => {
  const response = await axios.post(`api/v1/eosago/search`, {
    vin,
    date: dateToString(date)
  });
  return response.data;
};

export const storeEosagoContract = async ({
  contractId,
  clientId,
  eosagoId,
  vehicleId,
  insurerId,
  ownerId,
  driversId
}) => {
  const body = {
    ...(clientId && { clientId }),
    ...(eosagoId && { eosagoId }),
    ...(vehicleId && { vehicleId }),
    ...(insurerId && { insurerId }),
    ...(ownerId && { ownerId }),
    ...(driversId && { driversId })
  };
  if (contractId) {
    const response = await axios.put(
      `api/v1/eosago/contracts/${contractId}`,
      body
    );
    return response.data;
  }
  const response = await axios.post(`api/v1/eosago/contracts`, {
    clientId,
    eosagoId,
    vehicleId,
    insurerId,
    ownerId,
    driversId
  });
  return response.data;
};

export const getEosagoContract = async (id) => {
  const response = await axios.get(`api/v1/eosago/contracts/${id}`);
  return response.data;
};

export const getContracts = async ({
  clientId,
  agentId,
  dateFrom,
  dateTo,
  nameSignPhone
}) => {
  let dateToUpdate;
  if (dateTo) {
    dateToUpdate = new Date(
      new Date(dateTo).setDate(new Date(dateTo).getDate() + 1)
    );
  }
  const response = await axios.get('api/v1/eosago/contracts', {
    params: {
      ...(clientId && { clientId }),
      ...(agentId && { agentId }),
      ...(dateFrom && { dateFrom: dateToString(dateFrom) }),
      ...(dateTo && { dateTo: dateToString(dateToUpdate) }),
      ...(nameSignPhone && { searchQuery: nameSignPhone })
    }
  });
  return response.data;
};

export const deleteEosagoContract = async (id) => {
  const response = await axios.delete(`api/v1/eosago/contracts/${id}`);
  return response.data;
};

export const calcContract = async (contractId, companyid) => {
  const response = await axios.get(
    `api/v1/eosago/contract/calc/${contractId}/${companyid}?allProviders=1`
  );
  return response.data;
};

export const payOffer = async (offerId) => {
  const response = await axios.get(`api/v1/eosago/contract/pay/${offerId}`);
  return response.data;
};
