import { Form, FormikProvider, useFormik } from 'formik';
import { useEffect, useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { Icon } from '@iconify/react';
import closeFill from '@iconify/icons-eva/close-fill';
// material
import {
  Grid,
  Card,
  FormControl,
  FormControlLabel,
  CardContent,
  RadioGroup,
  Radio,
  FormLabel
} from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
import {
  MAutocomplete as Autocomplete,
  MIconButton
} from 'components/@material-extend';
// utils
import { numberPlateRegex } from 'utils/regexes';
import { toUpperCaseField } from 'utils/textTransformation';
// requests
import { searchVehicle } from 'requests/vehicles-requests';
//
import {
  FormDatePicker,
  FormTextField,
  NumberPlateCheckTextField,
  RadioTextField
} from 'components/personal/form-components';
import { vehicleSchema } from 'form_settings/validations/new_polis_validations';
import { getVehicleModels } from 'redux/slices/osago/osago';
import { saveVehicle, searchContract } from 'redux/slices/osago/edit_contract';
import { searchEosago } from 'requests/eosago-contracts-requests';
import LoadDialog from 'components/LoadDialog';

import { debounce } from 'lodash';

// ----------------------------------------------------------------------

export default function VehicleBlock() {
  const dispatch = useDispatch();
  const [autocompleteLoad, setAutocompleteLoad] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { vehicleModels } = useSelector((state) => state.osago);
  const { vehicle, client, contract, updateVehicle, isEdit } = useSelector(
    (state) => state.editContract
  );

  const handleVehicleInputChange = (value) => {
    const fetchData = () => {
      dispatch(getVehicleModels({ model: value }));
    };
    // debounce-функция с задержкой в 800 миллисекунд
    const debouncedFetchData = debounce(fetchData, 800);
    debouncedFetchData();
  };

  const formik = useFormik({
    initialValues: vehicle,
    validationSchema: vehicleSchema,
    isInitialValid: false,
    onSubmit: async (values) => {
      console.log(
        '🚀 ~ file: VehicleBlock.js:88 ~ onSubmit: ~ vehicle:',
        vehicle
      );
      let vehicleForm = {};
      if (values.id) {
        vehicleForm = {
          id: values.id,
          clientId: values.clientId,
          numberPlate: values.numberPlate || 'null',
          type: values.type,
          power: values.power,
          year: values.year,
          identifiersType: values.identifiersType,
          identifiers: values.identifiers,
          docType: values.docType,
          docDate: values.docDate,
          modelId: values.model.value,
          docNumber: values.docNumber,
          docSerial: values.docSerial
        };
      } else {
        vehicleForm = {
          id: vehicle.id,
          clientId: client.id,
          numberPlate: values.numberPlate || 'null',
          type: values.type,
          power: values.power,
          year: values.year,
          identifiersType: values.identifiersType,
          identifiers: values.identifiers,
          docType: values.docType,
          docDate: values.docDate,
          modelId: values.model.value,
          docNumber: values.docNumber,
          docSerial: values.docSerial
        };
      }

      console.log(
        '🚀🚀🚀 ~ file: VehicleBlock.js:88 ~🚀 onSubmit: 🚀~ vehicleForm:',
        vehicleForm
      );
      dispatch(
        saveVehicle({
          vehicle: vehicleForm,
          contractId: contract?.id,
          modelInfo: { brand: values.brand, model: values.model }
        })
      );
      enqueueSnackbar('Данные о ТС добавлены', {
        variant: 'success',
        action: (key) => (
          <MIconButton size="small" onClick={() => closeSnackbar(key)}>
            <Icon icon={closeFill} />
          </MIconButton>
        )
      });
    }
  });

  const {
    handleSubmit,
    values,
    isValid,
    submitForm,
    getFieldProps,
    setValues
  } = formik;

  useEffect(() => {
    if (isValid) {
      submitForm();
    }
  }, [values, isValid, submitForm]);

  // useEffect(() => {
  //   async function fetchData() {
  //     if (isEdit) return;
  //     console.log(
  //       '🚀 ~ file: VehicleBlock.js:120 ~ fetchData ~  values.numberPlate:',
  //       values.numberPlate
  //     );

  //     // Вторая валидация не нужна
  //     // Уже есть валидация в схеме валидации vehicleSchema

  //     // if (
  //     //   values.numberPlate === '' ||
  //     //   values.numberPlate.search(numberPlateRegex) === -1
  //     // )
  //     //   return;

  //     try {
  //       console.log('SEARCH');
  //       enqueueSnackbar('Поиск полиса...', {
  //         variant: 'info',
  //         action: (key) => (
  //           <MIconButton size="small" onClick={() => closeSnackbar(key)}>
  //             <Icon icon={closeFill} />
  //           </MIconButton>
  //         )
  //       });
  //       const searchResult = await searchEosago({
  //         numberPlate: values.numberPlate
  //       });
  //       console.log(
  //         '🚀 ~ file: VehicleBlock.js:178 ~ fetchData ~ searchResult:',
  //         searchResult
  //       );
  //       if (!searchResult) {
  //         return;
  //       }
  //       const { message, contract } = searchResult;

  //       console.log('FOUND');

  //       if (message === 'OK' && Object.keys(contract).length > 0) {
  //         setLoading(true);
  //         dispatch(searchContract(contract));
  //       }
  //     } catch (exc) {
  //       console.log('🚀 ~ file: VehicleBlock.js:159 ~ fetchData ~ exc:', exc);
  //       closeSnackbar();
  //       enqueueSnackbar('Полис не найден', {
  //         variant: 'warning',
  //         action: (key) => (
  //           <MIconButton size="small" onClick={() => closeSnackbar(key)}>
  //             <Icon icon={closeFill} />
  //           </MIconButton>
  //         )
  //       });
  //     } finally {
  //       setLoading(false);
  //     }
  //   }
  //   fetchData();
  // }, [values.numberPlate, closeSnackbar, enqueueSnackbar, dispatch, isEdit]);

  const [isLoading, setLoading] = useState(false);
  useEffect(() => {
    if (!updateVehicle) return;
    async function fetchData() {
      setLoading(true);
      await setValues(updateVehicle);
      setLoading(false);
    }
    fetchData();
  }, [updateVehicle, setValues]);

  const handleAutocompleteButton = async () => {
    if (values.numberPlate) {
      try {
        setAutocompleteLoad(true);
        const { message, vehicle } =
          (await searchVehicle(values.numberPlate)) || {};

        if (vehicle.original) {
          throw Error(`${vehicle.original.description ?? 'Ошибка сервиса'}`);
        }

        if (message === 'OK' && vehicle) {
          setValues({
            brand: {
              title: vehicle.brand,
              value: vehicle.brand
            },
            model: {
              title: vehicle.model,
              value: vehicle.modelId
            },
            year: vehicle.year,
            power: vehicle.power,
            docType: `${vehicle.docs[0].docType ?? 1}`,
            docSerial: vehicle.docs[0].docSerial,
            docNumber: vehicle.docs[0].docNumber,
            docDate: new Date(vehicle.docs[0].docDate),
            type: `${vehicle.type ?? 1}`,
            ...(vehicle.vin && {
              identifiers: vehicle.vin,
              identifiersType: '1'
            }),
            ...(vehicle.bodyNum && {
              identifiers: vehicle.bodyNum,
              identifiersType: '2'
            }),
            ...(vehicle.chassisNum && {
              identifiers: vehicle.chassisNum,
              identifiersType: '3'
            })
          });
        }
      } catch (exc) {
        enqueueSnackbar('Не найдено', {
          variant: 'error',
          action: (key) => (
            <MIconButton size="small" onClick={() => closeSnackbar(key)}>
              <Icon icon={closeFill} />
            </MIconButton>
          )
        });

        console.log(exc);
      } finally {
        setAutocompleteLoad(false);
      }
    }
  };

  if (isLoading)
    return (
      <LoadDialog
        open={isLoading}
        message="Загрузка данных найденного полиса"
      />
    );

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" onSubmit={handleSubmit}>
        <Grid container spacing={3}>
          <Grid item xs={12} md={4}>
            <Card>
              <CardContent>
                <Grid container spacing={5}>
                  <Grid item sm={12} xs={12}>
                    <NumberPlateCheckTextField
                      inputName="numberPlate"
                      checkName="withoutNumber"
                      fullWidth
                      label="Гос. номер"
                      onInputChange={toUpperCaseField}
                    />
                  </Grid>
                  <Grid item sm={12} xs={12}>
                    <RadioTextField
                      required
                      inputName="identifiers"
                      radioName="identifiersType"
                      fullWidth
                      label="Гос. номер"
                    />
                  </Grid>

                  <Grid item sm={12} xs={12}>
                    <LoadingButton
                      fullWidth
                      variant="contained"
                      disabled={values.noNumberPlate}
                      // onClick={handleAutocompleteButton}
                      // pending={autocompleteLoad}
                    >
                      Автозаполнение
                    </LoadingButton>
                  </Grid>

                  <Grid item xs={12}>
                    <FormControl>
                      <FormLabel complete>Тип ТС</FormLabel>
                      <RadioGroup
                        row
                        defaultValue="1"
                        {...getFieldProps(`type`)}
                      >
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="Легковой"
                        />
                        <FormControlLabel
                          // Пока учитывается только значение для грузовиков <=16 тонн
                          value="5"
                          control={<Radio />}
                          label="Грузовой"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                  <Grid item xs={12}>
                    <FormControl dea>
                      <FormLabel complete>Тип документа ТС</FormLabel>
                      <RadioGroup
                        row
                        // defaultValue="1"
                        {...getFieldProps(`docType`)}
                      >
                        <FormControlLabel
                          value="2"
                          control={<Radio />}
                          label="СТС"
                        />
                        <FormControlLabel
                          value="1"
                          control={<Radio />}
                          label="ПТС"
                        />
                        <FormControlLabel
                          value="3"
                          control={<Radio />}
                          label="ЭПТС"
                        />
                      </RadioGroup>
                    </FormControl>
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
          </Grid>

          <Grid item xs={12} md={8}>
            <Card>
              <CardContent>
                <Grid container spacing={4}>
                  <Grid item xs={12} sm={12}>
                    <Autocomplete
                      name="model"
                      onChangeInputAction={handleVehicleInputChange}
                      options={vehicleModels.map((model) => ({
                        title: `${model.brand?.name} ${model.name}`,
                        value: model.id
                      }))}
                      getOptionLabel={(option) => option.title}
                      filterOptions={(options) => options}
                      fullWidth
                      renderInputParams={{
                        required: true,
                        name: 'model',
                        label: 'Модель',
                        placeholder: 'Начните ввод'
                      }}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="year"
                      fullWidth
                      label="Год выпуска"
                      placeholder="Введите год выпуска"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormTextField
                      name="power"
                      fullWidth
                      label="Мощность Л.С."
                      placeholder="Введите мощность"
                      required
                    />
                  </Grid>

                  <Grid item xs={12} />
                  <Grid item xs={12} />

                  <Grid item xs={6} sm={3}>
                    <FormTextField
                      name="docSerial"
                      required
                      fullWidth
                      label="Серия документа"
                      placeholder="Введите серию"
                    />
                  </Grid>
                  <Grid item xs={6} sm={3}>
                    <FormTextField
                      fullWidth
                      required
                      name="docNumber"
                      label="Номер документа"
                      placeholder="Введите номер"
                    />
                  </Grid>

                  <Grid item xs={12} sm={6}>
                    <FormDatePicker
                      name="docDate"
                      fullWidth
                      required
                      label="Дата выдачи документа"
                    />
                  </Grid>
                  <Grid item xs={12} />
                </Grid>
              </CardContent>
            </Card>
          </Grid>
        </Grid>
      </Form>
    </FormikProvider>
  );
}
