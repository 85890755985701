import PropTypes from 'prop-types';
import closeFill from '@iconify/icons-eva/close-fill';
import { Icon } from '@iconify/react';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText
} from '@material-ui/core';
import { MIconButton } from 'components/@material-extend';
import { useDispatch } from 'react-redux';
import { selectVehicle } from 'redux/slices/osago/edit_contract';

// ----------------------------------------------------------------------

ChooseVehicleDialog.propTypes = {
  vehicles: PropTypes.array,
  open: PropTypes.bool,
  closeAction: PropTypes.func
};

export default function ChooseVehicleDialog({
  vehicles = [],
  open,
  closeAction
}) {
  const dispatch = useDispatch();

  const handleSelectVehicle = (vehicle) => {
    dispatch(selectVehicle(vehicle));
    closeAction();
  };

  return (
    <Dialog open={open} onClose={closeAction} maxWidth="xs" fullWidth>
      <DialogTitle>
        <Grid border={0} container>
          <Grid item xs={6} container alignItems="center">
            Выбор ТС
          </Grid>
          <Grid
            item
            xs={6}
            container
            border={0}
            justifyContent="flex-end"
            alignItems="flex-start"
          >
            <MIconButton onClick={closeAction}>
              <Icon icon={closeFill} />
            </MIconButton>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent dividers>
        <List>
          {vehicles.map((vehicle) => (
            <ListItem
              key={vehicle.id}
              button
              onClick={() => {
                handleSelectVehicle(vehicle);
              }}
            >
              <ListItemText
                primary={
                  vehicle.numberPlate === 'null'
                    ? 'Нет номера'
                    : vehicle.numberPlate
                }
                secondary={`${vehicle.brand ?? ''} ${
                  vehicle.model ?? ''
                }`.trim()}
              />
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
}
