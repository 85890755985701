import { lazy } from 'react';
// pathes
import { PATH_CONTACTS } from 'routes/paths';

// ----------------------------------------------------------------------

const ContactsRoutes = {
  path: PATH_CONTACTS.root,
  routes: [
    // Contacts routes
    {
      exact: true,
      path: PATH_CONTACTS.root,
      component: lazy(() => import('views/contacts/ContactsPage'))
    },
    {
      exact: true,
      path: PATH_CONTACTS.contactDetails,
      component: lazy(() => import('views/contacts/ContactDetailsPage'))
    },
    {
      exact: true,
      path: PATH_CONTACTS.vehicleDetails,
      component: lazy(() => import('views/contacts/ContactVehicleDetailsPage'))
    }
  ]
};

export default ContactsRoutes;
