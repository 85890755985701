import { useDispatch } from 'react-redux';
import EndIcon from '@material-ui/icons/CloseRounded';
import { useTheme, Button } from '@material-ui/core';
import { clearFilters } from 'redux/slices/osago/contracts';

export default function ClearFiltersButton() {
  const theme = useTheme();
  const dispatch = useDispatch();

  const handleClick = () => {
    dispatch(clearFilters());
  };

  return (
    <Button
      variant="outlined"
      size="small"
      onClick={handleClick}
      sx={{
        backgroundColor: 'white',
        borderRadius: 15,
        color: theme.palette.grey[700],
        borderColor: theme.palette.grey[700]
      }}
      startIcon={<EndIcon sx={{ color: 'red' }} fontSize="small" />}
    >
      Сбросить фильтр
    </Button>
  );
}
