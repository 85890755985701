import PropTypes from 'prop-types';
import { useField } from 'formik';
import { forwardRef, useState, useEffect } from 'react';
// material
import { TextField } from '@material-ui/core';

// ----------------------------------------------------------------------

const StandartFormTextField = forwardRef(
  ({ onChange, onFocus, onBlur, InputLabelProps, ...props }, ref) => {
    const [field, meta, helpers] = useField(props);
    const [value, setValue] = useState(field.value);

    const handleChange = (event) => {
      let val = event.target.value;
      if (onChange) val = onChange(event);
      setValue(val);
    };

    const handleBlur = () => {
      if (value !== null) {
        helpers.setValue(value);
        if (onBlur) onBlur(value);
      }
    };

    const handleFocus = () => {
      if (onFocus) onFocus(value);
      helpers.setTouched(true);
    };

    useEffect(() => {
      setValue(field.value);
    }, [field.value]);

    return (
      <TextField
        ref={ref}
        InputLabelProps={{
          shrink: true,
          ...InputLabelProps
        }}
        {...props}
        value={value}
        onBlur={handleBlur}
        onChange={handleChange}
        onFocus={handleFocus}
        error={Boolean(meta.touched && meta.error)}
        helperText={meta.touched && meta.error}
        variant="standard"
      />
    );
  }
);

StandartFormTextField.propTypes = {
  onChange: PropTypes.func,
  onFocus: PropTypes.func,
  onBlur: PropTypes.func,
  InputLabelProps: PropTypes.object
};

export default StandartFormTextField;
