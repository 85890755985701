import { useFormik, FormikProvider, Form } from 'formik';
// material
import { Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
//
import { prolongationInitial } from 'form_settings/inits';
import { prolongationSchema } from 'form_settings/validations';
//
import { ClientBlock, ButtonsBlock } from 'components/personal/mixed';
import { GeneralInformation, PreviousPolisBlock } from './prolongation';

// ----------------------------------------------------------------------

export default function ProlongationForm() {
  const formik = useFormik({
    initialValues: prolongationInitial,
    validationSchema: prolongationSchema,
    onSubmit: async (values, { resetForm }) => {
      resetForm();
    }
  });

  const {
    isSubmitting,
    handleSubmit,
    values: { clientSelect }
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <GeneralInformation />
        {clientSelect.value === '-1' ? (
          <ClientBlock odd fieldType="client" />
        ) : undefined}
        <PreviousPolisBlock />

        <ButtonsBlock>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              pending={isSubmitting}
            >
              Сохранить в черновик
            </LoadingButton>
          </Grid>
          <Grid item xs={6}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
              pending={isSubmitting}
            >
              Сохранить и рассчитать
            </LoadingButton>
          </Grid>
        </ButtonsBlock>
      </Form>
    </FormikProvider>
  );
}
