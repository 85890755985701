import PropTypes from 'prop-types';
// material
import { Box, Typography } from '@material-ui/core';

BlockTitle.propTypes = {
  title: PropTypes.node
};

export default function BlockTitle({ title }) {
  return (
    <Box sx={{ mb: { xs: 2 }, mt: { xs: 2 } }}>
      <Typography variant="h4" align="center">
        {title}
      </Typography>
    </Box>
  );
}
