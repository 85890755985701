import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from 'utils/axios';
// requests
import { getModelById, storeVehicle } from 'requests/vehicles-requests';
import {
  deleteEosagoContract,
  getEosagoContract
} from 'requests/eosago-contracts-requests';
import { getPerson, storePerson } from 'requests/persons-requests';
import {
  deleteDriver,
  getDriver,
  storeDriver
} from 'requests/drivers-requests';

// ----------------------------------------------------------------------

const initialState = {
  contacts: [],
  contactsOffset: 20,
  contactsPage: 1,
  contactsPageCount: 0,
  contactsCount: 0,
  contact: null,
  contractId: null,
  vehicle: {},
  insurerIsOwner: true,
  insurer: {},
  owner: {},
  drivers: [],
  history: []
};

const slice = createSlice({
  name: 'contacts',
  initialState,
  reducers: {
    getContactsSuccess(state, action) {
      state.contacts = action.payload;
    },
    setContactsPage(state, action) {
      state.contactsPage = action.payload;
    },
    setContactsOffset(state, action) {
      state.contactsPage = 1;
      state.contactsOffset = action.payload;
    },
    setContactsPageCount(state, action) {
      state.contactsPageCount = action.payload;
    },
    setContactsCount(state, action) {
      state.contactsCount = action.payload;
    },
    setContact(state, action) {
      state.contact = action.payload;
    },
    setContractId(state, action) {
      state.contractId = action.payload;
    },
    setVehicle(state, action) {
      state.vehicle = action.payload;
    },
    setInsurer(state, action) {
      state.insurer = { ...state.insurer, ...action.payload };
    },
    setOwner(state, action) {
      state.owner = action.payload;
    },
    setInsurerIsOwner(state, action) {
      state.insurerIsOwner = action.payload;
    },
    setDrivers(state, action) {
      state.drivers = action.payload;
    },
    replaceDriver(state, action) {
      state.drivers[action.payload.index] = action.payload.driver;
    },
    addDriver(state, action) {
      state.drivers.push(action.payload);
    },
    removeDriver(state, action) {
      state.drivers.splice(action.payload, 1);
    },
    clearDrivers(state, _) {
      state.drivers.splice(0, state.drivers.length);
    },
    getHistorySuccess(state, action) {
      state.history = action.payload;
    },
    removeContractFromHistory(state, action) {
      state.history.splice(
        state.history.findIndex((contract) => contract.id === action.payload),
        1
      );
      // TODO: добавить удаление контракта из истории
    }
  }
});

// Reducer
export default slice.reducer;

export const {
  setContractId,
  setInsurerIsOwner,
  addDriver,
  setContactsPage,
  setContactsOffset
} = slice.actions;

// ----------------------------------------------------------------------

// Функции контактов

export function getContacts({ offset, page, search }) {
  return async (dispatch) => {
    console.log({ offset, page, search });
    const response = await axios.get('api/v1/users', {
      params: {
        role: 'client',
        offset,
        page,
        search
      }
    });
    console.log(response.data);
    const { data = [], total = 0 } = response.data?.Users;
    dispatch(slice.actions.setContactsCount(total));
    dispatch(slice.actions.getContactsSuccess(data));
  };
}

export function setContact(id) {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/users/${id}`);
    const { user } = response.data;
    dispatch(slice.actions.setContact(user));
  };
}

export function newContact() {
  return async (dispatch) => {
    dispatch(slice.actions.setContact(null));
  };
}

export function updateContact(contact) {
  return async (dispatch) => {
    const { id = null, ...info } = contact;
    if (!id) {
      const response = await axios.post('api/v1/contacts', info);
      const { contact } = response?.data || {};
      await dispatch(slice.actions.setContact(contact));
    } else {
      const response = await axios.put(`api/v1/contacts/${id}`, info);
      const { contact } = response?.data || {};
      await dispatch(slice.actions.setContact(contact));
    }
  };
}

// Функции истории

export function getHistory(contactId) {
  return async (dispatch) => {
    const response = await axios.get(`api/v1/contacts/history/${contactId}`);
    const { data } = response.data;
    dispatch(slice.actions.getHistorySuccess(data?.contracts ?? []));
  };
}

export function setContractFromId(contractId) {
  return async (dispatch) => {
    dispatch(slice.actions.setContractId(contractId));
    const contractResponse = await getEosagoContract(contractId);
    const { contract } = contractResponse;
    const { insurerId, ownerId, vehicleId } = contract;
    const driversId = Array.isArray(contract.driversId)
      ? contract.driversId
      : JSON.parse(contract.driversId);

    if (vehicleId) {
      const vehicleResponse = await axios.get(`api/v1/vehicles/${vehicleId}`);
      const { vehicle } = vehicleResponse.data;
      const { modelId } = vehicle;
      const modelResponse = await getModelById(modelId);
      dispatch(slice.actions.setVehicle({ ...vehicle, ...modelResponse }));
    }
    if (insurerId) {
      const insurerResponse = await getPerson(insurerId);
      const { person: insurer = null } = insurerResponse || {};
      dispatch(slice.actions.setInsurer(insurer));
      dispatch(
        slice.actions.setInsurerIsOwner(Boolean(insurer?.insurerIsOwner ?? 1))
      );
    }
    if (ownerId) {
      const ownerResponse = await getPerson(ownerId);
      const { person: owner = null } = ownerResponse || {};
      dispatch(slice.actions.setOwner(owner));
    }

    const drivers = await Promise.all(
      driversId.map(async (id) => {
        const driverResponse = await getDriver(id);
        const { driver } = driverResponse;
        return driver;
      })
    );

    dispatch(slice.actions.setDrivers(drivers));
  };
}

export function deleteContract(contractId) {
  return async (dispatch) => {
    if (contractId) {
      await deleteEosagoContract(contractId);
      dispatch(slice.actions.removeContractFromHistory(contractId));
    }
  };
}

export function setContract({ vehicle, insurer, owner, drivers, contractId }) {
  return async (dispatch) => {
    dispatch(slice.actions.setContractId(contractId));

    dispatch(slice.actions.setVehicle(vehicle));
    if (vehicle) {
      const { modelId } = vehicle;
      const modelResponse = (await getModelById(modelId)) || {};
      dispatch(slice.actions.setVehicle({ ...vehicle, ...modelResponse }));
    }
    dispatch(slice.actions.setInsurer(insurer));
    dispatch(slice.actions.setOwner(owner));
    dispatch(slice.actions.setDrivers(drivers));
  };
}

export function newVehicle() {
  return async (dispatch) => {
    dispatch(slice.actions.setVehicle(null));
    dispatch(slice.actions.setInsurer(null));
    dispatch(slice.actions.setOwner(null));
    dispatch(slice.actions.setDrivers([]));
    dispatch(slice.actions.setContractId(null));
    dispatch(slice.actions.setInsurerIsOwner(true));
  };
}

export function newEmptyDriver() {
  return async (dispatch) => {
    const driver = {
      genderId: 1,
      firstName: '',
      middleName: '',
      lastName: '',
      birthdate: null,
      expDate: null,
      licenseDate: null,
      licenseSerial: '',
      licenseNumber: '',
      licenseForeign: 0,

      prevLicenseSerial: '',
      prevLicenseNumber: '',
      prevLicenseDate: '',
      prevLicenseFirstname: '',
      prevLicenseMiddlename: '',
      prevLicenseLastname: ''
    };
    dispatch(slice.actions.addDriver(driver));
  };
}

export function removeDriver(index, id) {
  return async (dispatch) => {
    if (id) await deleteDriver(id);
    dispatch(slice.actions.removeDriver(index));
  };
}

export function savePerson({
  type,
  id,
  clientId,
  contractId,
  genderId,
  passportForeign,
  firstName,
  lastName,
  middleName,
  noMiddleName,
  birthdate,
  passportSerial,
  passportNumber,
  passportDate,
  dadata,
  passportPoint,
  insurerIsOwner
}) {
  return async (dispatch) => {
    const personResponse = await storePerson({
      ...(id && { id }),
      clientId,
      ...(!contractId && { createContract: true }),
      type: insurerIsOwner ? 'INSURER' : type.toUpperCase(),
      genderId,
      passportForeign,
      firstName,
      lastName,
      middleName,
      noMiddleName,
      birthdate,
      passportSerial,
      passportNumber,
      passportDate,
      dadata,
      passportPoint,
      ...(type === 'insurer' ||
        (insurerIsOwner && {
          insurerIsOwner
        }))
    });

    const { person = null } = personResponse;

    if (!contractId) {
      const { contract } = personResponse;
      dispatch(slice.actions.setContractId(contract?.id));
    } else {
      await axios.put(`api/v1/eosago/contracts/${contractId}`, {
        ...(type === 'insurer'
          ? { insurerId: person?.id }
          : { ownerId: person?.id })
      });
    }

    if (type === 'insurer' || insurerIsOwner) {
      dispatch(slice.actions.setInsurer(person));
    }
    if (type === 'owner') {
      dispatch(slice.actions.setOwner(person));
    }
  };
}

export function saveVehicle({
  id,
  clientId,
  contractId,
  numberPlate,
  type,
  power,
  year,
  identifiersType,
  identifiers,
  docType,
  docSerial,
  docNumber,
  docDate,
  brandId,
  brandName,
  modelId,
  modelName,
  dc,
  dcDate
}) {
  return async (dispatch) => {
    const vehicleResponse = await storeVehicle({
      ...(id && { id }),
      clientId,
      ...(numberPlate && { numberPlate }),
      ...(!contractId && { createContract: true }),
      type,
      power,
      year,
      identifiersType,
      identifiers,
      docType,
      docSerial,
      docNumber,
      docDate,
      modelId,
      ...(dc && { dc }),
      ...(dcDate && { dcDate })
    });
    const { vehicle = null } = vehicleResponse;
    if (!contractId) {
      const { contract } = vehicleResponse;
      dispatch(slice.actions.setContractId(contract?.id));
    } else {
      await axios.put(`api/v1/eosago/contracts/${contractId}`, {
        vehicleId: vehicle?.id
      });
    }

    dispatch(
      slice.actions.setVehicle({
        ...vehicle,
        ...{ modelName, modelId, brandId, brandName }
      })
    );
  };
}

export function saveDriver({
  driversId,
  index,

  id,
  clientId,
  contractId,

  genderId,
  firstName,
  middleName,
  lastName,
  birthdate,
  expDate,
  licenseDate,
  licenseSerial,
  licenseNumber,
  licenseForeign,

  prevLicenseDate,
  prevLicenseFirstname,
  prevLicenseLastname,
  prevLicenseMiddlename,
  prevLicenseNumber,
  prevLicenseSerial
}) {
  return async (dispatch) => {
    const driverResponse = await storeDriver({
      ...(id && { id }),
      clientId,
      genderId,
      lastName,
      firstName,
      ...(middleName && { middleName }),
      ...(!contractId && { createContract: true }),
      birthdate,
      expDate,
      licenseDate,
      licenseSerial,
      licenseNumber,
      licenseForeign,

      ...(prevLicenseDate && { prevLicenseDate }),
      ...(prevLicenseFirstname && { prevLicenseFirstname }),
      ...(prevLicenseLastname && { prevLicenseLastname }),
      ...(prevLicenseMiddlename && { prevLicenseMiddlename }),
      ...(prevLicenseNumber && { prevLicenseNumber }),
      ...(prevLicenseSerial && { prevLicenseSerial })
    });
    const { driver = null } = driverResponse;

    if (!contractId) {
      const { contract } = driverResponse;
      dispatch(slice.actions.setContractId(contract?.id));
    } else {
      driversId.indexOf(driver.id);

      await axios.put(`api/v1/eosago/contracts/${contractId}`, {
        driversId:
          driversId.indexOf(driver.id) !== -1
            ? driversId
            : [...driversId, driver.id]
      });
    }

    dispatch(
      slice.actions.replaceDriver({
        index,
        driver
      })
    );
  };
}
