import PropTypes from 'prop-types';
// material
import { useTheme, alpha } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Block.propTypes = {
  title: PropTypes.node,
  odd: PropTypes.bool,
  children: PropTypes.node
};

export default function Block({ title, odd, children }) {
  const theme = useTheme();
  return (
    <Box
      sx={{
        mb: { xs: 6 },
        backgroundColor: odd ? theme.palette.grey[200] : undefined
      }}
      border={1}
      borderColor={alpha(theme.palette.grey[200], 0.8)}
      borderRadius={1}
      boxShadow={3}
    >
      {title}
      <Box sx={{ mt: { xs: 4 }, mx: { xs: 2 }, mb: { xs: 4 } }}>{children}</Box>
    </Box>
  );
}
