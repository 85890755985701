import { useFormik, FormikProvider, Form } from 'formik';
// material
import { Grid } from '@material-ui/core';
import { LoadingButton } from '@material-ui/lab';
//
import { calculatorInitial } from 'form_settings/inits';
import { calculatorSchema } from 'form_settings/validations';
//
import { ButtonsBlock } from 'components/personal/mixed';
import { DriversBlock, GeneralInformation, OwnerBlock } from './calculator';

// ----------------------------------------------------------------------

export default function CalculatorForm() {
  const formik = useFormik({
    initialValues: calculatorInitial,
    validationSchema: calculatorSchema,
    onSubmit: async (values, { resetForm }) => {
      resetForm();
    }
  });

  const {
    values: { drivers },
    handleSubmit
  } = formik;

  return (
    <FormikProvider value={formik}>
      <Form autoComplete="off" noValidate onSubmit={handleSubmit}>
        <GeneralInformation />
        <DriversBlock odd />
        {drivers[0].driversWithoutRestrictions ? <OwnerBlock /> : undefined}

        <ButtonsBlock>
          <Grid item xs={12}>
            <LoadingButton
              fullWidth
              size="large"
              type="submit"
              variant="contained"
            >
              Сохранить и рассчитать
            </LoadingButton>
          </Grid>
        </ButtonsBlock>
      </Form>
    </FormikProvider>
  );
}
