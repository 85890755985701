import { useSelector } from 'react-redux';
// material
import { useTheme } from '@material-ui/core/styles';
import { Grid, useMediaQuery } from '@material-ui/core';
import { MAutocomplete as Autocomplete } from 'components/@material-extend';
//
import {
  FormBlock,
  BlockTitle,
  FormDatePicker,
  FormTextField
} from 'components/personal/form-components';
import { toUpperCaseField } from 'utils/textTransformation';

export default function PreviousPolisBlock() {
  const { polisSerials } = useSelector((state) => state.lists);
  const theme = useTheme();
  const isDesktop = useMediaQuery(theme.breakpoints.up('lg'));

  const polisSerialsList = polisSerials.map((serial) => ({
    title: serial.name,
    value: serial.name
  }));

  return (
    <FormBlock title={<BlockTitle title="Предыдущий страховой полис" />}>
      <Grid item xs={isDesktop ? 4 : 12}>
        <Autocomplete
          name="prevPolisSerial"
          options={polisSerialsList}
          getOptionLabel={(option) => option.title}
          onChangeInput={toUpperCaseField}
          fullWidth
          renderInputParams={{
            name: 'prevPolisSerial',
            label: 'Серия предыдущего полиса',
            placeholder: 'Введите серию полиса'
          }}
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormTextField
          name="prevPolisNumber"
          fullWidth
          label="Номер предыдущего полиса"
          placeholder="Введите номер полиса"
        />
      </Grid>
      <Grid item xs={isDesktop ? 4 : 12}>
        <FormDatePicker
          name="ownerBirthdate"
          label="Дата рождения собственника"
        />
      </Grid>
    </FormBlock>
  );
}
