export const phoneRegex = /\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}/g;
export const polisSerialRegex = /[А-ЯЁ]{3}/g;
export const polisNumberRegex = /[0-9]/g;

// Vehicle block
export const numberPlateRegex = /^[А-ЯЁ0-9]*$/;
export const yearRegex = /^(19|20)\d{2}$/g;
export const enginePowerRegex = /^[0-9]*(\.?)[0-9]{0,2}$/g;
export const VINNumberRegex = /^[0-9ABCDEFGHJKLMNPRSTUVWXYZ]{17}$/g;
export const bodyNumberRegex = /^[A-Z0-9]*$/g;
export const chassiesNumberRegex = bodyNumberRegex;
export const documentSerialRegex = /^[а-яёА-ЯЁ0-9]{4}$/g;
export const documentNumberRegex = /^[0-9]{6}$/g;
export const diagnosticCardRegex = /^[0-9]{15}$/g;
