export function clearPhoneMask(phone) {
  return phone
    .replace(/\)/g, '')
    .replace(/\(/g, '')
    .replace(/-/g, '')
    .replace(/ /g, '')
    .replace(/_/g, '')
    .replace(/\+/g, '');
}
export function toPhoneMask(phone) {
  let result = clearPhoneMask(phone);
  if (result.search(/7\d{10}/g) === -1) return phone;
  result = `+${result[0]} (${result.substring(1, 4)}) ${result.substring(
    4,
    7
  )}-${result.substring(7, 9)}-${result.substring(9, 11)}`;
  return result;
}

export function clearStateSignMask(numberPlate) {
  return numberPlate.replace(/\)/g, '').replace(/\(/g, '');
}
