import PropTypes from 'prop-types';
import { Grid, Typography, useTheme } from '@material-ui/core';

SwitchField.propTypes = {
  disabled: PropTypes.bool,
  label: PropTypes.string,
  switcher: PropTypes.object,
  textDirection: PropTypes.oneOf(['left', 'right'])
};

export default function SwitchField({
  label,
  switcher,
  textDirection = 'left',
  disabled = false
}) {
  const theme = useTheme();
  return (
    <Grid
      item
      sm={12}
      xs={12}
      container
      spacing={1}
      alignContent="center"
      alignItems="center"
      sx={{ width: '100%', m: 0 }}
      // justifyContent="stretch"
    >
      {textDirection === 'left' ? (
        <>
          <Grid item sm={8} xs={8} container justifyContent="flex-start">
            <Typography
              {...(disabled && { color: theme.palette.text.disabled })}
            >
              {label}
            </Typography>
          </Grid>
          <Grid item sm={4} xs={4} container justifyContent="flex-end">
            {switcher}
          </Grid>
        </>
      ) : (
        <>
          <Grid item sm={8} xs={8} container justifyContent="flex-start">
            {switcher}
          </Grid>
          <Grid item sm={4} xs={4} container justifyContent="flex-end">
            {label}
          </Grid>
        </>
      )}
    </Grid>
  );
}
