import PropTypes from 'prop-types';
import { useState, useEffect } from 'react';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Box,
  FormControl,
  InputLabel,
  RadioGroup,
  FormControlLabel,
  Input,
  Radio,
  FormHelperText
} from '@material-ui/core';
//
import { useField, useFormikContext } from 'formik';

// ----------------------------------------------------------------------

RadioTextField.propTypes = {
  onInputChange: PropTypes.func,
  onInputFocus: PropTypes.func,
  onInputBlur: PropTypes.func,
  inputName: PropTypes.string,
  odd: PropTypes.bool,
  radioName: PropTypes.string
};

export default function RadioTextField({
  onInputChange,
  onInputFocus,
  onInputBlur,
  inputName,
  odd,
  radioName
}) {
  const theme = useTheme();
  const { setFieldValue, getFieldProps } = useFormikContext();
  const [field, meta, helpers] = useField(inputName);
  const [value, setValue] = useState(field.value);

  const [isActive, setActive] = useState(false);
  const [borderColor, setBorderColor] = useState(theme.palette.action.focus);

  const handleFocus = () => {
    setActive(true);
    helpers.setTouched(true);
    setBorderColor(theme.palette.primary.main);
    if (onInputFocus) onInputFocus(value);
  };

  const handleBlur = () => {
    setActive(false);
    setBorderColor(theme.palette.action.focus);
    if (value !== null) {
      setFieldValue(field.name, value);
      if (onInputBlur) onInputBlur(value);
    }
  };

  const handleMouseOver = () => {
    if (!isActive) {
      setBorderColor(theme.palette.text.primary);
    }
  };
  const handleMouseLeave = () => {
    if (!isActive) {
      setBorderColor(theme.palette.action.focus);
    }
  };

  const handleInputChange = (event) => {
    let val = event.target.value;
    if (onInputChange) val = onInputChange(event);
    setValue(val);
  };

  const getLabelColor = () => {
    if (meta.touched && meta.error) return theme.palette.error.main;
    if (value !== '') return theme.palette.text.primary;
    return theme.palette.text.secondary;
  };

  useEffect(() => {
    setValue(field.value);
  }, [field.value]);

  const radioBackground = {
    backgroundColor: odd
      ? theme.palette.grey[200]
      : theme.palette.background.default,
    background: `linear-gradient(0deg, white 50%, ${
      odd ? theme.palette.grey[200] : theme.palette.background.default
    } 50%)`
  };

  return (
    <Box
      height={56}
      sx={{ backgroundColor: 'white' }}
      boxShadow={2}
      border={isActive ? 2 : 1}
      borderRadius={1}
      borderColor={
        meta.touched && meta.error ? theme.palette.error.main : borderColor
      }
    >
      <FormControl fullWidth size="small">
        <InputLabel
          shrink
          sx={{
            // top: '-30%',
            mt: -2,
            left: '3%',
            right: '-32%',
            color: getLabelColor()
          }}
        >
          <Box height={40} overflow="hidden" textOverflow="clip">
            <RadioGroup
              row
              sx={{
                ml: { xs: 2 }
              }}
              defaultValue="1"
              {...getFieldProps(radioName)}
              // {...radioProps}
            >
              <FormControlLabel
                sx={radioBackground}
                value="1"
                control={<Radio />}
                label="VIN"
              />

              <FormControlLabel
                sx={radioBackground}
                value="2"
                control={<Radio />}
                label="Номер кузова"
              />
              <FormControlLabel
                sx={radioBackground}
                value="3"
                control={<Radio />}
                label="Номер шасси"
              />
            </RadioGroup>
          </Box>
        </InputLabel>
        <Input
          disableUnderline
          sx={{ left: '3%', top: -2, borderWidth: 1 }}
          value={value}
          onFocus={handleFocus}
          onBlur={handleBlur}
          onMouseOver={handleMouseOver}
          onMouseLeave={handleMouseLeave}
          placeholder="Введите номер"
          onChange={handleInputChange}
        />
        <FormHelperText
          error
          id="component-helper-text"
          sx={{
            ml: 2,
            mt: 2
          }}
        >
          {meta.touched && meta.error}
        </FormHelperText>
      </FormControl>
    </Box>
  );
}
