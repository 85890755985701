import * as Yup from 'yup';
import {
  documentSerialRegex,
  enginePowerRegex,
  documentNumberRegex
} from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const calculatorSchema = Yup.object().shape({
  // Блок Общая информация
  vehicleType: Yup.number(),
  enginePower: Yup.string()
    .required('Мощность обязательна')
    .matches(enginePowerRegex, 'Мощность введена неверно'),
  year: Yup.number()
    .required('Год обязателен')
    .min(1940, 'Неверный год')
    .max(new Date().getFullYear(), 'Год выше текущий даты'),
  datePolisStart: Yup.date('Введите дату').min(
    MIN_VALIDATION_DATE,
    'Дата некорректна'
  ),
  insuranceTerm: Yup.number().integer(),
  withTrailer: Yup.boolean(),

  // Блок Водители
  drivers: Yup.array()
    .of(
      Yup.object({
        driversWithoutRestrictions: Yup.boolean().default(false),
        driverSelect: Yup.string(),
        isForeignIdentity: Yup.boolean(),
        driverFirstName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Имя обязательно')
              : schema.notRequired()
        ),
        driverMiddleName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Отчество обязательно')
              : schema.notRequired()
        ),
        driverLastName: Yup.string().when(
          ['driversWithoutRestrictions', 'driverSelect'],
          (driversWithoutRestrictions, driverSelect, schema) =>
            !driversWithoutRestrictions && driverSelect === 'Иное лицо'
              ? schema.required('Фамилия обязательна')
              : schema.notRequired()
        ),
        driverGender: Yup.number(),
        driverBirthdate: Yup.date()
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            ['driversWithoutRestrictions', 'driverSelect'],
            (driversWithoutRestrictions, driverSelect, schema) =>
              !driversWithoutRestrictions && driverSelect === 'Иное лицо'
                ? schema.required('Дата рождения обязательна')
                : schema.notRequired()
          ),
        driverLicenseSerial: Yup.string()
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Серия обязательна')
          )
          .when('isForeignIdentity', (isForeignIdentity, schema) =>
            !isForeignIdentity
              ? schema.matches(documentSerialRegex, 'Неверный формат')
              : undefined
          ),
        driverLicenseNumber: Yup.string()
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Номер обязателен')
          )
          .when('isForeignIdentity', (isForeignIdentity, schema) =>
            !isForeignIdentity
              ? schema.matches(documentNumberRegex, 'Неверный формат')
              : undefined
          ),
        experienceStartDate: Yup.date()
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Дата обязательна')
          ),
        driverLicenseIssueDate: Yup.date()
          .min(MIN_VALIDATION_DATE, 'Дата некорректна')
          .when(
            'driversWithoutRestrictions',
            (driversWithoutRestrictions, schema) =>
              driversWithoutRestrictions
                ? schema.notRequired()
                : schema.required('Дата выдачи обязательна')
          ),
        isPreviousDriverLicense: Yup.boolean(),
        previousDriverLicenseSerial: Yup.string().matches(
          documentSerialRegex,
          'Неверный формат'
        ),
        previousDriverLicenseNumber: Yup.string().matches(
          documentNumberRegex,
          'Неверный формат'
        ),
        previousDriverLicenseIssueDate: Yup.date().min(
          MIN_VALIDATION_DATE,
          'Дата некорректна'
        ),
        previousDriverFirstName: Yup.string(),
        previousDriverMiddleName: Yup.string(),
        previousDriverLastName: Yup.string()
      })
    )
    .required(),

  // Блок Собственник срабатывает в случае если Водители выбрано без ограничений
  ownerFirstName: Yup.string().when('drivers', (drivers, schema) =>
    drivers[0].driversWithoutRestrictions
      ? schema.required('Имя обязательно')
      : schema.notRequired()
  ),
  ownerNoMiddleName: Yup.boolean(),
  ownerMiddleName: Yup.string().when(
    ['drivers', 'ownerNoMiddleName'],
    (drivers, ownerNoMiddleName, schema) => {
      if (!drivers[0].driversWithoutRestrictions) {
        return schema.notRequired();
      }
      if (ownerNoMiddleName) {
        return schema.notRequired();
      }
      return schema.required('Отчество обязательно');
    }
  ),
  ownerLastName: Yup.string().when('drivers', (drivers, schema) =>
    !drivers[0].driversWithoutRestrictions
      ? schema.notRequired()
      : schema.required('Фамилия обязательна')
  ),
  ownerGender: Yup.number(),
  ownerPassportType: Yup.string().when('drivers', (drivers, schema) =>
    !drivers[0].driversWithoutRestrictions
      ? schema.notRequired()
      : schema.required('Тип обязателен')
  ),
  ownerBirthdate: Yup.date()
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Дата рождения обязательна')
    ),
  ownerPassportSerial: Yup.string()
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Серия обязательна')
    )
    .when('ownerPassportType', (ownerPassportType, schema) =>
      ownerPassportType === '0'
        ? schema.matches(documentSerialRegex, 'Неверный формат')
        : undefined
    ),
  ownerPassportNumber: Yup.string()
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Номер обязателен')
    )
    .when('ownerPassportType', (ownerPassportType, schema) =>
      ownerPassportType === '0'
        ? schema.matches(documentNumberRegex, 'Неверный формат')
        : undefined
    ),
  ownerPassportIssueDate: Yup.date()
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Дата выдачи обязательна')
    ),
  ownerPassportIssuedBy: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Место выдачи обязательно')
    )
    .nullable(),

  ownerRegistrationAddress: Yup.object({
    title: Yup.string(),
    value: Yup.object()
  })
    .when('drivers', (drivers, schema) =>
      !drivers[0].driversWithoutRestrictions
        ? schema.notRequired()
        : schema.required('Адрес регистрации обязателен')
    )
    .nullable()
});

export default calculatorSchema;
