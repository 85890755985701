import * as Yup from 'yup';
import {
  bodyNumberRegex,
  documentSerialRegex,
  enginePowerRegex,
  numberPlateRegex,
  VINNumberRegex,
  documentNumberRegex
} from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const vehicleSchema = Yup.object().shape({
  withoutNumber: Yup.boolean(),
  numberPlate: Yup.string().when('withoutNumber', {
    is: false,
    then: Yup.string()
      .required('Номер обязателен')
      .matches(numberPlateRegex, 'Номер указан неверно')
  }),
  // brand: Yup.object({
  //   title: Yup.string(),
  //   value: Yup.string()
  // })
  //   .required('Марка обязательна')
  //   .nullable(),
  model: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .required('Модель обязательна')
    .nullable(),
  identifiersType: Yup.string(),
  identifiers: Yup.string()
    .required('Номер обязателен')
    .when('identifiersType', (identifiersType, schema) =>
      identifiersType === '1'
        ? schema.matches(VINNumberRegex, 'VIN введен не верно')
        : schema.matches(bodyNumberRegex, 'Номер введен неверно')
    ),
  vehicleDocumentType: Yup.string(),
  vehicleType: Yup.number(),
  year: Yup.number()
    .required('Год обязателен')
    .min(1940, 'Неверный год')
    .max(new Date().getFullYear(), 'Год выше текущий даты'),
  power: Yup.string()
    .required('Мощность обязательна')
    .matches(enginePowerRegex, 'Мощность введена неверно'),
  docSerial: Yup.string()
    .when('vehicleDocumentType', (vehicleDocumentType, schema) =>
      vehicleDocumentType !== '3'
        ? schema.required('Серия обязательна')
        : undefined
    )
    .matches(documentSerialRegex, 'Неверная серия'),
  docNumber: Yup.string()
    .required('Номер обязателен')
    .matches(documentNumberRegex, 'Неверный номер'),
  docDate: Yup.date('Дата некорректна')
    .required('Дата обязательна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
});

export default vehicleSchema;
