import { createSlice } from '@reduxjs/toolkit';
// utils
// import axios from 'utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  showDialog: false,
  kbmSearch: {
    isForeignIdentity: false,
    firstName: '',
    middleName: '',
    lastName: '',
    birthdate: '',
    licenseSerial: '',
    licenseNumber: '',

    // Подблок предыдущее В/У
    isPreviousLicense: false,
    previousLicenseSerial: '',
    previousLicenseNumber: '',
    previousFirstName: '',
    previousMiddleName: '',
    previousLastName: ''
  },
  kbmResult: null
};

const slice = createSlice({
  name: 'kbm',
  initialState,
  reducers: {
    changeDialogState(state, action) {
      state.showDialog = action.payload;
    },

    setKBMResult(state, action) {
      state.kbmResult = action.payload;
    },

    setKBMSearchField(state, action) {
      state.kbmSearch[action.payload.name] = action.payload.value;
    }
  }
});

// Reducer
export default slice.reducer;

// ----------------------------------------------------------------------

// Действия с окном КБМ
export function showKBMDialog() {
  return async (dispatch) => {
    dispatch(slice.actions.changeDialogState(true));
  };
}

export function closeKBMDialog() {
  return async (dispatch) => {
    dispatch(slice.actions.changeDialogState(false));
  };
}

// Действия с полями КБМ
export function setKBMResult(result) {
  return async (dispatch) => {
    dispatch(slice.actions.setKBMResult(result));
  };
}

export function setKBMSearchField({ name, value }) {
  return async (dispatch) => {
    dispatch(slice.actions.setKBMSearchField({ name, value }));
  };
}
