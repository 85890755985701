import { combineReducers } from 'redux';
import { persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage';
// slices
import settingsReducer from './slices/settings';
import osagoReducer from './slices/osago/osago';
import kbmReducer from './slices/osago/kbm';
import contractsReducer from './slices/osago/contracts';
import prolongationJournalReducer from './slices/osago/prolongation_journal';
import authJwtReducer from './slices/authJwt';
import dadataReducer from './slices/dadata';
import contactsReducer from './slices/contacts/contacts';
import listsReducer from './slices/lists';
import editContractReducer from './slices/osago/edit_contract';
import usersReducer from './slices/users/users';

// ----------------------------------------------------------------------

const rootPersistConfig = {
  key: 'root',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['settings']
};

const authPersistConfig = {
  key: 'authJwt',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['isAuthenticated']
};

const listsPersistConfig = {
  key: 'lists',
  storage,
  keyPrefix: 'redux-'
};

const usersPersisConfig = {
  key: 'users',
  storage,
  keyPrefix: 'redux-',
  whitelist: ['editUser']
};

const contactsPersisCongig = {
  key: 'contacts',
  storage,
  keyPrefix: 'redux-',
  whitelist: [
    'contact',
    'contractId',
    'vehicle',
    'insurerIsOwner',
    'insurer',
    'owner',
    'drivers'
  ]
};

const rootReducer = combineReducers({
  settings: settingsReducer,
  lists: persistReducer(listsPersistConfig, listsReducer),
  dadata: dadataReducer,
  contacts: persistReducer(contactsPersisCongig, contactsReducer),
  users: persistReducer(usersPersisConfig, usersReducer),
  osago: osagoReducer,
  editContract: editContractReducer,
  prolongationJournal: prolongationJournalReducer,
  contracts: contractsReducer,
  kbm: kbmReducer,
  authJwt: persistReducer(authPersistConfig, authJwtReducer)
});

export { rootPersistConfig, rootReducer };
