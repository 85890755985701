import PropTypes from 'prop-types';
import { useState } from 'react';
// material
import { useTheme } from '@material-ui/core/styles';
import {
  Dialog,
  DialogActions,
  Box,
  Grid,
  CircularProgress,
  Typography,
  Button,
  DialogContent,
  DialogContentText,
  DialogTitle
} from '@material-ui/core';

LoadDialog.propTypes = {
  message: PropTypes.string,
  open: PropTypes.bool,
  errors: PropTypes.array
};
export default function LoadDialog({ open, message, errors = [] }) {
  const theme = useTheme();
  const [close, setClose] = useState(false);

  return (
    <Dialog open={!close && open} scroll="paper" maxWidth="xs" fullWidth>
      {errors.length > 0 ? (
        <>
          <DialogTitle color="red">Ошибка</DialogTitle>
          <DialogContent dividers>
            <DialogContentText color={theme.palette.error.main} tabIndex={-1}>
              {errors.join('\n')}
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" onClick={() => setClose(true)}>
              Закрыть
            </Button>
          </DialogActions>
        </>
      ) : (
        <Box height={200} width="100%" display="flex">
          <Grid
            container
            direction="column"
            alignItems="center"
            justifyContent="space-evenly"
            p={2}
          >
            <Grid item xs={1}>
              <CircularProgress disableShrink size={60} thickness={4} />
            </Grid>
            <Grid item xs={1}>
              <Typography variant="h5">{message || 'Загрузка...'}</Typography>
            </Grid>
          </Grid>
        </Box>
      )}
    </Dialog>
  );
}
