import PropTypes from 'prop-types';
import { useField, useFormikContext } from 'formik';
import { forwardRef } from 'react';
// material
import { Autocomplete } from '@material-ui/core';
import { MTextField as TextField } from '.';

// ----------------------------------------------------------------------

const MAutocomplete = forwardRef(
  (
    { onChangeInputAction, onChangeAction, renderInputParams, ...props },
    ref
  ) => {
    const { setFieldValue } = useFormikContext();
    const [field, meta] = useField(props);

    const handleChange = (_, value) => {
      if (onChangeAction !== undefined) {
        onChangeAction(value);
      }
      setFieldValue(field.name, value);
    };

    const handleInputChange = (event, newInputValue) => {
      if (onChangeInputAction !== undefined) {
        onChangeInputAction(newInputValue);
      }
    };
    return (
      <Autocomplete
        ref={ref}
        {...props}
        {...field}
        onChange={handleChange}
        onInputChange={handleInputChange}
        renderInput={(params) => (
          <TextField
            {...params}
            {...renderInputParams}
            complete={field.value !== null}
            error={Boolean(meta.touched && meta.error)}
            helperText={
              (meta.touched && meta.error && meta.error.value) ||
              (meta.touched && meta.error)
            }
          />
        )}
      />
    );
  }
);

MAutocomplete.propTypes = {
  onChangeInputAction: PropTypes.func,
  onChangeAction: PropTypes.func,
  renderInputParams: PropTypes.object
};

export default MAutocomplete;
