import { lazy } from 'react';
import { Redirect } from 'react-router-dom';
// layouts
import PersonalLayout from 'layouts';
// pathes
import {
  PATH_DIAGNOSTIC_CARDS,
  PATH_OSAGO,
  PATH_PERSONAL,
  PATH_USER
} from 'routes/paths';

import AuthGuard from 'guards/AuthGuard';
import ContactsRoutes from './contacts.routes';
import UsersRoutes from './users_management.routes';

// ----------------------------------------------------------------------

const PersonalRoutes = {
  path: PATH_PERSONAL.root,
  // guard:
  guard: AuthGuard,
  layout: PersonalLayout,
  routes: [
    {
      exact: true,
      path: PATH_PERSONAL.root,
      component: lazy(() => import('views/PersonalHome'))
    },

    // Profile

    {
      exact: true,
      path: PATH_USER.account,
      component: lazy(() => import('views/user/UserAccount'))
    },

    // OSAGO routes

    {
      exact: true,
      path: PATH_OSAGO.root,
      component: () => <Redirect to="/404" />
    },
    {
      exact: true,
      path: PATH_OSAGO.editPolis,
      component: lazy(() => import('views/osago/NewPolisPage'))
    },
    {
      exact: true,
      path: PATH_OSAGO.prolongation,
      component: lazy(() => import('views/osago/ProlongationPage'))
    },
    {
      exact: true,
      path: PATH_OSAGO.prolongationJournal,
      component: lazy(() => import('views/osago/ProlongationJournalPage'))
    },
    {
      exact: true,
      path: PATH_OSAGO.calculator,
      component: lazy(() => import('views/osago/CalculatorPage'))
    },
    {
      exact: true,
      path: PATH_OSAGO.calculations,
      component: lazy(() => import('views/osago/CalculationsPage'))
    },

    // Contacts routes
    ContactsRoutes,

    UsersRoutes,

    // Diagnostic cards routes
    {
      exact: true,
      path: PATH_DIAGNOSTIC_CARDS.root,
      component: () => <Redirect to="/404" />
    },

    // Documents routes

    // Control routes

    // Wallet routes

    // ----------------------------------------------------------------------

    {
      component: () => <Redirect to="/404" />
    }
  ]
};

export default PersonalRoutes;
