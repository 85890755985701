import * as Yup from 'yup';
import { documentSerialRegex, documentNumberRegex } from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const personSchema = Yup.object().shape({
  // Блок Страхователь
  firstName: Yup.string().required('Имя обязательно'),
  middleName: Yup.string().when('noMiddleName', (noMiddleName, schema) =>
    noMiddleName
      ? schema.notRequired()
      : schema.required('Отчество обязательно')
  ),
  lastName: Yup.string().required('Фамилия обязательна'),
  birthdate: Yup.date('Дата некорректна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .required('Дата рождения обязательна'),

  passportSerial: Yup.string()
    .matches(documentSerialRegex, 'Неверный формат')
    .required('Серия обязательна'),
  passportNumber: Yup.string()
    .matches(documentNumberRegex, 'Неверный формат')
    .required('Номер обязателен'),
  passportDate: Yup.date('Дата некорректна')
    .required('Дата обязательна')
    .min(MIN_VALIDATION_DATE, 'Дата некорректна'),

  passportPoint: Yup.object({
    title: Yup.string(),
    value: Yup.string()
  })
    .required('Место выдачи обязательно')
    .nullable(),
  address: Yup.object({
    title: Yup.string(),
    value: Yup.object()
  })
    .required('Адрес регистрации обязателен')
    .nullable()
});

export default personSchema;
