import axios from 'utils/axios';
import { dateToString } from 'utils/textTransformation';

export const searchVehicle = async (numberPlate) => {
  const response = await axios.get(
    `api/v1/vehicles/search?numberPlate=${numberPlate}`
  );
  return response.data;
};

export const getVehiclesList = async () => {
  const response = await axios.get(`api/v1/vehicles/list`);
  return response.data;
};

export const getClientVehiclesList = async (clientId) => {
  const response = await axios.get(`api/v1/vehicles/list?clientId=${clientId}`);
  return response.data;
};

export const storeVehicle = async ({
  clientId,
  id,
  numberPlate,
  type,
  power,
  year,
  identifiersType,
  identifiers,
  docType,
  docSerial,
  docNumber,
  docDate,
  modelId,
  createContract
}) => {
  const body = {
    clientId,
    numberPlate,
    type,
    power,
    year,
    identifiersType,
    identifiers,
    docType,
    docSerial,
    docNumber,
    docDate: typeof docDate === 'string' ? docDate : dateToString(docDate),
    modelId,
    ...(createContract && { createContract })
  };
  if (!id) {
    const response = await axios.post(`api/v1/vehicles`, body);
    return response.data;
  }
  const response = await axios.put(`api/v1/vehicles/${id}`, body);
  return response.data;
};

export const getVehicle = async (id) => {
  const response = await axios.get(`api/v1/vehicles/${id}`);
  return response.data;
};

export const getModelById = async (id) => {
  const response = await axios.get(`api/v1/vehicles/models?modelId=${id}`);
  return response.data;
};
