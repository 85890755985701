import * as Yup from 'yup';
import { phoneRegex, polisSerialRegex, polisNumberRegex } from 'utils/regexes';
import { MIN_VALIDATION_DATE } from 'utils/constants';

// ----------------------------------------------------------------------

const prolongationSchema = Yup.object().shape({
  clientSelect: Yup.object({
    title: Yup.string(),
    value: Yup.number()
  }).required('Выбор клиента обязателен'),
  clientPhone: Yup.string()
    .required('Телефон клиента обязателен')
    .matches(phoneRegex, 'Формат номера +7 (999) 999-99-99'),
  clientEmail: Yup.string().email('Введите действующий e-mail'),
  clientFirstName: Yup.string().required('Имя обязательно'),
  clientMiddleName: Yup.string().required('Отчество обязательно'),
  clientLastName: Yup.string().required('Фамилия обязательна'),
  prevPolisSerial: Yup.object({
    title: Yup.string(),
    value: Yup.string().matches(polisSerialRegex, 'Несоответствующий формат')
  }).nullable(),
  prevPolisNumber: Yup.string(polisNumberRegex, 'Несоответствующий формат'),
  ownerBirthdate: Yup.date()
    .min(MIN_VALIDATION_DATE, 'Дата некорректна')
    .when('insurerIsOwner', (insurerIsOwner, schema) =>
      insurerIsOwner
        ? schema.notRequired()
        : schema.required('Дата рождения обязательна')
    )
});

export default prolongationSchema;
